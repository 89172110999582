export const expandedDrawerWidth = 180;
export const collapsedDrawerWidth = 72;
export const LOCAL_STORAGE_KEY = "homeschool-planner-auth";
export const TIMEZONES = [
  // US & Canada
  { value: "America/New_York", label: "Eastern Time (ET)", group: "Americas" },
  { value: "America/Chicago", label: "Central Time (CT)", group: "Americas" },
  { value: "America/Denver", label: "Mountain Time (MT)", group: "Americas" },
  {
    value: "America/Los_Angeles",
    label: "Pacific Time (PT)",
    group: "Americas",
  },
  { value: "America/Anchorage", label: "Alaska Time (AKT)", group: "Americas" },
  { value: "Pacific/Honolulu", label: "Hawaii Time (HT)", group: "Americas" },
  { value: "America/Toronto", label: "Toronto", group: "Americas" },
  { value: "America/Vancouver", label: "Vancouver", group: "Americas" },

  // Europe
  { value: "Europe/London", label: "London (BST/GMT)", group: "Europe" },
  { value: "Europe/Paris", label: "Central European (CET)", group: "Europe" },
  {
    value: "Europe/Helsinki",
    label: "Eastern European (EET)",
    group: "Europe",
  },
  { value: "Europe/Moscow", label: "Moscow (MSK)", group: "Europe" },

  // Asia & Pacific
  { value: "Asia/Dubai", label: "Dubai (GST)", group: "Asia/Pacific" },
  { value: "Asia/Shanghai", label: "China (CST)", group: "Asia/Pacific" },
  { value: "Asia/Tokyo", label: "Japan (JST)", group: "Asia/Pacific" },
  { value: "Asia/Singapore", label: "Singapore (SGT)", group: "Asia/Pacific" },
  { value: "Australia/Sydney", label: "Sydney (AEST)", group: "Asia/Pacific" },
  {
    value: "Pacific/Auckland",
    label: "New Zealand (NZST)",
    group: "Asia/Pacific",
  },
  { value: "Asia/Kolkata", label: "India (IST)", group: "Asia/Pacific" },
];
