import { useState, useCallback, useEffect } from "react";
import { debug } from "../utils/debug";

export const useBaseWizardState = (steps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [wizardData, setWizardData] = useState({});
  const [stepValidation, setStepValidation] = useState({});
  const [error, setError] = useState(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!Array.isArray(steps)) {
      setError("Steps must be an array");
      return;
    }
    if (!initialized) {
      setActiveStep(0);
      setInitialized(true);
    }
  }, [steps, initialized]);

  useEffect(() => {
    if (Array.isArray(steps) && activeStep >= steps.length) {
      setActiveStep(Math.max(0, steps.length - 1));
    }
  }, [steps, activeStep]);

  // Initialize step validation
  useEffect(() => {
    if (Array.isArray(steps) && !initialized) {
      const initialValidation = steps.reduce((acc, _, index) => {
        acc[index] = false;
        return acc;
      }, {});
      debug.wizard.state("Initializing validation:", initialValidation);
      setStepValidation(initialValidation);
      setWizardData({}); // Initialize empty wizard data
      setInitialized(true);
    }
  }, [steps, initialized]);

  const handleStepChange = useCallback(
    (direction) => {
      debug.wizard.state("Handling step change:", { direction, activeStep });
      if (direction === "next" && activeStep < steps.length - 1) {
        setActiveStep(activeStep + 1);
      } else if (direction === "back" && activeStep > 0) {
        setActiveStep(activeStep - 1);
      }
    },
    [activeStep, steps.length]
  );

  const navigate = useCallback(
    (direction) => {
      try {
        if (!Array.isArray(steps) || steps.length === 0) {
          throw new Error("Invalid steps configuration");
        }

        setActiveStep((current) => {
          if (direction === "next" && current < steps.length - 1) {
            return current + 1;
          }
          if (direction === "back" && current > 0) {
            return current - 1;
          }
          return current;
        });
      } catch (err) {
        setError(err.message);
      }
    },
    [steps]
  );

  const validateStep = useCallback((stepIndex, data) => {
    debug.wizard.state("Validating step:", { stepIndex, data });

    // Basic validation - check if required data exists
    if (stepIndex === 0) {
      const isValid = Boolean(data?.term && data?.student);
      debug.wizard.state("Step 0 validation result:", { isValid, data });
      return isValid;
    }

    return true; // Default to valid for other steps
  }, []);

  const updateWizardData = useCallback((newData) => {
    setWizardData((prev) => ({ ...prev, ...newData }));
  }, []);

  const updateStepValidation = useCallback((stepIndex, isValid) => {
    setStepValidation((prev) => ({ ...prev, [stepIndex]: isValid }));
  }, []);

  return {
    activeStep,
    setActiveStep,
    wizardData,
    updateWizardData,
    stepValidation,
    updateStepValidation,
    validateStep,
    handleStepChange,
    error,
    setError,
    initialized,
    navigate,
  };
};
