import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { doPerformLogin } from "../api/authApi";
import { setAuthInfo } from "../redux/authSlice";

export const useLogin = () => {
  const dispatch = useDispatch();
  const [authResponse, setAuthResponse] = useState(null);
  const [verificationRequired, setVerificationRequired] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  const handleAuthResponse = useCallback(
    (response) => {
      if (!response || !response.loggedInUserAccounts) {
        console.log("Missing either response or loggedInUserAccounts");
        return false;
      }

      if (response.loggedInUserAccounts.length === 0) {
        console.log("No license found for this user");
        return false;
      }

      if (response.loggedInUserAccounts.length === 1) {
        console.log("Only one account found");
        const selectedAccount = response.loggedInUserAccounts[0];
        dispatch(
          setAuthInfo({ ...response, selectedAccountId: selectedAccount.id })
        );
      } else {
        dispatch(setAuthInfo(response));
        console.log("Multiple accounts found");
      }
      return true;
    },
    [dispatch]
  );

  useEffect(() => {
    if (authResponse) {
      handleAuthResponse(authResponse);
    }
  }, [authResponse, handleAuthResponse]);

  const loginMutation = useMutation(doPerformLogin, {
    onSuccess: (response) => {
      console.log("Login mutation success:", response);
      setAuthResponse(response);
      setVerificationRequired(false);
      setUserEmail("");
    },
    onError: (error) => {
      console.error("Login failed:", error);
      if (error.response?.data?.status === "USER_EXISTS_UNVERIFIED") {
        setVerificationRequired(true);
        try {
          const credentials = JSON.parse(error.response.config.data);
          setUserEmail(credentials.username);
        } catch (e) {
          console.error("Could not parse credentials from error response");
        }
      }
    },
  });

  const loginWithCredentials = useCallback(
    async (credentials) => {
      try {
        return await loginMutation.mutateAsync(credentials);
      } catch (error) {
        // This will ensure the error is handled and not propagated
        console.error("Login attempt failed:", error);
      }
    },
    [loginMutation]
  );

  useEffect(() => {
    console.log("useLogin - authResponse changed:", authResponse);
  }, [authResponse]);

  return {
    loginWithCredentials,
    isLoading: loginMutation.isLoading,
    error: loginMutation.error,
    authResponse,
    setAuthResponse,
    verificationRequired,
    userEmail,
  };
};
