import React from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  TextField,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik, Form, Field } from "formik";
import { useReportContext } from "../../contexts/report-context";
import { enrollStudentValidationSchema } from "../../utils/validation";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { applyLessonPlanToTerm } from "../../api/lessonPlanApi";

const EnrollStudentModal = ({
  open,
  handleApply,
  handleClose,
  studentDetails,
  classes,
  terms,
}) => {
  const { setMessage } = useReportContext();
  const { execute: executeApplyLessonPlan } = useAsyncOperation(
    applyLessonPlanToTerm
  );

  if (!open) {
    return null;
  }

  const handleSubmit = async (values) => {
    try {
      const { termId, lessonPlanId } = values;

      if (!lessonPlanId || !termId || !studentDetails?.id) {
        throw new Error("Missing required fields");
      }

      const result = await executeApplyLessonPlan(lessonPlanId, {
        termId,
        studentId: studentDetails.id,
      });

      if (result) {
        handleApply();
        setMessage({
          text: "Student enrolled in class successfully!",
          severity: "success",
          flag: true,
        });
        handleClose();
      }
    } catch (err) {
      console.error("Error applying lesson plan:", err);
      setMessage({
        text:
          err.message ||
          err.response?.data?.message ||
          "Failed to enroll student in class",
        severity: "error",
        flag: true,
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="apply-modal-title"
      aria-describedby="apply-modal-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle style={{ cursor: "move" }} id="dialog-title">
        Enroll Student in Class
      </DialogTitle>
      <DialogContent dividers sx={{ p: 2 }}>
        <Formik
          onSubmit={(values, actions) => {
            console.log("Formik onSubmit called with:", values);
            return handleSubmit(values, actions);
          }}
          initialValues={{
            termId: "",
            lessonPlanId: "",
          }}
          validationSchema={enrollStudentValidationSchema}
        >
          {({ isSubmitting, touched, errors, values, setFieldValue }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    {studentDetails?.firstName} {studentDetails?.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field name="lessonPlanId">
                    {({ field }) => (
                      <TextField
                        select
                        label="Class"
                        size="small"
                        fullWidth
                        value={field.value}
                        onChange={(e) => {
                          const value = e.target.value;
                          setFieldValue("lessonPlanId", value);
                        }}
                        error={errors.lessonPlanId && touched.lessonPlanId}
                        helperText={
                          errors.lessonPlanId && touched.lessonPlanId
                            ? errors.lessonPlanId
                            : ""
                        }
                        required
                      >
                        {classes.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {`${option.name} (${option.durationPerLesson} : ${option.lessonFrequency})`}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="termId">
                    {({ field }) => (
                      <TextField
                        select
                        label="School Term"
                        size="small"
                        fullWidth
                        value={field.value}
                        onChange={(e) => {
                          const value = e.target.value;
                          setFieldValue("termId", value);
                        }}
                        error={errors.termId && touched.termId}
                        helperText={
                          errors.termId && touched.termId ? errors.termId : ""
                        }
                        required
                      >
                        {terms.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                sx={{ mt: 2 }}
              >
                <Button autoFocus variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  Schedule
                </LoadingButton>
              </Stack>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default EnrollStudentModal;
