import { useState, useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";

export const useGoogleMapsScript = ({ googleMapsApiKey }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadError, setLoadError] = useState(null);

  useEffect(() => {
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      const loader = new Loader({
        apiKey: googleMapsApiKey,
        version: "weekly",
        libraries: ["places"],
      });

      loader
        .load()
        .then(() => {
          setIsLoaded(true);
        })
        .catch((err) => {
          console.error("Error loading Google Maps API:", err);
          setLoadError(err);
        });
    } else {
      setIsLoaded(true);
    }
  }, [googleMapsApiKey]);

  return { isLoaded, loadError };
};

export const getGoogleMapsPlaceDetails = (placeId) => {
  return new Promise((resolve, reject) => {
    const placesService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    placesService.getDetails(
      { placeId: placeId, fields: ["address_components", "geometry"] },
      (result, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          resolve(result);
        } else {
          reject(new Error(`Place details request failed: ${status}`));
        }
      }
    );
  });
};
