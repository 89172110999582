import React from "react";
import {
  Grid,
  Button,
  TextField,
  Stack,
  IconButton,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik, Form, Field, FieldArray } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useReportContext } from "../../contexts/report-context";
import { createLessonValidationSchema } from "../../utils/validation";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import {
  createClassLesson,
  updateClassLesson,
} from "../../api/classLessonsApi";
import HsModal from "../../components/common/HsModal";

const CreateLessonModal = ({
  open,
  lessonPlanId,
  data,
  handleCreate,
  handleClose,
}) => {
  const { setMessage } = useReportContext();
  const { execute: executeCreateLesson, loading: createLoading } =
    useAsyncOperation(createClassLesson);
  const { execute: executeUpdateLesson, loading: updateLoading } =
    useAsyncOperation(updateClassLesson);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (data) {
        await executeUpdateLesson(data.id, {
          ...values,
          lessonPlanId,
        });
      } else {
        await executeCreateLesson({
          ...values,
          lessonPlanId,
        });
      }

      handleCreate();
      setMessage({
        text: `Lesson ${data ? "updated" : "created"} successfully!`,
        severity: "success",
        flag: true,
      });
      handleClose();
    } catch (err) {
      setMessage({
        text:
          err.response?.data?.message ||
          `Failed to ${data ? "update" : "create"} lesson`,
        severity: "error",
        flag: true,
      });
    }
  };

  return (
    <HsModal
      open={open}
      handleClose={handleClose}
      title={data ? "Edit Lesson" : "Create Lesson"}
    >
      <Formik
        initialValues={{
          title: data?.title || "",
          description: data?.description || "",
          lessonNumber: data?.lessonNumber || "",
          durationInMinutes: data?.durationInMinutes || "",
          workItems: data?.workItems || [{ description: "" }],
        }}
        validationSchema={createLessonValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, isSubmitting }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field name="title">
                  {({ field }) => (
                    <TextField
                      {...field}
                      label="Title"
                      fullWidth
                      size="small"
                      error={errors.title && touched.title}
                      helperText={
                        errors.title && touched.title ? errors.title : ""
                      }
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="description">
                  {({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      fullWidth
                      multiline
                      rows={3}
                      size="small"
                      error={errors.description && touched.description}
                      helperText={
                        errors.description && touched.description
                          ? errors.description
                          : ""
                      }
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="lessonNumber">
                  {({ field }) => (
                    <TextField
                      {...field}
                      label="Lesson Number"
                      type="number"
                      fullWidth
                      size="small"
                      error={errors.lessonNumber && touched.lessonNumber}
                      helperText={
                        errors.lessonNumber && touched.lessonNumber
                          ? errors.lessonNumber
                          : ""
                      }
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="durationInMinutes">
                  {({ field }) => (
                    <TextField
                      {...field}
                      label="Duration (minutes)"
                      type="number"
                      fullWidth
                      size="small"
                      error={
                        errors.durationInMinutes && touched.durationInMinutes
                      }
                      helperText={
                        errors.durationInMinutes && touched.durationInMinutes
                          ? errors.durationInMinutes
                          : ""
                      }
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Work Items
                </Typography>
                <FieldArray name="workItems">
                  {({ push, remove }) => (
                    <Stack spacing={2}>
                      {values.workItems.map((item, index) => (
                        <Stack
                          key={index}
                          direction="row"
                          spacing={1}
                          alignItems="center"
                        >
                          <Field name={`workItems.${index}.description`}>
                            {({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                size="small"
                                placeholder="Work item description"
                              />
                            )}
                          </Field>
                          <IconButton
                            onClick={() => remove(index)}
                            disabled={values.workItems.length === 1}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      ))}
                      <Button
                        startIcon={<AddIcon />}
                        onClick={() => push({ description: "" })}
                      >
                        Add Work Item
                      </Button>
                    </Stack>
                  )}
                </FieldArray>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={2}
                  sx={{ mt: 2 }}
                >
                  <Button variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    loading={createLoading || updateLoading}
                  >
                    {data ? "Update" : "Create"}
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </HsModal>
  );
};

export default CreateLessonModal;
