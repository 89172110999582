import React, { useCallback, useMemo, useState } from "react";
import {
  Grid,
  Typography,
  Stack,
  IconButton,
  Button,
  Collapse,
  Box,
  TableCell,
  CircularProgress,
  Chip,
  alpha,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import SkipNextOutlinedIcon from "@mui/icons-material/SkipNextOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HsTable from "../../components/common/HsTable";
import { updateCalendarEvent } from "../../api/calendarApi";
import { useReportContext } from "../../contexts/report-context";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { debug } from "../../utils/debug";
import { useSelector } from "react-redux";

const ChecklistTable = ({
  checklistItems,
  loading,
  error,
  selectedRows,
  setSelectedRows,
  onStatusChange,
}) => {
  const { setMessage } = useReportContext();
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const theme = useTheme();
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRowExpansion = useCallback((rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  }, []);

  const columns = useMemo(
    () => [
      { id: "title", label: "TITLE", width: "40%" },
      { id: "time", label: "TIME", width: "15%" },
      { id: "student", label: "STUDENT", width: "20%" },
      { id: "action", label: "ACTION", width: "25%" },
    ],
    []
  );

  const getStatusColor = useCallback((status) => {
    switch (status) {
      case "COMPLETED":
        return "success";
      case "SKIPPED":
        return "warning";
      default:
        return "default";
    }
  }, []);

  const getStatusLabel = useCallback((status) => {
    switch (status) {
      case "COMPLETED":
        return "Completed";
      case "SKIPPED":
        return "Skipped";
      default:
        return "Pending";
    }
  }, []);

  const renderActionButtons = useCallback(
    (row) => {
      const isCompleted = row.checklistStatus === "COMPLETED";
      const isSkipped = row.checklistStatus === "SKIPPED";

      return (
        <Stack direction="row" spacing={1}>
          <Button
            variant={isCompleted ? "contained" : "outlined"}
            size="small"
            startIcon={
              isCompleted ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />
            }
            onClick={() =>
              onStatusChange(row.id, isCompleted ? "PENDING" : "COMPLETED")
            }
            color={isCompleted ? "success" : "inherit"}
            sx={{
              borderColor: isCompleted ? "success.main" : undefined,
            }}
          >
            {isCompleted ? "Completed" : "Complete"}
          </Button>
          <Button
            variant={isSkipped ? "contained" : "outlined"}
            size="small"
            startIcon={isSkipped ? <SkipNextIcon /> : <SkipNextOutlinedIcon />}
            onClick={() =>
              onStatusChange(row.id, isSkipped ? "PENDING" : "SKIPPED")
            }
            color={isSkipped ? "warning" : "inherit"}
            sx={{
              borderColor: isSkipped ? "warning.main" : undefined,
            }}
          >
            {isSkipped ? "Skipped" : "Skip"}
          </Button>
          <IconButton
            size="small"
            onClick={() => {
              /* Handle edit action */
            }}
          >
            <EditOutlinedIcon />
          </IconButton>
        </Stack>
      );
    },
    [onStatusChange]
  );

  const renderDesktopRow = useCallback(
    (row, columns) => (
      <>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            sx={{
              backgroundColor:
                row.checklistStatus !== "PENDING"
                  ? alpha(
                      theme.palette[getStatusColor(row.checklistStatus)].light,
                      0.1
                    )
                  : "transparent",
              borderLeft:
                row.checklistStatus !== "PENDING"
                  ? `4px solid ${
                      theme.palette[getStatusColor(row.checklistStatus)].main
                    }`
                  : "none",
            }}
          >
            {column.id === "title" ? (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography
                  sx={{
                    textDecoration:
                      row.checklistStatus === "COMPLETED"
                        ? "line-through"
                        : "none",
                    color:
                      row.checklistStatus === "COMPLETED"
                        ? "text.secondary"
                        : "text.primary",
                  }}
                >
                  {row.title}
                </Typography>
                <Chip
                  size="small"
                  label={getStatusLabel(row.checklistStatus)}
                  color={getStatusColor(row.checklistStatus)}
                  sx={{
                    display:
                      row.checklistStatus === "PENDING" ? "none" : "flex",
                    height: "20px",
                  }}
                />
              </Stack>
            ) : column.id === "time" ? (
              `${dayjs(row.userStartTime).format("HH:mm")} - ${dayjs(
                row.userEndTime
              ).format("HH:mm")}`
            ) : column.id === "student" ? (
              // Safe access to student name
              row.student ? (
                `${row.student.firstName} ${row.student.lastName}`
              ) : (
                "No Student"
              )
            ) : column.id === "action" ? (
              renderActionButtons(row)
            ) : (
              row[column.id]
            )}
          </TableCell>
        ))}
      </>
    ),
    [renderActionButtons, getStatusColor, getStatusLabel, theme.palette]
  );

  const renderMobileRow = useCallback(
    (row) => (
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            py: 2,
            px: 2,
            borderColor: "divider",
            backgroundColor:
              row.checklistStatus !== "PENDING"
                ? alpha(
                    theme.palette[getStatusColor(row.checklistStatus)].light,
                    0.1
                  )
                : "transparent",
            borderLeft:
              row.checklistStatus !== "PENDING"
                ? `4px solid ${
                    theme.palette[getStatusColor(row.checklistStatus)].main
                  }`
                : "none",
          }}
        >
          {/* Title and Time Section */}
          <Box sx={{ mb: 1.5 }}>
            <Typography
              variant="subtitle1"
              sx={{
                textDecoration:
                  row.checklistStatus === "COMPLETED" ? "line-through" : "none",
                color:
                  row.checklistStatus === "COMPLETED"
                    ? "text.secondary"
                    : "text.primary",
                mb: 0.5,
              }}
            >
              {row.title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {`${dayjs(row.userStartTime).format("HH:mm")} - ${dayjs(
                row.userEndTime
              ).format("HH:mm")}`}
            </Typography>
          </Box>

          {/* Student and Status Section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1.5,
            }}
          >
            <Typography variant="body2" color="textSecondary">
              {row.student
                ? `${row.student.firstName} ${row.student.lastName}`
                : "No Student"}
            </Typography>
            {row.checklistStatus !== "PENDING" && (
              <Chip
                size="small"
                label={getStatusLabel(row.checklistStatus)}
                color={getStatusColor(row.checklistStatus)}
                sx={{ height: "24px" }}
              />
            )}
          </Box>

          {/* Action Buttons */}
          <Stack
            direction="row"
            spacing={1}
            sx={{
              mt: 1,
              pt: 1.5,
              borderTop: 1,
              borderColor: "divider",
            }}
          >
            <Button
              fullWidth
              size="medium"
              variant={
                row.checklistStatus === "COMPLETED" ? "contained" : "outlined"
              }
              onClick={() =>
                onStatusChange(
                  row.id,
                  row.checklistStatus === "COMPLETED" ? "PENDING" : "COMPLETED"
                )
              }
              color={
                row.checklistStatus === "COMPLETED" ? "success" : "inherit"
              }
              startIcon={
                row.checklistStatus === "COMPLETED" ? (
                  <CheckCircleIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )
              }
            >
              {row.checklistStatus === "COMPLETED" ? "Completed" : "Complete"}
            </Button>
            <Button
              fullWidth
              size="medium"
              variant={
                row.checklistStatus === "SKIPPED" ? "contained" : "outlined"
              }
              onClick={() =>
                onStatusChange(
                  row.id,
                  row.checklistStatus === "SKIPPED" ? "PENDING" : "SKIPPED"
                )
              }
              color={row.checklistStatus === "SKIPPED" ? "warning" : "inherit"}
              startIcon={
                row.checklistStatus === "SKIPPED" ? (
                  <SkipNextIcon />
                ) : (
                  <SkipNextOutlinedIcon />
                )
              }
            >
              {row.checklistStatus === "SKIPPED" ? "Skipped" : "Skip"}
            </Button>
          </Stack>
        </Box>
      </Box>
    ),
    [onStatusChange, theme.palette, getStatusColor, getStatusLabel]
  );

  const handleSelectRow = useCallback(
    (row, tableId) => {
      setSelectedRows((prev) => {
        const isSelected = prev.some(
          (item) => item.id === row.id && item.tableId === tableId
        );
        if (isSelected) {
          return prev.filter(
            (item) => !(item.id === row.id && item.tableId === tableId)
          );
        } else {
          return [...prev, { id: row.id, tableId: tableId }];
        }
      });
    },
    [setSelectedRows]
  );

  const handleSelectAllRows = useCallback(
    (checked, tableId) => {
      if (checked) {
        const allRowsInTable = checklistItems[tableId].map((row) => ({
          id: row.id,
          tableId: tableId,
        }));
        setSelectedRows((prev) => [
          ...prev.filter((item) => item.tableId !== tableId),
          ...allRowsInTable,
        ]);
      } else {
        setSelectedRows((prev) =>
          prev.filter((item) => item.tableId !== tableId)
        );
      }
    },
    [checklistItems, setSelectedRows]
  );

  const renderEmptyState = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
        textAlign: "center",
        color: "primary.main",
      }}
    >
      <EmojiEventsIcon sx={{ fontSize: 100, mb: 2 }} />
      <Typography variant="h4" gutterBottom>
        Congratulations, Overachiever!
      </Typography>
      <Typography variant="body1">
        Your checklist is as empty as a student's excuse for not doing homework.
      </Typography>
      <Typography variant="body2" sx={{ mt: 2, fontStyle: "italic" }}>
        Time to celebrate or... add more tasks?
      </Typography>
    </Box>
  );

  const renderContent = () => {
    if (loading) {
      return (
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
            <CircularProgress />
          </Box>
        </Grid>
      );
    }

    if (error) {
      return (
        <Grid item xs={12}>
          <Box sx={{ p: 3, textAlign: "center" }}>
            <Typography color="error">{error}</Typography>
          </Box>
        </Grid>
      );
    }

    if (!checklistItems || Object.keys(checklistItems).length === 0) {
      return renderEmptyState();
    }

    return Object.entries(checklistItems).map(([date, events]) => (
      <Grid item xs={12} key={date}>
        <Typography variant="h6" gutterBottom style={{ margin: "20px 0 10px" }}>
          {date}
        </Typography>
        <HsTable
          columns={columns}
          data={events}
          totalCount={events.length}
          page={0}
          rowsPerPage={events.length}
          loading={false}
          error={null}
          onPageChange={() => {}}
          onRowsPerPageChange={() => {}}
          renderDesktopRow={renderDesktopRow}
          renderMobileRow={renderMobileRow}
          showPagination={false}
          selectable={true}
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAllRows={handleSelectAllRows}
          tableId={date}
          sx={{
            "& .MuiTable-root": {
              tableLayout: "fixed",
            },
            "& .MuiTableCell-root": {
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          }}
        />
      </Grid>
    ));
  };

  return <>{renderContent()}</>;
};

export default ChecklistTable;
