import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Grid, IconButton, TableCell } from "@mui/material";
import { useSelector } from "react-redux";
import InviteUserModal from "../../components/modals/InviteUserModal";
import {
  ROLE_STUDENT,
  getUsers,
  deleteUser,
  getInviteUsers,
  deleteInviteUser,
} from "../../api/userApi";
import { useReportContext } from "../../contexts/report-context";
import HsTable from "../../components/common/HsTable";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const StudentsComponent = () => {
  const navigate = useNavigate();
  const { setMessage } = useReportContext();
  const [showStudentModal, setShowStudentModal] = useState(false);
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const theme = useTheme();

  const fetchStudents = useCallback(
    async (signal) => {
      const [users, inviteUsersData] = await Promise.all([
        getUsers(selectedAccountId, ROLE_STUDENT, signal),
        getInviteUsers(
          selectedAccountId,
          ROLE_STUDENT,
          page,
          rowsPerPage,
          "ASC",
          signal
        ),
      ]);

      const mappedInviteUsers = inviteUsersData.content.map((user) => ({
        id: user.id,
        firstName: user.personFirstName,
        lastName: user.personLastName,
        email: user.personEmail,
        status: user.status,
      }));

      const combinedData = [...mappedInviteUsers, ...users];

      return {
        data: combinedData,
        totalCount: combinedData.length,
      };
    },
    [selectedAccountId, page, rowsPerPage]
  );

  const {
    data: studentsData,
    error: studentsError,
    execute: executeStudentsFetch,
  } = useAsyncOperation(fetchStudents);

  const { execute: executeDeleteUser } = useAsyncOperation(deleteUser);
  const { execute: executeDeleteInviteUser } =
    useAsyncOperation(deleteInviteUser);

  useEffect(() => {
    executeStudentsFetch();
  }, [executeStudentsFetch]);

  useEffect(() => {
    if (studentsError) {
      setMessage({
        text: studentsError.message || "Failed to fetch students",
        severity: "error",
        flag: true,
      });
    }
  }, [studentsError, setMessage]);

  const handleAddStudent = () => {
    setShowStudentModal(true);
  };

  const handleCancelStudentModal = () => {
    setShowStudentModal(false);
  };

  const handleSaveStudentModal = () => {
    setShowStudentModal(false);
    executeStudentsFetch();
  };

  const handleDeleteUser = useCallback(
    async (id) => {
      try {
        await executeDeleteUser(selectedAccountId, id);
        setMessage({
          text: "Removed a user successfully!",
          severity: "success",
          flag: true,
        });
        executeStudentsFetch();
      } catch (error) {
        setMessage({
          text: error.message || "Failed to remove a user",
          severity: "error",
          flag: true,
        });
      }
    },
    [selectedAccountId, setMessage, executeStudentsFetch, executeDeleteUser]
  );

  const handleDeleteInviteUser = useCallback(
    async (id) => {
      try {
        await executeDeleteInviteUser(id);
        setMessage({
          text: "Removed the invited user successfully!",
          severity: "success",
          flag: true,
        });
        executeStudentsFetch();
      } catch (error) {
        setMessage({
          text: error.message || "Failed to delete the invited user",
          severity: "error",
          flag: true,
        });
      }
    },
    [setMessage, executeStudentsFetch, executeDeleteInviteUser]
  );

  const handleRowClick = useCallback(
    (row) => {
      navigate(`/users/${row.id}`);
    },
    [navigate]
  );

  const columns = [
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "status", label: "Status" },
    { id: "action", label: "Action" },
  ];

  const renderCell = useCallback(
    (columnId, row) => {
      if (columnId === "action") {
        return (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleRowClick(row);
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        );
      }
      return row[columnId];
    },
    [handleRowClick]
  );

  const renderDesktopRow = useCallback(
    (row, columns) => (
      <>
        {columns.map((column) => (
          <TableCell key={column.id}>
            {column.id === "name"
              ? `${row.firstName} ${row.lastName}`
              : renderCell(column.id, row)}
          </TableCell>
        ))}
      </>
    ),
    [renderCell]
  );

  const renderMobileRow = useCallback(
    (row) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="subtitle1" component="span">
            {`${row.firstName} ${row.lastName}`}
          </Typography>
          <Typography
            variant="body2"
            component="span"
            sx={{
              ml: 1,
              color: theme.palette.primary.dark, // Changed to dark green
              fontSize: "0.75rem",
            }}
          >
            {row.status}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.secondary }}
          >
            {row.email}
          </Typography>
        </Box>
        {renderCell("action", row)}
      </Box>
    ),
    [renderCell, theme]
  );

  const handlePageChange = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const handleRowsPerPageChange = useCallback((newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography variant="h2">Students</Typography>
        <IconButton
          onClick={handleAddStudent}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HsTable
            columns={columns}
            data={studentsData?.data || []}
            totalCount={studentsData?.totalCount || 0}
            page={page}
            rowsPerPage={rowsPerPage}
            loading={!studentsData && !studentsError}
            error={studentsError?.message}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            renderDesktopRow={renderDesktopRow}
            renderMobileRow={renderMobileRow}
            onRowClick={handleRowClick}
            showPagination={true}
            rowsPerPageOptions={[5, 10, 15, 20]}
          />
        </Grid>
      </Grid>
      <InviteUserModal
        title="Add Student"
        open={showStudentModal}
        onCancel={handleCancelStudentModal}
        onSave={handleSaveStudentModal}
        userRole={ROLE_STUDENT}
      />
    </Box>
  );
};

export default StudentsComponent;
