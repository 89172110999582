import { api } from "./api";
import apiUrl from "../config/config.js";

export const createCheckoutSession = async (planType, accountId) => {
  try {
    const response = await api.post(
      `${apiUrl}/api/v1/subscription/create-checkout-session?plan_type=${planType}&account_id=${accountId}`,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const handleSubscriptionSuccess = async (sessionId) => {
  try {
    const response = await api.get(
      `${apiUrl}/api/v1/subscription/subscription-success?session_id=${sessionId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
