import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Typography,
  Grid,
  Stack,
  Button,
  Paper,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import HsTable from "../../components/common/HsTable";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserById, getStudentEnrollment } from "../../api/userApi";
import {
  fetchActiveTerms,
  fetchPastTerms,
  fetchFutureTerms,
} from "../../api/termApi";
import { getLessonPlans } from "../../api/lessonPlanApi";
import { useReportContext } from "../../contexts/report-context";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import EnrollStudentModal from "./EnrollStudentModal";

const UserDetailsPage = () => {
  const { id } = useParams();
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const { setMessage } = useReportContext();
  const [enrollmentData, setEnrollmentData] = useState([]);
  const [activeTerms, setActiveTerms] = useState([]);
  const [futureTerms, setFutureTerms] = useState([]);
  const [pastTerms, setPastTerms] = useState([]);
  const [openEnrollStudentModal, setOpenEnrollStudentModal] = useState(false);
  const [lessonPlans, setLessonPlans] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchUserDetails = useCallback(
    async (signal) => {
      const response = await getUserById(id, selectedAccountId, signal);
      return response;
    },
    [id, selectedAccountId]
  );

  const {
    data: userDetails,
    loading: userLoading,
    error: userError,
    execute: executeUserFetch,
  } = useAsyncOperation(fetchUserDetails);

  useEffect(() => {
    if (selectedAccountId) {
      executeUserFetch();
    }
  }, [selectedAccountId, executeUserFetch]);

  useEffect(() => {
    if (userError) {
      setMessage({
        text: userError.message || "Failed to fetch user details",
        severity: "error",
        flag: true,
      });
    }
  }, [userError, setMessage]);

  const fetchStudentEnrollment = useCallback(
    async (termId = "") => {
      try {
        const response = await getStudentEnrollment(id);
        if (Array.isArray(response)) {
          const mappedData = response
            .map((item) => {
              if (!item.lessonPlanSummary || !item.termSummary) {
                console.error("Unexpected item format:", item);
                return null;
              }
              return {
                id: item.id,
                class: item.name,
                subject: item.lessonPlanSummary.subject,
                duration: item.lessonPlanSummary.durationPerLesson,
                termId: item.termSummary.id,
                termName: item.termSummary.name,
              };
            })
            .filter((item) => item !== null);

          const filteredData = termId
            ? mappedData.filter((item) => item.termId === termId)
            : mappedData;

          setEnrollmentData(filteredData);
        } else {
          console.error("Unexpected response format:", response);
          setEnrollmentData([]);
        }
      } catch (err) {
        console.error("Error fetching student enrollment:", err);
        setMessage({
          text: `Failed to fetch student details: ${err.message}`,
          severity: "error",
          flag: true,
        });
        setEnrollmentData([]);
      }
    },
    [id, setMessage]
  );

  const fetchLessonPlans = useCallback(async () => {
    try {
      const data = await getLessonPlans(selectedAccountId, 0, 100);
      setLessonPlans(data.content);
    } catch (err) {
      setMessage({
        text: "Failed to fetch lesson plans",
        severity: "error",
        flag: true,
      });
    }
  }, [selectedAccountId, setMessage]);

  useEffect(() => {
    fetchStudentEnrollment();
    fetchLessonPlans();
  }, [fetchStudentEnrollment, fetchLessonPlans]);

  const fetchAllSchoolTerms = useCallback(async (signal) => {
    const [activeResponse, futureResponse, pastResponse] = await Promise.all([
      fetchActiveTerms(0, 100, signal),
      fetchFutureTerms(0, 100, signal),
      fetchPastTerms(0, 100, signal),
    ]);

    return {
      activeTerms: activeResponse.data || [],
      futureTerms: futureResponse.data || [],
      pastTerms: pastResponse.data || [],
    };
  }, []);

  const {
    data: termsData,
    error: termsError,
    execute: executeTermsFetch,
  } = useAsyncOperation(fetchAllSchoolTerms);

  useEffect(() => {
    executeTermsFetch();
  }, [executeTermsFetch]);

  useEffect(() => {
    if (termsError) {
      setMessage({
        text: termsError.message || "Failed to fetch terms",
        severity: "error",
        flag: true,
      });
    }
  }, [termsError, setMessage]);

  useEffect(() => {
    if (termsData) {
      setActiveTerms(termsData.activeTerms);
      setFutureTerms(termsData.futureTerms);
      setPastTerms(termsData.pastTerms);
    }
  }, [termsData]);

  const handleSelectTerm = (event) => {
    const termId = event.target.value;
    setSelectedTerm(termId);
    fetchStudentEnrollment(termId);
  };

  const handleOpenEnrollStudentModal = () => {
    setOpenEnrollStudentModal(true);
  };

  const handleCloseEnrollStudentModal = () => {
    setOpenEnrollStudentModal(false);
  };

  const handleEnrollStudent = () => {
    fetchStudentEnrollment();
    setOpenEnrollStudentModal(false);
  };

  const enrollmentColumns = [
    { id: "class", label: "Class", width: "25%" },
    { id: "subject", label: "Subject", width: "25%" },
    { id: "duration", label: "Duration", width: "25%" },
    { id: "termName", label: "Term", width: "25%" },
  ];

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  if (userLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (userError || !userDetails) {
    return (
      <Box>
        <Typography color="error">
          {userError?.message || "Failed to load user details"}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            {userDetails.firstName} {userDetails.lastName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Typography variant="bodyFV">Email:</Typography>
            <Typography variant="bodyFV">{userDetails.email}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={12}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Select
                size="small"
                displayEmpty
                fullWidth
                value={selectedTerm}
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>Select Term</em>;
                  }

                  const allTerms = [
                    ...activeTerms,
                    ...futureTerms,
                    ...pastTerms,
                  ];
                  const foundTerm = allTerms.find(
                    (item) => item.id === selected
                  );
                  return foundTerm?.name || "Unknown Term";
                }}
                onChange={handleSelectTerm}
              >
                <MenuItem value="">All</MenuItem>
                {[...activeTerms, ...futureTerms, ...pastTerms].map(
                  (option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  )
                )}
              </Select>
            </Stack>

            <Button variant="contained" onClick={handleOpenEnrollStudentModal}>
              Add Class
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <HsTable
              columns={enrollmentColumns}
              data={enrollmentData}
              totalCount={enrollmentData.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              loading={false}
              error={null}
              showPagination={true}
              rowsPerPageOptions={[5, 10, 15, 20]}
              emptyMessage="No classes enrolled"
            />
          </Paper>
        </Grid>
      </Grid>
      <EnrollStudentModal
        open={openEnrollStudentModal}
        studentDetails={userDetails}
        terms={[...activeTerms, ...futureTerms]}
        handleClose={handleCloseEnrollStudentModal}
        handleApply={handleEnrollStudent}
        classes={lessonPlans}
      />
    </Box>
  );
};

export default UserDetailsPage;
