import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Stack,
  MenuItem,
  Select,
  TableCell,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import HsTable from "../../components/common/HsTable";
import CreateLessonPlanModal from "./CreateLessonPlanModal";
import {
  setLessonPlans,
  addLessonPlan,
  updateLessonPlan as updateLessonPlanAction,
} from "../../redux/lessonPlanSlice";
import { useReportContext } from "../../contexts/report-context";
import {
  useLessonPlanOperations,
  fetchAllSchoolTerms,
  fetchStudents,
} from "./apiCalls";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { createLessonPlan, updateLessonPlan } from "../../api/lessonPlanApi";
import { useTheme } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddIcon from "@mui/icons-material/Add";

// Styled components
const StyledSelect = styled(Select)(({ theme }) => ({
  minWidth: 200,
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.main,
  },
}));

const ClassesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const { setMessage } = useReportContext();

  const [openClassModal, setOpenClassModal] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [students, setStudents] = useState([]);
  const [terms, setTerms] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState("");
  const [selectedTermId, setSelectedTermId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const theme = useTheme();

  const { fetchLessonPlans, searchLessonPlans } = useLessonPlanOperations();

  const handleError = useCallback(
    (message, error) => {
      console.error(message, error);
      setMessage({ text: message, severity: "error", flag: true });
    },
    [setMessage]
  );

  const fetchInitialData = useCallback(async () => {
    try {
      const allTerms = await fetchAllSchoolTerms();
      setTerms(allTerms);
    } catch (error) {
      handleError("Failed to fetch initial data", error);
    }
  }, [handleError]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  const fetchStudentData = useCallback(async () => {
    try {
      const users = await fetchStudents(selectedAccountId);
      setStudents(users || []);
    } catch (error) {
      handleError("Failed to fetch students", error);
    }
  }, [selectedAccountId, handleError]);

  useEffect(() => {
    if (selectedAccountId) {
      fetchStudentData();
    }
  }, [fetchStudentData, selectedAccountId]);

  const fetchData = useCallback(
    async (signal) => {
      const result =
        selectedStudentId || selectedTermId
          ? await searchLessonPlans(page, rowsPerPage, {
              studentId: selectedStudentId,
              schoolTermId: selectedTermId,
            })
          : await fetchLessonPlans(page, rowsPerPage);

      dispatch(setLessonPlans(result.data));
      return result;
    },
    [
      page,
      rowsPerPage,
      selectedStudentId,
      selectedTermId,
      dispatch,
      searchLessonPlans,
      fetchLessonPlans,
    ]
  );

  const {
    data,
    loading,
    error,
    execute: refetchData,
  } = useAsyncOperation(fetchData);

  useEffect(() => {
    if (error) {
      handleError("Failed to fetch lesson plans", error);
    }
  }, [error, handleError]);

  useEffect(() => {
    refetchData();
  }, [refetchData, page, rowsPerPage, selectedStudentId, selectedTermId]);

  useEffect(() => {
    console.log("Data received from API:", data); // Debugging output
    console.log("Loading state:", loading);
    console.log("Error state:", error);
  }, [data, loading, error]);

  const handleOpenCreateModal = () => {
    setSelectedClass(null);
    setOpenClassModal(true);
  };

  const handleCloseClassModal = () => {
    setSelectedClass(null);
    setOpenClassModal(false);
  };

  const handleCreateClass = async (classData) => {
    try {
      const lessonPlanData = {
        ...classData,
        accountId: selectedAccountId,
      };

      const newClass = await createLessonPlan(lessonPlanData);
      dispatch(addLessonPlan(newClass));
      refetchData();
      handleCloseClassModal();
      setMessage({
        text: "Lesson plan created successfully",
        severity: "success",
        flag: true,
      });
    } catch (error) {
      handleError("Failed to create lesson plan", error);
    }
  };

  const handleUpdateClass = async (id, classData) => {
    try {
      const updatedData = {
        ...classData,
        accountId: selectedAccountId,
      };
      const updatedClass = await updateLessonPlan(id, updatedData);
      dispatch(updateLessonPlanAction(updatedClass));
      refetchData();
      handleCloseClassModal();
      setMessage({
        text: "Lesson plan updated successfully",
        severity: "success",
        flag: true,
      });
    } catch (error) {
      handleError("Failed to update lesson plan", error);
    }
  };

  const handleRowClick = useCallback(
    (row) => navigate(`/lesson-plans/${row.id}`),
    [navigate]
  );

  const handleSelectStudent = (event) => {
    setSelectedStudentId(event.target.value);
    setPage(0);
  };

  const handleSelectTerm = (event) => {
    setSelectedTermId(event.target.value);
    setPage(0);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const columns = useMemo(
    () => [
      { id: "name", label: "Title" },
      { id: "subject", label: "Subject" },
      { id: "description", label: "Description" },
      { id: "durationPerLesson", label: "Duration" },
      { id: "action", label: "" },
    ],
    []
  );

  const renderDesktopRow = useCallback(
    (row, columns) => (
      <>
        {columns.map((column) => (
          <TableCell key={column.id}>
            {column.id === "durationPerLesson" ? (
              `${row[column.id]} min`
            ) : column.id === "action" ? (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleRowClick(row);
                }}
              >
                <ArrowForwardIosIcon fontSize="small" />
              </IconButton>
            ) : (
              row[column.id]
            )}
          </TableCell>
        ))}
      </>
    ),
    [handleRowClick]
  );

  const renderMobileRow = useCallback(
    (row) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box>
          <Typography variant="body2">{row.name}</Typography>
          <Typography variant="body2" color="text.secondary">
            {row.subject ? `${row.subject}, ` : ""}
            {row.durationPerLesson} min
          </Typography>
        </Box>
        <ArrowForwardIosIcon fontSize="small" color="action" />
      </Box>
    ),
    []
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography variant="h2">Lesson Plans</Typography>
        <IconButton
          onClick={handleOpenCreateModal}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <StyledSelect
            size="small"
            displayEmpty
            value={selectedStudentId}
            onChange={handleSelectStudent}
            renderValue={(selected) =>
              !selected ? (
                <em>Select Student</em>
              ) : (
                students.find((item) => item.id === selected)?.firstName +
                " " +
                students.find((item) => item.id === selected)?.lastName
              )
            }
          >
            <MenuItem value="">All</MenuItem>
            {students.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.firstName} {option.lastName}
              </MenuItem>
            ))}
          </StyledSelect>
          <StyledSelect
            size="small"
            displayEmpty
            value={selectedTermId}
            onChange={handleSelectTerm}
            renderValue={(selected) =>
              !selected ? (
                <em>Select Term</em>
              ) : (
                terms.find((item) => item.id === selected)?.name
              )
            }
          >
            <MenuItem value="">All</MenuItem>
            {terms.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </StyledSelect>
        </Stack>
      </Stack>

      <HsTable
        columns={columns}
        data={data?.data || []}
        totalCount={data?.totalCount || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        loading={loading}
        error={error?.message}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        renderDesktopRow={renderDesktopRow}
        renderMobileRow={renderMobileRow}
        onRowClick={handleRowClick}
        showPagination={true}
        rowsPerPageOptions={[10, 25, 50]}
      />

      <CreateLessonPlanModal
        open={openClassModal}
        handleCreate={handleCreateClass}
        handleUpdate={handleUpdateClass}
        handleClose={handleCloseClassModal}
        data={selectedClass}
      />
    </Box>
  );
};

export default ClassesPage;
