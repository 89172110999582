import React from "react";
import { Container, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import HowWeCanHelp from "./how-we-can-help";
import GetStarted from "./get-started";
import Testimonials from "./testimonials";
import CallToAction from "./call-to-action";
import ContactForm from "./contact-form";
import Hero from "./hero";
import PricingDisplay from "../../components/PricingDisplay";

const HeroSection = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "visible",
  // paddingTop: theme.spacing(24), // Add some padding to account for the header
  paddingBottom: theme.spacing(6),
}));

const LoggedOutHome = () => {
  return (
    <Box sx={{ position: "relative" }}>
      <HeroSection
        sx={{ p: 0, m: 0, display: "flex", justifyContent: "center" }}
      >
        <Container
          maxWidth="lg"
          sx={{ p: 0, m: 0, display: "flex", justifyContent: "center" }}
        >
          <Hero />
        </Container>
      </HeroSection>
      <Container maxWidth="lg">
        <Box id="how-we-can-help">
          <HowWeCanHelp />
        </Box>

        <Box id="benefits">
          <GetStarted />
        </Box>

        <Box
          sx={{
            my: 4,
            py: 4,
          }}
          id="pricing"
        >
          <Typography variant="h2" align="center" gutterBottom>
            Pricing
          </Typography>
          <Typography variant="body1" align="center" paragraph sx={{ mb: 4 }}>
            Our platform provides a step-by-step guide on how to manage your
            homeschooling schedule and track your child's progress through their
            lesson plans. With easy-to-use features and intuitive design, you
            can focus on what matters most - your child's education.
          </Typography>
          <PricingDisplay />
        </Box>

        <Box id="testimonials">
          <Testimonials />
        </Box>

        <Box
          id="call-to-action"
          sx={{
            my: 4,
            py: 4,
          }}
        >
          <CallToAction />
        </Box>

        <Box id="contact">
          <ContactForm />
        </Box>
      </Container>
    </Box>
  );
};

export default LoggedOutHome;
