import store from "../redux/store.js";
import {
  clearAuthInfo,
  setAuthInfo,
  setTimeoutMessage,
} from "../redux/authSlice";
import { doPerformRefresh } from "./api";
import { isTokenExpiringWithinTenMinutes } from "../utils/utils";
import { logout as logoutApi } from "../api/authApi";

class AuthManager {
  static async refreshTokenIfNeeded() {
    const state = store.getState();
    const { token } = state.auth;

    if (token && isTokenExpiringWithinTenMinutes(token)) {
      try {
        const newAuthInfo = await doPerformRefresh(undefined);
        store.dispatch(setAuthInfo(newAuthInfo));
        return newAuthInfo.token;
      } catch (error) {
        console.log("Token refresh failed, handling auth error");
        this.handleAuthError();
        throw error;
      }
    }

    return token;
  }

  static handleAuthError(error) {
    console.error("Auth error:", error);
    store.dispatch(setTimeoutMessage());
    store.dispatch(clearAuthInfo());
    // Optionally, you can redirect to the login page here
    window.location.href = "/landing";
  }

  static async logout() {
    try {
      await logoutApi();
    } catch (error) {
      console.error("Logout API call failed:", error);
    } finally {
      console.log("Logout completed, handling auth error");
      this.handleAuthError();
    }
  }
}

export default AuthManager;
