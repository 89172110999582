import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  items: [],
  total: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCart: (state, action) => {
      return {
        ...state,
        ...action.payload,
        total: calculateTotal(action.payload.items),
      };
    },
    addItem: (state, action) => {
      state.items.push(action.payload);
      state.total = calculateTotal(state.items);
    },
    removeItem: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
      state.total = calculateTotal(state.items);
    },
    updateItem: (state, action) => {
      const index = state.items.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index !== -1) {
        state.items[index] = action.payload;
        state.total = calculateTotal(state.items);
      }
    },
    clearCart: (state) => {
      state.items = [];
      state.total = 0;
    },
  },
});

const calculateTotal = (items) => {
  return items.reduce((total, item) => total + item.price * item.quantity, 0);
};

export const { setCart, addItem, removeItem, updateItem, clearCart } =
  cartSlice.actions;

export default cartSlice.reducer;
