import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  shippingAddress: null,
  shippingOption: null,
  shippingOptions: [],
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setShippingAddress: (state, action) => {
      state.shippingAddress = action.payload;
    },
    setShippingOption: (state, action) => {
      state.shippingOption = action.payload;
    },
    setShippingOptions: (state, action) => {
      state.shippingOptions = action.payload;
    },
    clearCheckoutData: (state) => {
      return initialState;
    },
  },
});

export const {
  setShippingAddress,
  setShippingOption,
  setShippingOptions,
  clearCheckoutData,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
