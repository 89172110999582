import React from "react";
import { Box, Button } from "@mui/material";
import { useWizard } from "react-use-wizard";

const Footer = ({
  isSubmitting,
  handleSubmit,
  handleCancel,
  isNextDisabled,
}) => {
  const { isLastStep, nextStep, previousStep, activeStep } = useWizard();

  const handleNext = () => {
    if (isLastStep) {
      handleSubmit();
    } else {
      nextStep();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 3,
        pt: 2,
        borderTop: 1,
        borderColor: "divider",
        bgcolor: "background.paper",
      }}
    >
      <Button
        variant="outlined"
        onClick={handleCancel}
        sx={{
          borderRadius: 50,
          px: 3,
        }}
      >
        Cancel
      </Button>
      <Box sx={{ display: "flex", gap: 2 }}>
        {activeStep > 0 && (
          <Button
            variant="outlined"
            onClick={previousStep}
            sx={{
              borderRadius: 50,
              px: 3,
            }}
          >
            Back
          </Button>
        )}
        <Button
          variant="contained"
          onClick={handleNext}
          disabled={isSubmitting || (isNextDisabled && !isLastStep)}
          sx={{
            borderRadius: 50,
            px: 3,
          }}
        >
          {isLastStep ? "Save" : "Next"}
        </Button>
      </Box>
    </Box>
  );
};

export default Footer;
