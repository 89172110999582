import React from "react";
import { Box, Button, Typography, Paper, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PlanCard = ({ type, price, students, popular }) => {
  const navigate = useNavigate();

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor:
          type === "FAMILY" ? "rgba(255,255,255,0.3)" : "transparent",
        borderRadius: "32px",
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        minWidth: "280px",
      }}
    >
      {popular && (
        <Box
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            backgroundColor: "white",
            borderRadius: "16px",
            px: 2,
            py: 0.5,
          }}
        >
          <Typography variant="caption">Most popular</Typography>
        </Box>
      )}
      <Box
        sx={{
          backgroundColor: "#FFE4B5",
          borderRadius: "12px",
          px: 2,
          py: 0.5,
          mb: 2,
        }}
      >
        <Typography variant="caption">TEST MODE</Typography>
      </Box>
      <Typography variant="h5" gutterBottom>
        {type}
      </Typography>
      <Typography variant="body2" color="text.secondary" gutterBottom>
        {students}
      </Typography>
      <Box sx={{ my: 2 }}>
        <Typography variant="h3" component="span">
          ${price}
        </Typography>
        <Typography variant="body1" component="span" color="text.secondary">
          {" "}
          per year
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => navigate("/register")}
        sx={{
          borderRadius: "28px",
          backgroundColor: "black",
          color: "white",
          "&:hover": {
            backgroundColor: "#333",
          },
        }}
      >
        Start trial
      </Button>
    </Paper>
  );
};

const PricingDisplay = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#E3D9E3",
        borderRadius: 2,
        border: "2px solid #ccc",
        p: 4,
      }}
    >
      <Grid container spacing={3} justifyContent="center" alignItems="stretch">
        <Grid item>
          <PlanCard type="BASIC" price="45" students="One student" />
        </Grid>
        <Grid item>
          <PlanCard
            type="FAMILY"
            price="90"
            students="Up to 10 students"
            popular
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PricingDisplay;
