// WizardContext.jsx

import React, { createContext, useContext, useMemo } from "react";
import { useBaseWizardState } from "../hooks/useBaseWizardState";

export const WizardContext = createContext(null);

export const WizardProvider = ({ children, steps }) => {
  const wizardState = useBaseWizardState(steps);

  const contextValue = useMemo(() => wizardState, [wizardState]);

  return (
    <WizardContext.Provider value={contextValue}>
      {children}
    </WizardContext.Provider>
  );
};

export const useWizardContext = () => {
  const context = useContext(WizardContext);
  if (!context) {
    throw new Error("useWizardContext must be used within a WizardProvider");
  }
  return context;
};
