import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useSelector } from "react-redux";
import { debug } from "../../utils/debug";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { usePlannerWizardContext } from "../../contexts/planner-wizard-context";
import { fetchActiveAndFutureTerms } from "../../api/termApi";
import { getUsers, ROLE_STUDENT } from "../../api/userApi";
import { getStudentsByTermId } from "../../api/studentApi";

const TermStudentSelection = () => {
  const {
    wizardData,
    updateWizardData,
    updateStepValidation,
    validateStep,
    activeStep: stepIndex,
    stepValidation,
    clearWizardData,
  } = usePlannerWizardContext();

  // Use refs to maintain references to selected objects
  const termRef = useRef(wizardData?.term);
  const studentRef = useRef(wizardData?.student);
  const mountedRef = useRef(true);
  const fetchingRef = useRef({
    terms: false,
    students: false,
  });

  // Initialize with empty values and update when data is available
  const [selectedTermId, setSelectedTermId] = useState(
    wizardData?.term?.id || ""
  );
  const [selectedStudentId, setSelectedStudentId] = useState(
    wizardData?.student?.id || ""
  );

  const { selectedAccountId } = useSelector((state) => state.auth);

  // Setup async operations for fetching data
  const {
    execute: fetchTerms,
    loading: loadingTerms,
    data: terms,
    error: termsError,
    abort: abortTerms,
  } = useAsyncOperation(fetchActiveAndFutureTerms, {
    onError: (error) => {
      if (error?.message === "canceled") return;
      debug.wizard.error("Error fetching terms:", error);
    },
  });

  const {
    execute: fetchStudents,
    loading: loadingStudents,
    data: studentsResponse,
    error: studentsError,
    abort: abortStudents,
  } = useAsyncOperation(
    (termId) => getStudentsByTermId(termId, selectedAccountId),
    {
      onError: (error) => {
        if (error?.message === "canceled") return;
        debug.wizard.error("Error fetching students:", error);
      },
    }
  );

  useEffect(() => {
    return () => {
      mountedRef.current = false;
      abortTerms?.();
      abortStudents?.();
      clearWizardData?.();
    };
  }, [abortTerms, abortStudents, clearWizardData]);

  // Fetch data only if we don't have selections
  useEffect(() => {
    if (!mountedRef.current) return;

    if (!terms?.data && !fetchingRef.current.terms) {
      fetchingRef.current.terms = true;
      fetchTerms();
    }
    if (selectedTermId && !fetchingRef.current.students) {
      fetchingRef.current.students = true;
      fetchStudents(selectedTermId);
    }
  }, [fetchTerms, fetchStudents, terms, selectedTermId]);

  // Update selections when data is loaded
  useEffect(() => {
    if (!mountedRef.current) return;

    if (terms?.data) {
      // Find the term in the loaded data
      if (selectedTermId) {
        const term = terms.data.find((t) => t.id === selectedTermId);
        if (term) {
          termRef.current = term;
          updateWizardData({ term });
        }
      }
    }
  }, [terms, selectedTermId, updateWizardData]);

  // Update how we handle student data
  const getStudentsList = (response) => {
    if (!response) return [];
    // Handle both array response and paginated response
    return Array.isArray(response) ? response : response.content || [];
  };

  useEffect(() => {
    if (!mountedRef.current) return;

    const studentsList = getStudentsList(studentsResponse);
    debug.wizard.state("Processing students response:", {
      studentsResponse,
      studentsList,
    });

    if (studentsList.length > 0) {
      // Find the student in the loaded data
      if (selectedStudentId) {
        const student = studentsList.find((s) => s.id === selectedStudentId);
        if (student) {
          studentRef.current = student;
          updateWizardData({ student });
        }
      }
      // If we don't have a selection but have students, select the first one
      else if (!selectedStudentId) {
        const student = studentsList[0];
        setSelectedStudentId(student.id);
        studentRef.current = student;
        updateWizardData({ student });
      }
    } else {
      // Clear student selection if no students available
      setSelectedStudentId("");
      studentRef.current = null;
      updateWizardData({ student: null });
    }
  }, [studentsResponse, selectedStudentId, updateWizardData]);

  // Validate whenever selections change
  useEffect(() => {
    debug.wizard.state("Step1 state:", {
      selectedTermId,
      selectedStudentId,
      stepValidation,
      stepIndex,
      hasValidateStep: Boolean(validateStep),
    });

    const term = terms?.data?.find((t) => t.id === selectedTermId);
    const studentsList = getStudentsList(studentsResponse);
    const student = studentsList.find((s) => s.id === selectedStudentId);

    const validationResult =
      validateStep?.(stepIndex, {
        term,
        student,
      }) ?? false;

    if (validationResult !== stepValidation[stepIndex]) {
      debug.wizard.state("Updating step validation:", {
        stepIndex,
        validationResult,
      });
      updateStepValidation(stepIndex, validationResult);
    }

    // Keep wizard data in sync
    if (term !== termRef.current) {
      termRef.current = term;
      updateWizardData({ term });
    }
    if (student !== studentRef.current) {
      studentRef.current = student;
      updateWizardData({ student });
    }
  }, [
    selectedTermId,
    selectedStudentId,
    validateStep,
    stepIndex,
    stepValidation,
    updateStepValidation,
    terms,
    studentsResponse,
    updateWizardData,
  ]);

  const handleTermChange = (event) => {
    const termId = event.target.value;
    const term = terms?.data?.find((t) => t.id === termId);
    termRef.current = term;
    debug.wizard.state("Term selection:", {
      termId,
      term,
      type: typeof term,
      value: event.target.value,
    });
    setSelectedTermId(termId);
    updateWizardData({ term });

    // Clear student selection when term changes
    setSelectedStudentId("");
    studentRef.current = null;
    updateWizardData({ student: null });

    // Fetch students for the selected term
    if (termId) {
      fetchingRef.current.students = false;
      fetchStudents(termId);
    }
  };

  const handleStudentChange = (event) => {
    const studentId = event.target.value;
    const studentsList = getStudentsList(studentsResponse);
    const student = studentsList.find((s) => s.id === studentId);
    studentRef.current = student;
    debug.wizard.state("Student selection:", {
      studentId,
      student,
      type: typeof student,
      value: event.target.value,
    });
    setSelectedStudentId(studentId);
    updateWizardData({ student });
  };

  if (loadingTerms || loadingStudents) {
    return <CircularProgress />;
  }

  const termOptions = terms?.data || [];
  const studentOptions = getStudentsList(studentsResponse);

  debug.wizard.state("Render state:", {
    loadingStudents,
    studentsResponse,
    studentOptions,
    selectedTermId,
    selectedStudentId,
  });

  return (
    <Box>
      <FormControl fullWidth margin="normal">
        <InputLabel>Term</InputLabel>
        <Select
          value={selectedTermId}
          onChange={handleTermChange}
          label="Term"
          disabled={loadingTerms}
        >
          {loadingTerms ? (
            <MenuItem value="">
              <em>Loading terms...</em>
            </MenuItem>
          ) : termOptions.length === 0 ? (
            <MenuItem value="">
              <em>No terms available</em>
            </MenuItem>
          ) : (
            termOptions.map((term) => (
              <MenuItem key={term.id} value={term.id}>
                {term.name}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel>Student</InputLabel>
        <Select
          value={selectedStudentId}
          onChange={handleStudentChange}
          label="Student"
          disabled={!selectedTermId || loadingStudents}
        >
          {!selectedTermId ? (
            <MenuItem value="">
              <em>Select a term first</em>
            </MenuItem>
          ) : loadingStudents ? (
            <MenuItem value="">
              <em>Loading students...</em>
            </MenuItem>
          ) : studentOptions.length === 0 ? (
            <MenuItem value="">
              <em>No students enrolled in this term</em>
            </MenuItem>
          ) : (
            studentOptions.map((student) => (
              <MenuItem key={student.id} value={student.id}>
                {`${student.firstName} ${student.lastName}`}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
    </Box>
  );
};

export default TermStudentSelection;
