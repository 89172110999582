import React from "react";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { NumericFormat } from "react-number-format";

export const CustomTextField = ({
  field,
  form: { touched, errors },
  ...props
}) => (
  <TextField
    {...field}
    {...props}
    error={touched[field.name] && Boolean(errors[field.name])}
    helperText={touched[field.name] && errors[field.name]}
  />
);

export const CustomAutocomplete = ({
  field,
  form,
  options,
  label,
  helperText,
  ...props
}) => (
  <Autocomplete
    {...field}
    {...props}
    options={options}
    getOptionLabel={(option) => option.label || ""}
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
        helperText={helperText}
        error={form.touched[field.name] && Boolean(form.errors[field.name])}
      />
    )}
    onChange={(_, value) => form.setFieldValue(field.name, value)}
  />
);

export const CustomNumericFormat = ({
  field,
  form: { touched, errors },
  ...props
}) => (
  <NumericFormat
    {...field}
    {...props}
    customInput={TextField}
    error={touched[field.name] && Boolean(errors[field.name])}
    helperText={touched[field.name] && errors[field.name]}
    thousandSeparator={true}
  />
);
