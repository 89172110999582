import React, { useEffect, useCallback } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Button } from "@mui/material";
import apiUrl from "../config/config";
import { debug } from "../utils/debug";
import { CONFIG } from "../config/config";

function HsGoogleButton({
  mode = "login", // 'login' or 'register'
  onSubmit,
  inviteCode,
  loginTrigger,
  fullWidth = false,
  disabled = false,
}) {
  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      debug.auth.log("Google auth successful", tokenResponse);
    },
    onError: () => {
      debug.auth.error("Google auth failed");
    },
    flow: "auth-code",
    ux_mode: "redirect",
    redirect_uri: `${apiUrl}/api/v1/auth/google-signin`,
    state: mode === "register" ? inviteCode : undefined,
  });

  const handleButtonClick = useCallback(() => {
    if (onSubmit) {
      onSubmit();
    }
    googleLogin();
  }, [onSubmit, googleLogin]);

  useEffect(() => {
    if (loginTrigger) {
      handleButtonClick();
    }
  }, [loginTrigger, handleButtonClick]);

  return (
    <Button
      variant="darkRounded"
      onClick={handleButtonClick}
      fullWidth={fullWidth}
      disabled={disabled}
      startIcon={
        <img
          src={`${CONFIG.REACT_APP_PUBLIC_URL}/assets/images/google-logo.png`}
          alt="Google logo"
          style={{ width: "24px", height: "24px" }}
        />
      }
    >
      {mode === "register" ? "Sign up with Google" : "Sign in with Google"}
    </Button>
  );
}

export default HsGoogleButton;
