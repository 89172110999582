import api from "./api";
import apiUrl from "../config/config";

export const getOrder = async (orderId, signal) => {
  if (!orderId) {
    throw new Error("Order ID is required");
  }

  try {
    const response = await api.get(`${apiUrl}/api/v1/orders/${orderId}`, {
      signal,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          throw new Error("You don't have permission to view this order");
        case 404:
          throw new Error("Order not found");
        default:
          throw new Error("Failed to fetch order details");
      }
    }
    throw error;
  }
};

export const getUserOrders = async (page, size, signal) => {
  try {
    const response = await api.get(`${apiUrl}/api/v1/orders/user`, {
      params: {
        page,
        size,
      },
      signal,
    });
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      throw new Error("Please log in to view your orders");
    }
    throw new Error("Failed to fetch orders");
  }
};
