import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Stack,
  Button,
  Modal,
  TableCell,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import HsTable from "../../components/common/HsTable";
import CreateLessonPlanModal from "./CreateLessonPlanModal";
import ApplyToTermModal from "./ApplyToTermModal";
import CreateLessonModal from "./CreateLessonModal";
import {
  getLessonPlanById,
  applyLessonPlanToTerm,
  removeLessonPlanFromTerm,
  updateLessonPlan,
  getClassInstances,
} from "../../api/lessonPlanApi";
import {
  getLessonPlanLessons,
  deleteClassLesson,
} from "../../api/classLessonsApi";
import { ROLE_STUDENT, getUsers } from "../../api/userApi";
import { fetchActiveAndFutureTerms } from "../../api/termApi";
import { useReportContext } from "../../contexts/report-context";
import { updateLessonPlan as updateLessonPlanAction } from "../../redux/lessonPlanSlice";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";

const LessonPlansDetailsPage = () => {
  const { id } = useParams();
  const { setMessage } = useReportContext();
  const dispatch = useDispatch();
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );

  const [selectedClassLesson, setSelectedClassLesson] = useState(null);
  const [selectedClassInstance, setSelectedClassInstance] = useState(null);
  const [openApplyModal, setOpenApplyModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openLessonModal, setOpenLessonModal] = useState(false);
  const [openClassModal, setOpenClassModal] = useState(false);

  const {
    data: lessonPlanDetails,
    execute: fetchLessonPlanDetails,
    loading: lessonPlanLoading,
    error: lessonPlanError,
  } = useAsyncOperation(getLessonPlanById);

  const {
    data: classLessons,
    execute: fetchClassLessons,
    loading: lessonsLoading,
  } = useAsyncOperation(getLessonPlanLessons);

  const {
    data: classInstances,
    execute: fetchClassInstances,
    loading: instancesLoading,
  } = useAsyncOperation(getClassInstances);

  const {
    data: terms,
    execute: fetchTerms,
    loading: termsLoading,
  } = useAsyncOperation(fetchActiveAndFutureTerms);

  const {
    data: students,
    execute: fetchStudents,
    loading: studentsLoading,
  } = useAsyncOperation(getUsers);

  const { execute: executeApplyLessonPlan } = useAsyncOperation(
    applyLessonPlanToTerm
  );

  const { execute: executeRemoveLessonPlan } = useAsyncOperation(
    removeLessonPlanFromTerm
  );

  const { execute: executeUpdateLessonPlan } =
    useAsyncOperation(updateLessonPlan);

  const fetchData = useCallback(
    async (signal) => {
      if (!id || !selectedAccountId) return;

      try {
        const results = await Promise.all([
          fetchLessonPlanDetails(id, signal),
          fetchClassLessons(id, signal),
          fetchClassInstances(id, 0, 10, signal),
          fetchTerms(signal),
          fetchStudents(selectedAccountId, ROLE_STUDENT, signal),
        ]);

        if (!results[0]) {
          throw new Error("Lesson plan not found");
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error fetching data:", error);
          setMessage({
            text: error.message || "Failed to fetch class details",
            severity: "error",
            flag: true,
          });
        }
      }
    },
    [
      id,
      selectedAccountId,
      fetchLessonPlanDetails,
      fetchClassLessons,
      fetchClassInstances,
      fetchTerms,
      fetchStudents,
      setMessage,
    ]
  );

  const { execute: executeDataFetch } = useAsyncOperation(fetchData);

  useEffect(() => {
    if (id && selectedAccountId) {
      executeDataFetch();
    }
  }, [id, selectedAccountId, executeDataFetch]);

  const isLoading =
    lessonPlanLoading ||
    lessonsLoading ||
    instancesLoading ||
    termsLoading ||
    studentsLoading;

  useEffect(() => {
    if (lessonPlanError) {
      setMessage({
        text: lessonPlanError.message || "Failed to load lesson plan",
        severity: "error",
        flag: true,
      });
    }
  }, [lessonPlanError, setMessage]);

  const handleOpenApplyModal = () => setOpenApplyModal(true);
  const handleCloseApplyModal = () => setOpenApplyModal(false);

  const handleOpenRemoveConfirmModal = useCallback((rowData) => {
    setSelectedClassInstance(rowData);
    setOpenRemoveModal(true);
  }, []);

  const handleCloseRemoveConfirmModal = () => {
    setSelectedClassInstance(null);
    setOpenRemoveModal(false);
  };

  const handleRemoveClassFromTerm = async () => {
    try {
      if (!selectedClassInstance?.studentId) {
        throw new Error("Student ID is required to remove class from term");
      }

      await executeRemoveLessonPlan(
        id,
        selectedClassInstance.termId,
        selectedClassInstance.studentId
      );
      fetchData();
      handleCloseRemoveConfirmModal();
      setMessage({
        text: "Removed the class from term successfully!",
        severity: "success",
        flag: true,
      });
    } catch (err) {
      setMessage({
        text: err.message || "Failed to remove class from term",
        severity: "error",
        flag: true,
      });
    }
  };

  const handleApplyClassToTerm = () => {
    fetchData();
    setOpenApplyModal(false);
  };

  const handleOpenLessonModal = () => setOpenLessonModal(true);
  const handleCloseLessonModal = () => {
    setSelectedClassLesson(null);
    setOpenLessonModal(false);
  };

  const handleCreateLesson = () => {
    fetchData();
    handleCloseLessonModal();
  };

  const handleOpenLessonEditModal = useCallback((rowData) => {
    setSelectedClassLesson(rowData);
    setOpenLessonModal(true);
  }, []);

  const handleOpenEditModal = () => setOpenClassModal(true);
  const handleCloseClassModal = () => setOpenClassModal(false);

  const handleUpdateLessonPlan = async (id, updatedData) => {
    try {
      const dataWithAccountId = {
        ...updatedData,
        accountId: selectedAccountId,
      };
      const updatedClass = await executeUpdateLessonPlan(id, dataWithAccountId);
      dispatch(updateLessonPlanAction(updatedClass));
      fetchLessonPlanDetails(id);
      handleCloseClassModal();
      setMessage({
        text: "Lesson plan updated successfully",
        severity: "success",
        flag: true,
      });
    } catch (error) {
      setMessage({
        text: "Failed to update lesson plan",
        severity: "error",
        flag: true,
      });
    }
  };

  const handleRemoveLesson = useCallback(
    async (id) => {
      try {
        await deleteClassLesson(id);
        fetchData();
        setMessage({
          text: "Removed the lesson successfully!",
          severity: "success",
          flag: true,
        });
      } catch (err) {
        setMessage({
          text: "Failed to remove the lesson",
          severity: "error",
          flag: true,
        });
      }
    },
    [fetchData, setMessage]
  );

  const lessonColumns = [
    { id: "title", label: "Title" },
    { id: "description", label: "Description" },
    { id: "workItems", label: "Work Items" },
    { id: "lessonNumber", label: "Lesson Number" },
    { id: "durationInMinutes", label: "Duration (minutes)" },
    { id: "action", label: "Action" },
  ];

  const enrollmentColumns = [
    { id: "studentName", label: "Student" },
    { id: "termName", label: "Term" },
    { id: "action", label: "Action" },
  ];

  const renderLessonDesktopRow = useCallback(
    (row, columns) => (
      <>
        {columns.map((column) => (
          <TableCell key={column.id}>
            {column.id === "workItems" ? (
              <Box>
                {row[column.id]?.map((item, index) => (
                  <Typography
                    key={index}
                    variant="body2"
                    color="text.secondary"
                  >
                    • {item.description}
                  </Typography>
                ))}
              </Box>
            ) : column.id === "action" ? (
              <Stack direction="row" spacing={1}>
                <Button
                  variant="outlined"
                  onClick={() => handleOpenLessonEditModal(row)}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleRemoveLesson(row.id)}
                >
                  Delete
                </Button>
              </Stack>
            ) : (
              row[column.id]
            )}
          </TableCell>
        ))}
      </>
    ),
    [handleOpenLessonEditModal, handleRemoveLesson]
  );

  const renderEnrollmentDesktopRow = useCallback(
    (row, columns) => (
      <>
        {columns.map((column) => (
          <TableCell key={column.id}>
            {column.id === "action" ? (
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleOpenRemoveConfirmModal(row)}
              >
                Remove
              </Button>
            ) : (
              row[column.id]
            )}
          </TableCell>
        ))}
      </>
    ),
    [handleOpenRemoveConfirmModal]
  );

  if (isLoading) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography>Loading class details...</Typography>
      </Box>
    );
  }

  if (!lessonPlanDetails) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">
          Unable to load lesson plan details. Please try again later.
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h4">{lessonPlanDetails.name}</Typography>
        <Button variant="contained" onClick={handleOpenEditModal}>
          Edit
        </Button>
      </Stack>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="bodyFV">
            {lessonPlanDetails.description}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="bodyFV">
            Subject: {lessonPlanDetails.subject}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="bodyFV">
            Duration: {lessonPlanDetails.durationPerLesson} minutes
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="bodyFV">
            Frequency: {lessonPlanDetails.lessonFrequency}
            {lessonPlanDetails.isOngoing ? " - Ongoing" : ""}
          </Typography>
        </Grid>
      </Grid>

      {!lessonPlanDetails.isOngoing && (
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Lessons</Typography>
              <Button variant="contained" onClick={handleOpenLessonModal}>
                Create Lesson
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <HsTable
              columns={lessonColumns}
              data={classLessons?.content || []}
              totalCount={classLessons?.totalElements || 0}
              page={0}
              rowsPerPage={(classLessons?.content || []).length}
              loading={!classLessons}
              onPageChange={() => {}}
              onRowsPerPageChange={() => {}}
              renderDesktopRow={renderLessonDesktopRow}
              showPagination={false}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Enrollment</Typography>
            <Button variant="contained" onClick={handleOpenApplyModal}>
              Enroll Student
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <HsTable
            columns={enrollmentColumns}
            data={
              classInstances?.content?.flatMap((instance) =>
                instance.students.map((student) => ({
                  id: `${instance.termSummary.id}-${student.id}`,
                  studentId: student.id,
                  studentName: `${student.firstName} ${student.lastName}`,
                  termId: instance.termSummary.id,
                  termName: instance.termSummary.name,
                }))
              ) || []
            }
            totalCount={(classInstances?.content || []).length}
            page={0}
            rowsPerPage={(classInstances?.content || []).length}
            loading={!classInstances}
            onPageChange={() => {}}
            onRowsPerPageChange={() => {}}
            renderDesktopRow={renderEnrollmentDesktopRow}
            showPagination={false}
          />
        </Grid>
      </Grid>

      <CreateLessonPlanModal
        open={openClassModal}
        handleUpdate={handleUpdateLessonPlan}
        handleClose={handleCloseClassModal}
        data={lessonPlanDetails}
      />
      <ApplyToTermModal
        open={openApplyModal}
        lessonPlanId={id}
        lessonPlanDetails={lessonPlanDetails}
        terms={terms?.data || []}
        students={students || []}
        handleClose={handleCloseApplyModal}
        handleApply={handleApplyClassToTerm}
      />
      <CreateLessonModal
        open={openLessonModal}
        lessonPlanId={id}
        data={selectedClassLesson}
        handleCreate={handleCreateLesson}
        handleClose={handleCloseLessonModal}
      />

      <Modal open={openRemoveModal} onClose={handleCloseRemoveConfirmModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Confirm Removal
          </Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to remove this student from this term?
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="flex-end" mt={2}>
            <Button variant="outlined" onClick={handleCloseRemoveConfirmModal}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleRemoveClassFromTerm}
            >
              Remove
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default LessonPlansDetailsPage;
