import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  TableCell,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useReportContext } from "../../contexts/report-context";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import HsTable from "../../components/common/HsTable";
import {
  fetchActiveTerms,
  fetchPastTerms,
  fetchFutureTerms,
} from "../../api/termApi";
import { getUsers, ROLE_STUDENT } from "../../api/userApi";

const ReportGenerator = () => {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const { setMessage } = useReportContext();
  const theme = useTheme();

  // Fetch users with useAsyncOperation
  const { data: users, execute: fetchUsers } = useAsyncOperation(
    async (signal) => {
      const response = await getUsers(selectedAccountId, ROLE_STUDENT, signal);
      return Array.isArray(response) ? response : [];
    }
  );

  // Fetch terms with useAsyncOperation
  const { data: terms, execute: fetchTerms } = useAsyncOperation(
    async (signal) => {
      const [activeTerms, pastTerms, futureTerms] = await Promise.all([
        fetchActiveTerms(0, 100, signal),
        fetchPastTerms(0, 100, signal),
        fetchFutureTerms(0, 100, signal),
      ]);

      const combinedTerms = [
        ...(activeTerms?.data || []),
        ...(pastTerms?.data || []),
        ...(futureTerms?.data || []),
      ];

      return Array.isArray(combinedTerms) ? combinedTerms : [];
    }
  );

  // Simulated report data, replace with actual API call
  const {
    data: reports,
    execute: fetchReports,
    error: reportsError,
    loading: reportsLoading,
  } = useAsyncOperation(async () => {
    // Simulate fetching report data
    // Replace with actual API call
    const response = [
      {
        id: 1,
        title: "Attendance Report",
        term: "Spring 2023",
        date: "2024-07-01T00:00:00Z",
        fileSize: "25 MB",
        downloadLink: "http://example.com/download1",
        expirationDate: "2024-07-01T23:59:59Z",
      },
      {
        id: 2,
        title: "Attendance Report",
        term: "Spring 2023",
        date: "2024-07-01T00:00:00Z",
        fileSize: "25 MB",
        downloadLink: "http://example.com/download1",
        expirationDate: "2024-07-01T23:59:59Z",
      },
    ];
    return response;
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const renderDesktopRow = useCallback((row, columns) => {
    return columns.map((column) => (
      <TableCell key={column.id}>
        {column.renderCell ? column.renderCell(row) : row[column.id]}
      </TableCell>
    ));
  }, []);

  const renderMobileRow = useCallback((row) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            {row.title}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CalendarTodayIcon
                sx={{ fontSize: "small", mr: 0.5, color: "text.secondary" }}
              />
              <Typography variant="body2" color="text.secondary">
                {formatDate(row.date)}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FileCopyIcon
                sx={{ fontSize: "small", mr: 0.5, color: "text.secondary" }}
              />
              <Typography variant="body2" color="text.secondary">
                {row.fileSize}
              </Typography>
            </Box>
          </Box>
        </Box>
        <ArrowForwardIosIcon sx={{ color: "text.secondary" }} />
      </Box>
    );
  }, []);

  useEffect(() => {
    fetchUsers();
    fetchTerms();
    fetchReports();
  }, [fetchUsers, fetchTerms, fetchReports]);

  const handleGenerateReport = useCallback(() => {
    // Implement report generation logic here
    setMessage({
      text: "Report generation is not yet implemented.",
      severity: "info",
      flag: true,
    });
  }, [setMessage]);

  const handleRowClick = useCallback((rowData) => {
    // Implement row click handling logic here
    console.log("Row clicked:", rowData);
  }, []);

  const handlePageChange = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const handleRowsPerPageChange = useCallback((newRowsPerPage) => {
    setPageSize(newRowsPerPage);
    setPage(0);
  }, []);

  const columns = useMemo(
    () => [
      { id: "title", label: "Title", flex: 1 },
      { id: "term", label: "Term", flex: 1 },
      {
        id: "date",
        label: "Date",
        flex: 1,
        renderCell: (row) => formatDate(row.date),
      },
      { id: "fileSize", label: "File Size", flex: 1 },
      {
        id: "expirationDate",
        label: "Expiration",
        flex: 1,
        renderCell: (row) => formatDate(row.expirationDate),
      },
      {
        id: "downloadLink",
        label: "Download",
        flex: 1,
        renderCell: (row) =>
          row.downloadLink ? (
            <Button
              variant="contained"
              size="small"
              href={row.downloadLink}
              download
            >
              Download
            </Button>
          ) : (
            "N/A"
          ),
      },
    ],
    []
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography variant="h2">Reports</Typography>
        <IconButton
          onClick={handleGenerateReport}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HsTable
            columns={columns}
            data={reports || []}
            totalCount={reports?.length || 0}
            page={page}
            rowsPerPage={pageSize}
            loading={reportsLoading}
            error={reportsError}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onRowClick={handleRowClick}
            showPagination={true}
            rowsPerPageOptions={[5, 10, 15, 20]}
            renderDesktopRow={renderDesktopRow}
            renderMobileRow={renderMobileRow}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportGenerator;
