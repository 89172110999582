import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useReportContext } from "../contexts/report-context";

/**
 * Component to manage calendar connection status and show reconnect prompts
 * Must be used within ReportContextProvider
 */
const CalendarConnectionManager = () => {
  const { googleCalendarConnected, reconnectGoogleCalendar, loggedIn } =
    useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { setMessage } = useReportContext();

  useEffect(() => {
    if (loggedIn && reconnectGoogleCalendar) {
      setMessage({
        text: "Your Google Calendar connection needs to be renewed. Please reconnect your calendar.",
        severity: "warning",
        flag: true,
        action: {
          label: "Reconnect",
          onClick: () => navigate("/account-settings"),
        },
      });
    }
  }, [loggedIn, reconnectGoogleCalendar, setMessage, navigate]);

  return null; // This component doesn't render anything
};

export default CalendarConnectionManager;
