import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

export const validateTimeRanges = (instructionHours) => {
  const errors = {};

  Object.entries(instructionHours).forEach(([day, { isSelected, value }]) => {
    if (!isSelected || !value?.length) return;

    const dayErrors = [];

    // Sort ranges by start time for overlap checking
    const sortedRanges = [...value].sort((a, b) => {
      if (!a.startTime || !b.startTime) return 0;
      return dayjs(getFullDate(a.startTime)).diff(
        dayjs(getFullDate(b.startTime))
      );
    });

    sortedRanges.forEach((range, index) => {
      const rangeErrors = {};

      // Validate start time exists and is valid
      if (!range.startTime) {
        rangeErrors.startTime = "Start time is required";
      }

      // Validate end time exists and is valid
      if (!range.endTime) {
        rangeErrors.endTime = "End time is required";
      }

      if (range.startTime && range.endTime) {
        const start = dayjs(getFullDate(range.startTime));
        const end = dayjs(getFullDate(range.endTime));

        // Validate end time is after start time
        if (end.isSameOrBefore(start)) {
          rangeErrors.endTime = "End time must be after start time";
        }

        // Check for overlaps with other ranges
        for (let i = 0; i < sortedRanges.length; i++) {
          if (i !== index) {
            const otherRange = sortedRanges[i];
            const otherStart = dayjs(getFullDate(otherRange.startTime));
            const otherEnd = dayjs(getFullDate(otherRange.endTime));

            if (
              start.isBetween(otherStart, otherEnd, null, "[]") ||
              end.isBetween(otherStart, otherEnd, null, "[]") ||
              otherStart.isBetween(start, end, null, "[]") ||
              otherEnd.isBetween(start, end, null, "[]")
            ) {
              rangeErrors.startTime = "Time ranges cannot overlap";
              rangeErrors.endTime = "Time ranges cannot overlap";
            }
          }
        }
      }

      if (Object.keys(rangeErrors).length > 0) {
        dayErrors[index] = rangeErrors;
      }
    });

    if (dayErrors.length > 0) {
      errors[day] = dayErrors;
    }
  });

  return errors;
};

export const getFullDate = (time) => {
  if (!time) return null;
  const [hours, minutes] = time.split(":");
  const date = new Date();
  date.setHours(parseInt(hours, 10));
  date.setMinutes(parseInt(minutes, 10));
  return date;
};
