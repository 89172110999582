// AccountSelectorModal.js
import React, { useState } from "react";
import { Button, Select, MenuItem } from "@mui/material";
import HsModal from "../common/HsModal";

const AccountSelectorModal = ({
  authResponse,
  isOpen,
  handleClose,
  handleConfirmAccount,
}) => {
  const [selectedAccount, setSelectedAccount] = useState("");

  const handleAccountSelect = (e) => {
    setSelectedAccount(e.target.value);
  };

  const handleContinue = () => {
    handleConfirmAccount(selectedAccount);
    handleClose();
  };

  return (
    <HsModal
      open={isOpen}
      handleClose={handleClose}
      title="Select an Account"
      dismissable={false}
    >
      <Select
        value={selectedAccount ?? ""}
        onChange={handleAccountSelect}
        displayEmpty
        fullWidth
        required
      >
        <MenuItem value="" disabled>
          Select an account
        </MenuItem>
        {authResponse &&
          authResponse.loggedInUserAccounts &&
          authResponse.loggedInUserAccounts.length > 1 &&
          authResponse.loggedInUserAccounts.map((account) => (
            <MenuItem key={account.id} value={account.id}>
              {account.name}
            </MenuItem>
          ))}
      </Select>

      <Button
        variant="contained"
        color="primary"
        onClick={handleContinue}
        fullWidth
        sx={{ mt: 2 }}
        disabled={!selectedAccount}
      >
        Continue
      </Button>
    </HsModal>
  );
};

export default AccountSelectorModal;
