import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Stack,
  FormControlLabel,
  Tooltip,
  IconButton,
  Switch,
  MenuItem,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useSelector } from "react-redux";
import { createCalendarEvent } from "../../api/calendarApi";
import { fetchActiveTerms } from "../../api/termApi";
import { useReportContext } from "../../contexts/report-context";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { debug } from "../../utils/debug";
import InfoIcon from "@mui/icons-material/Info";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import HsModal from "../../components/common/HsModal";

dayjs.extend(isBetween);

const defaultFormData = {
  title: "",
  description: "",
  startTime: dayjs().add(1, "hour").startOf("hour"),
  endTime: dayjs().add(2, "hour").startOf("hour"),
  termId: "",
  rebalance: false,
};

const isTermOverlapping = (term, eventStart, eventEnd) => {
  const termStart = dayjs(term.startDate);
  const termEnd = dayjs(term.endDate);

  return (
    eventStart.isBetween(termStart, termEnd, "minute", "[]") ||
    eventEnd.isBetween(termStart, termEnd, "minute", "[]") ||
    (eventStart.isBefore(termStart) && eventEnd.isAfter(termEnd))
  );
};

const CreateCalendarEventModal = ({
  open,
  handleSuccess,
  handleFailure,
  handleCancel,
  onEventCreated,
}) => {
  const { loggedInUser, selectedAccountId } = useSelector(
    (state) => state.auth
  );
  const { setMessage } = useReportContext();
  const {
    execute: fetchTerms,
    data,
    error: termsError,
    loading: termsLoading,
  } = useAsyncOperation(fetchActiveTerms);

  const [formData, setFormData] = useState(defaultFormData);
  const [availableTerms, setAvailableTerms] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Reset form when modal opens/closes
  useEffect(() => {
    if (open) {
      try {
        fetchTerms(0, 100);
      } catch (error) {
        debug.calendar.error("Failed to fetch terms:", error);
        setMessage({
          text: "Unable to load terms. Some features may be limited.",
          severity: "warning",
          flag: true,
        });
      }
    } else {
      setFormData(defaultFormData);
      setAvailableTerms([]);
    }
  }, [open, fetchTerms, setMessage]);

  // Update available terms when times or terms change
  useEffect(() => {
    if (!data?.data?.length) return;

    const overlappingTerms = data.data.filter((term) =>
      isTermOverlapping(term, formData.startTime, formData.endTime)
    );
    setAvailableTerms(overlappingTerms);
  }, [data, formData.startTime, formData.endTime]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleTimeChange = (field) => (newValue) => {
    setFormData((prev) => ({ ...prev, [field]: newValue }));
  };

  const handleTermChange = (e) => {
    const newTermId = e.target.value;
    if (formData.termId && !availableTerms.find((t) => t.id === newTermId)) {
      setFormData((prev) => ({
        ...prev,
        termId: "",
        rebalance: false,
      }));
    } else {
      setFormData((prev) => ({ ...prev, termId: newTermId }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Find the selected term to get its name
      const selectedTerm = availableTerms.find(
        (term) => term.id === formData.termId
      );

      const eventData = {
        name: formData.title,
        userId: loggedInUser.id,
        title: formData.title,
        description: formData.description,
        userStartTime: formData.startTime.format("YYYY-MM-DDTHH:mm:ss"),
        userEndTime: formData.endTime.format("YYYY-MM-DDTHH:mm:ss"),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        recurrenceType: "NON_RECURRING",
        source: "LOCAL",
        checklistStatus: "PENDING",
        type: "USER",
        termId: formData.termId || undefined,
        termName: selectedTerm?.name,
      };

      await createCalendarEvent(selectedAccountId, eventData, {
        rebalance: formData.termId ? formData.rebalance : false,
      });

      if (onEventCreated) {
        onEventCreated();
      }

      handleSuccess();
      setMessage({
        text: "Event created successfully",
        severity: "success",
        flag: true,
      });
    } catch (error) {
      debug.calendar.error("Failed to create event:", error);
      setMessage({
        text: "Failed to create event",
        severity: "error",
        flag: true,
      });
      handleFailure();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <HsModal open={open} handleClose={handleCancel} title="Create Event">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              name="title"
              label="Title"
              required
              fullWidth
              value={formData.title}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="description"
              label="Description (Optional)"
              multiline
              rows={4}
              fullWidth
              value={formData.description}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateTimePicker
              label="Start Time"
              required
              value={formData.startTime}
              onChange={handleTimeChange("startTime")}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateTimePicker
              label="End Time"
              required
              value={formData.endTime}
              onChange={handleTimeChange("endTime")}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              name="termId"
              label="School Term (Optional)"
              value={formData.termId}
              onChange={handleTermChange}
              helperText={
                termsError
                  ? "Unable to load terms"
                  : availableTerms.length === 0 &&
                    "No terms available for selected time range"
              }
              error={Boolean(termsError)}
              disabled={termsLoading}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {availableTerms.map((term) => (
                <MenuItem key={term.id} value={term.id}>
                  {term.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {formData.termId && (
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} alignItems="center">
                <FormControlLabel
                  control={
                    <Switch
                      name="rebalance"
                      checked={formData.rebalance}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          rebalance: e.target.checked,
                        }))
                      }
                      color="primary"
                    />
                  }
                  label="Auto-adjust conflicting events"
                />
                <Tooltip
                  title="When enabled, any blocking or moveable events that conflict with this new event will be automatically rescheduled while maintaining scheduling constraints"
                  arrow
                >
                  <IconButton size="small">
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
          )}
        </Grid>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          sx={{ mt: 3 }}
        >
          <Button onClick={handleCancel}>Cancel</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={
              !formData.title || !formData.startTime || !formData.endTime
            }
          >
            Create
          </LoadingButton>
        </Stack>
      </form>
    </HsModal>
  );
};

export default CreateCalendarEventModal;
