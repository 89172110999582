import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const DAYS_OF_WEEK = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];

export const INITIAL_INSTRUCTION_HOURS = DAYS_OF_WEEK.reduce((acc, day) => {
  acc[day] = {
    isSelected: [
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
    ].includes(day),
    value: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"].includes(
      day
    )
      ? [{ startTime: "09:00", endTime: "12:00" }]
      : [],
  };
  return acc;
}, {});

export const INITIAL_VALUES = {
  name: "",
  startDate: dayjs.utc().format(),
  endDate: dayjs.utc().add(4, "months").format(),
};
