import { useCallback } from "react";
import { useBaseWizardState } from "./useBaseWizardState";
import { debug } from "../utils/debug";

const initialQuickstartData = {
  addedStudents: [],
  addedTerms: [],
  addedLessonPlans: [],
};

export const useQuickstartWizardState = (steps) => {
  const baseState = useBaseWizardState(steps, initialQuickstartData);

  // Override validateStep to always return true for quickstart wizard
  const validateStep = useCallback(() => {
    debug.wizard.state("Quickstart wizard validateStep: always valid");
    return true;
  }, []);

  // Return modified state with overridden validation
  return {
    ...baseState,
    validateStep,
    // Always return true for step validation
    stepValidation: { [baseState.activeStep]: true },
  };
};
