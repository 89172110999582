import React from "react";
import {
  Modal,
  Box,
  Typography,
  Fade,
  Backdrop,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

const Header = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderTopLeftRadius: "16px",
  borderTopRightRadius: "16px",
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Body = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  paddingTop: theme.spacing(2),
}));

const HsModal = ({
  open,
  handleClose,
  title,
  children,
  dismissable = true,
}) => {
  const handleModalClose = (event, reason) => {
    if (
      dismissable &&
      (reason === "backdropClick" || reason === "escapeKeyDown")
    ) {
      if (handleClose) handleClose(event, reason);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      closeAfterTransition
      disableEscapeKeyDown={!dismissable}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box
          onClick={(e) => e.stopPropagation()}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 150,
            minHeight: 200,
            maxWidth: "90vw",
            maxHeight: "90vh",
            width: "auto",
            height: "auto",
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            p: 0,
            outline: 0,
            borderRadius: "16px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Header>
            <Typography
              variant="h6"
              sx={{ color: "white", fontWeight: "bold" }}
            >
              {title}
            </Typography>
            {dismissable && (
              <IconButton onClick={handleClose} sx={{ color: "white" }}>
                <CloseIcon />
              </IconButton>
            )}
          </Header>
          <Body sx={{ flex: 1, overflow: "auto" }}>{children}</Body>
        </Box>
      </Fade>
    </Modal>
  );
};

export default HsModal;
