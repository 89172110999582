export const PUBLIC_ROUTES = [
  "/landing",
  "/login",
  "/invite",
  "/register",
  "/pricing",
  "/privacy-policy",
  "/terms-of-service",
  "/payment-success",
  "/payment-cancel",
  "/subscription-success",
  "/subscription-error",
  "/email-verification",
];

// Helper function to check if a path is public
export const isPublicRoute = (pathname) => {
  return PUBLIC_ROUTES.some((route) => pathname.startsWith(route));
};
