import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CreateLessonPlanModal from "../../../pages/lesson-plans/CreateLessonPlanModal";
import { useQuickstartWizardContext } from "../../../contexts/quickstart-wizard-context";
import { useSelector } from "react-redux";
import { debug } from "../../../utils/debug";
import { useAsyncOperation } from "../../../hooks/useAsyncOperation";
import { getLessonPlans, createLessonPlan } from "../../../api/lessonPlanApi";

const QuickstartLessonPlans = () => {
  const [showModal, setShowModal] = useState(false);
  const { wizardData, updateWizardData } = useQuickstartWizardContext();
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const [isLoading, setIsLoading] = useState(true);
  const { execute: executeFetch } = useAsyncOperation(getLessonPlans);
  const addedLessonPlans = wizardData.addedLessonPlans || [];

  const fetchLessonPlans = useCallback(async () => {
    try {
      const response = await executeFetch(selectedAccountId, 0, 100);
      debug.wizard.state("Lesson plans loaded:", response);

      const existingPlans = response.content.map((plan) => ({
        id: plan.id,
        name: plan.name,
        subject: plan.subject,
        description: plan.description,
      }));

      updateWizardData({
        addedLessonPlans: existingPlans,
      });
    } catch (error) {
      debug.wizard.state("Error loading lesson plans:", error);
    } finally {
      setIsLoading(false);
    }
  }, [executeFetch, selectedAccountId, updateWizardData]);

  // Load existing lesson plans on mount
  useEffect(() => {
    fetchLessonPlans();
  }, [fetchLessonPlans]);

  const handleCreate = async (data) => {
    try {
      debug.wizard.state("Creating lesson plan with data:", {
        ...data,
        accountId: selectedAccountId,
      });

      const createdPlan = await createLessonPlan({
        ...data,
        accountId: selectedAccountId,
      });

      updateWizardData({
        addedLessonPlans: [
          ...addedLessonPlans,
          {
            id: createdPlan.id,
            name: createdPlan.name,
            subject: createdPlan.subject,
            description: createdPlan.description,
          },
        ],
      });

      setShowModal(false);
    } catch (error) {
      debug.wizard.state("Error creating lesson plan:", error);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Add Lesson Plans
      </Typography>
      <Typography variant="body1" color="text.secondary" paragraph>
        Set up your lesson plans to organize your curriculum.
      </Typography>

      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      ) : addedLessonPlans.length > 0 ? (
        <List sx={{ mb: 3 }}>
          {addedLessonPlans.map((plan) => (
            <ListItem
              key={plan.id}
              sx={{
                bgcolor: "background.paper",
                borderRadius: 1,
                mb: 1,
                boxShadow: 1,
              }}
            >
              <ListItemText
                primary={plan.name}
                secondary={plan.subject || plan.description}
              />
              <CheckCircleIcon color="success" />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography color="text.secondary" sx={{ mb: 3 }}>
          No lesson plans added yet. Click below to add your first plan.
        </Typography>
      )}

      <Button
        startIcon={<MenuBookIcon />}
        variant="contained"
        onClick={() => setShowModal(true)}
      >
        Add Lesson Plan
      </Button>

      <CreateLessonPlanModal
        open={showModal}
        handleCreate={handleCreate}
        handleClose={handleModalClose}
      />
    </Box>
  );
};

export default QuickstartLessonPlans;
