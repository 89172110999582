import React from "react";
import { Grid, Button, TextField, MenuItem, Stack, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik, Form, Field } from "formik";
import { useReportContext } from "../../contexts/report-context";
import { enrollUserValidationSchema } from "../../utils/validation";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { applyLessonPlanToTerm } from "../../api/lessonPlanApi";
import HsModal from "../../components/common/HsModal";

const ApplyToTermModal = ({
  open,
  handleApply,
  handleClose,
  lessonPlanId,
  lessonPlanDetails,
  terms,
  students,
}) => {
  const { setMessage } = useReportContext();
  const { execute: executeApplyLessonPlan, loading } = useAsyncOperation(
    applyLessonPlanToTerm
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { termId, studentId } = values;
      await executeApplyLessonPlan(lessonPlanId, {
        termId,
        studentId,
      });

      handleApply();
      setMessage({
        text: "Applied the lesson plan successfully!",
        severity: "success",
        flag: true,
      });
      handleClose();
    } catch (err) {
      setMessage({
        text: err.response?.data?.message || "Failed to apply the lesson plan",
        severity: "error",
        flag: true,
      });
    }
  };

  return (
    <HsModal
      open={open}
      handleClose={handleClose}
      title="Enroll Student in Class"
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          termId: "",
          studentId: "",
        }}
        validationSchema={enrollUserValidationSchema}
      >
        {({ isSubmitting, touched, errors }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field name="studentId">
                  {({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Student"
                      size="small"
                      fullWidth
                      error={errors.studentId && touched.studentId}
                      helperText={
                        errors.studentId && touched.studentId
                          ? errors.studentId
                          : ""
                      }
                      required
                    >
                      {students.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.firstName} {option.lastName}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="termId">
                  {({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="School Term"
                      size="small"
                      fullWidth
                      error={errors.termId && touched.termId}
                      helperText={
                        errors.termId && touched.termId ? errors.termId : ""
                      }
                      required
                    >
                      {terms.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <Alert severity="info">
                    Class Title: {lessonPlanDetails.name}
                    <br />
                    Duration: {lessonPlanDetails.durationPerLesson} minutes :{" "}
                    {lessonPlanDetails.lessonFrequency}
                  </Alert>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={2}
                  sx={{ mt: 2 }}
                >
                  <Button variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    loading={loading}
                  >
                    Schedule
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </HsModal>
  );
};

export default ApplyToTermModal;
