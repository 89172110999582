import React from "react";
import { TextField, Typography, Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const Step1 = ({ values, setValues, errors, setErrors }) => {
  const handleChange = (field) => (event) => {
    setValues((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
    // Clear error when field is updated
    if (errors[field]) {
      setErrors((prev) => ({
        ...prev,
        [field]: null,
      }));
    }
  };

  const handleDateChange = (field) => (newValue) => {
    if (newValue && dayjs.isDayjs(newValue)) {
      // Format date as YYYY-MM-DD
      const formattedDate = newValue.format("YYYY-MM-DD");
      setValues((prev) => ({
        ...prev,
        [field]: formattedDate,
      }));
      if (errors[field]) {
        setErrors((prev) => ({
          ...prev,
          [field]: null,
        }));
      }
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
        Term Details
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <TextField
          fullWidth
          label="Term Name"
          value={values.name}
          onChange={handleChange("name")}
          error={!!errors.name}
          helperText={errors.name}
          required
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: 2,
            },
          }}
        />

        <DatePicker
          label="Start Date"
          value={values.startDate ? dayjs(values.startDate) : null}
          onChange={handleDateChange("startDate")}
          slotProps={{
            textField: {
              fullWidth: true,
              required: true,
              error: !!errors.startDate,
              helperText: errors.startDate,
              sx: {
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                },
              },
            },
          }}
        />

        <DatePicker
          label="End Date"
          value={values.endDate ? dayjs(values.endDate) : null}
          minDate={values.startDate ? dayjs(values.startDate) : null}
          onChange={handleDateChange("endDate")}
          slotProps={{
            textField: {
              fullWidth: true,
              required: true,
              error: !!errors.endDate,
              helperText: errors.endDate,
              sx: {
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                },
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default Step1;
