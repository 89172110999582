import { createSlice } from "@reduxjs/toolkit";

const lessonPlanSlice = createSlice({
  name: "lessonPlan",
  initialState: {
    lessonPlans: [],
  },
  reducers: {
    setLessonPlans: (state, action) => {
      state.lessonPlans = action.payload;
    },
    removeLessonPlan: (state, action) => {
      state.lessonPlans = state.lessonPlans.filter(
        (lessonPlan) => lessonPlan.id !== action.payload
      );
    },
    addLessonPlan: (state, action) => {
      state.lessonPlans.push(action.payload);
    },
    updateLessonPlan: (state, action) => {
      const index = state.lessonPlans.findIndex(
        (lessonPlan) => lessonPlan.id === action.payload.id
      );
      if (index !== -1) {
        state.lessonPlans[index] = action.payload;
      }
    },
  },
});

export const {
  setLessonPlans,
  removeLessonPlan,
  addLessonPlan,
  updateLessonPlan,
} = lessonPlanSlice.actions;

export default lessonPlanSlice.reducer;
