import { api } from "./api.js";
import apiUrl from "../config/config.js";

export const getStudentsByTermId = async (termId, accountId) => {
  const response = await api.get(
    `${apiUrl}/api/v1/student/term/${termId}/students`,
    {
      params: {
        account_id: accountId,
      },
    }
  );
  return response.data;
};
