import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useReportContext } from "../contexts/report-context";

const AppSnackBar = () => {
  const { message, setMessage } = useReportContext();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMessage({ text: "", severity: "success", flag: false });
  };
  return (
    <Snackbar
      open={message.flag}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={message.severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message.text}
      </Alert>
    </Snackbar>
  );
};

export default AppSnackBar;
