import React from "react";
import {
  Typography,
  Box,
  Avatar,
  IconButton,
  Grid,
  useTheme,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const TestimonialCard = ({
  text,
  author,
  avatarSrc,
  backgroundColorKey,
  smallImageSrc,
  textColor,
}) => {
  const theme = useTheme();

  // Split the backgroundColorKey into its parts
  const [colorType, colorShade] = backgroundColorKey.split(".");

  // Access the correct color from the theme
  const backgroundColor =
    theme.palette[colorType]?.[colorShade] || theme.palette.primary.main;

  // Determine text color
  const textColorValue = textColor === "black" ? "black" : "white";

  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        p: 3,
        borderRadius: 2,
        height: "100%",
        position: "relative",
      }}
    >
      <Typography variant="body1" sx={{ mb: 2, color: textColorValue }}>
        "{text}"
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar src={avatarSrc} sx={{ mr: 2 }} />
          <Typography variant="subtitle1" sx={{ color: textColorValue }}>
            - {author}
          </Typography>
        </Box>
        {smallImageSrc && (
          <img
            src={smallImageSrc}
            alt="Small icon"
            style={{ width: 24, height: 24 }}
          />
        )}
      </Box>
      <Typography
        variant="h1"
        sx={{
          position: "absolute",
          bottom: 0,
          right: 10,
          color: "rgba(255,255,255,0.3)",
          fontSize: "72px",
        }}
      ></Typography>
    </Box>
  );
};

const Testimonials = () => (
  <Box sx={{ my: 4 }}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 2,
      }}
    >
      <Typography variant="h2">Testimonials</Typography>
      <Box>
        <IconButton>
          <ArrowBackIosNewIcon />
        </IconButton>
        <IconButton>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Box>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TestimonialCard
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          author="John Doe"
          avatarSrc="/path-to-john-doe-avatar.jpg"
          backgroundColorKey="primary"
          smallImageSrc="/assets/images/quote_left.png"
          textColor="white"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TestimonialCard
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          author="Jane Doe"
          avatarSrc="/path-to-jane-doe-avatar.jpg"
          backgroundColorKey="primary.lighter"
          smallImageSrc="/assets/images/quote_right.png"
          textColor="black"
        />
      </Grid>
    </Grid>
  </Box>
);

export default Testimonials;
