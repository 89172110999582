import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SchoolTermModal from "../../../pages/school-terms/modal/SchoolTermModal";
import { useQuickstartWizardContext } from "../../../contexts/quickstart-wizard-context";
import { debug } from "../../../utils/debug";
import { useAsyncOperation } from "../../../hooks/useAsyncOperation";
import { fetchActiveAndFutureTerms } from "../../../api/termApi";
import dayjs from "dayjs";

const formatDate = (dateString) => {
  return dayjs(dateString).format("MMMM D, YYYY");
};

const QuickstartTerms = () => {
  const [showTermModal, setShowTermModal] = useState(false);
  const { wizardData, updateWizardData } = useQuickstartWizardContext();
  const [isLoading, setIsLoading] = useState(true);
  const { execute: fetchTerms, loading } = useAsyncOperation(
    fetchActiveAndFutureTerms
  );
  const addedTerms = wizardData.addedTerms || [];

  // Load existing terms on mount
  useEffect(() => {
    const loadTerms = async () => {
      try {
        const response = await fetchTerms();
        const existingTerms = response.data.map((term) => ({
          id: term.id,
          name: term.name,
          startDate: term.startDate,
          endDate: term.endDate,
        }));

        updateWizardData({
          addedTerms: existingTerms,
        });
      } catch (error) {
        debug.wizard.state("Error loading terms:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadTerms();
  }, [fetchTerms, updateWizardData]);

  const handleTermAdded = (newTerm) => {
    if (!newTerm?.id) {
      debug.wizard.state("Invalid term data received:", newTerm);
      return;
    }

    debug.wizard.state("Adding new term:", newTerm);

    updateWizardData({
      addedTerms: [
        ...addedTerms,
        {
          id: newTerm.id,
          name: newTerm.name,
          startDate: newTerm.startDate,
          endDate: newTerm.endDate,
        },
      ],
    });
    setShowTermModal(false);
  };

  const handleModalClose = () => {
    setShowTermModal(false);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Add School Terms
      </Typography>
      <Typography variant="body1" color="text.secondary" paragraph>
        Set up your school terms to organize your academic year.
      </Typography>

      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      ) : addedTerms.length > 0 ? (
        <List sx={{ mb: 3 }}>
          {addedTerms.map((term) => (
            <ListItem
              key={term.id}
              sx={{
                bgcolor: "background.paper",
                borderRadius: 1,
                mb: 1,
                boxShadow: 1,
              }}
            >
              <ListItemText
                primary={term.name}
                secondary={`${formatDate(term.startDate)} - ${formatDate(
                  term.endDate
                )}`}
              />
              <CheckCircleIcon color="success" />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography color="text.secondary" sx={{ mb: 3 }}>
          No school terms added yet. Click below to add your first term.
        </Typography>
      )}

      <Button
        startIcon={<CalendarTodayIcon />}
        variant="contained"
        onClick={() => setShowTermModal(true)}
      >
        Add School Term
      </Button>

      <SchoolTermModal
        open={showTermModal}
        onCancel={handleModalClose}
        onSave={handleTermAdded}
      />
    </Box>
  );
};

export default QuickstartTerms;
