import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CONFIG } from "../../config/config";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  titleSection: {
    maxWidth: 600,
    margin: "0 auto",
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
}));

const PricingPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.pageContainer}>
      <div className={classes.titleSection}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Pricing
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="textSecondary"
          component="p"
        >
          Quickly build an effective pricing table for your potential customers
          with this layout. It&apos;s built with default Material-UI components
          with little customization.
        </Typography>
      </div>
      <stripe-pricing-table
        pricing-table-id={CONFIG.REACT_APP_STRIPE_PRICING_TABLE_ID}
        publishable-key={CONFIG.STRIPE_PUBLISHABLE_KEY}
      ></stripe-pricing-table>
    </div>
  );
};

export default PricingPage;
