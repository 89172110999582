export const PRODUCT_IDS = {
  PLANNER: "66f79a3ce6615479291a432c",
  // Add other products here as needed
};

// If you prefer an enum-like structure, you could also do:
// export const PRODUCT_IDS = Object.freeze({
//   PLANNER: "66f79a3ce6615479291a432c",
//   // Add other products here as needed
// });
