import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InviteUserModal from "../../modals/InviteUserModal";
import { useQuickstartWizardContext } from "../../../contexts/quickstart-wizard-context";
import { ROLE_STUDENT, getUsers } from "../../../api/userApi";
import { debug } from "../../../utils/debug";
import { useSelector } from "react-redux";
import { useAsyncOperation } from "../../../hooks/useAsyncOperation";

const QuickstartStudents = () => {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const { wizardData, updateWizardData } = useQuickstartWizardContext();
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const { execute: fetchStudents, loading } = useAsyncOperation(getUsers);

  const loadStudents = async () => {
    try {
      const students = await fetchStudents(selectedAccountId, ROLE_STUDENT);

      debug.wizard.state("Fetched students:", students);
      const mappedStudents = students.map((student) => ({
        id: student.id,
        name: `${student.firstName} ${student.lastName}`,
        email: student.email,
        gradeLevel: student.gradeLevel,
      }));

      updateWizardData({
        addedStudents: mappedStudents,
      });
    } catch (error) {
      debug.wizard.state("Error fetching students:", error);
    }
  };

  // Initial fetch
  useEffect(() => {
    if (selectedAccountId) {
      loadStudents();
    }
  }, [selectedAccountId]);

  const handleStudentAdded = (newStudent) => {
    debug.wizard.state("Student added:", newStudent);
    const addedStudents = wizardData.addedStudents || [];

    // Ensure we have all required fields from the API response
    if (!newStudent?.id) {
      debug.wizard.state("Invalid student data received:", newStudent);
      return;
    }

    const updatedStudents = [
      ...addedStudents,
      {
        id: newStudent.id,
        name: `${newStudent.firstName} ${newStudent.lastName}`,
        email: newStudent.email,
        gradeLevel: newStudent.gradeLevel,
      },
    ];

    debug.wizard.state("Updating students list:", updatedStudents);
    updateWizardData({
      addedStudents: updatedStudents,
    });
  };

  const handleModalClose = () => {
    setShowInviteModal(false);
  };

  const handleSaveSuccess = async () => {
    handleModalClose();
    // Refetch students after successful creation
    await loadStudents();
  };

  const addedStudents = wizardData.addedStudents || [];

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Add Students
      </Typography>
      <Typography variant="body1" color="text.secondary" paragraph>
        Start by adding students to your account. You can add more students
        later.
      </Typography>

      {loading ? (
        <Box display="flex" justifyContent="center" my={3}>
          <CircularProgress />
        </Box>
      ) : (
        addedStudents.length > 0 && (
          <List sx={{ mb: 3 }}>
            {addedStudents.map((student) => (
              <ListItem
                key={`${student.id}-${student.email}`}
                sx={{
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  mb: 1,
                  boxShadow: 1,
                }}
              >
                <ListItemText
                  primary={student.name}
                  secondary={`Grade ${student.gradeLevel}`}
                />
                <CheckCircleIcon color="success" />
              </ListItem>
            ))}
          </List>
        )
      )}

      <Button
        startIcon={<PersonAddIcon />}
        variant="contained"
        onClick={() => setShowInviteModal(true)}
      >
        Add Student
      </Button>

      <InviteUserModal
        open={showInviteModal}
        onCancel={handleModalClose}
        onSave={handleSaveSuccess}
        userRole={ROLE_STUDENT}
        title="Add Student"
        showGradeLevel={true}
      />
    </Box>
  );
};

export default QuickstartStudents;
