import { api } from "./api";
import apiUrl from "../config/config.js";

export const getLessonPlanLessons = async (lessonPlanId, signal) => {
  const response = await api.get(
    `${apiUrl}/api/v1/class-lesson/lesson-plan/${lessonPlanId}`,
    { signal }
  );
  return response.data;
};

export const getClassLessonById = async (id) => {
  const response = await api.get(`${apiUrl}/api/v1/class-lesson/${id}`);
  return response.data;
};

export const createClassLesson = async (data) => {
  const response = await api.post(`${apiUrl}/api/v1/class-lesson`, data);
  return response.data;
};

export const updateClassLesson = async (id, data) => {
  const response = await api.put(`${apiUrl}/api/v1/class-lesson/${id}`, data);
  return response.data;
};

export const deleteClassLesson = async (id) => {
  const response = await api.delete(`${apiUrl}/api/v1/class-lesson/${id}`);
  return response.data;
};
