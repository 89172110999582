import React, { useState } from "react";
import {
  Button,
  Box,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  useTheme,
} from "@mui/material";
import HsModal from "../common/HsModal";
import { useSelector } from "react-redux";
import { createCheckoutSession } from "../../api/paymentApi";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import PlanCard from "../PlanCard";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SubscriptionModal = ({ open, handleClose }) => {
  const { selectedAccountId } = useSelector((state) => state.auth);
  const [selectedPlanType, setSelectedPlanType] = useState("FAMILY");
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const plans = [
    {
      type: "BASIC",
      title: "BASIC",
      description: "One student",
      price: "3.75",
    },
    {
      type: "FAMILY",
      title: "FAMILY",
      description: "Up to 10 students",
      price: "7.50",
    },
  ];

  const handleSubmit = async () => {
    if (!selectedPlanType) {
      alert("Please select a plan type");
      return;
    }

    setIsLoading(true);
    try {
      const { sessionId } = await createCheckoutSession(
        selectedPlanType,
        selectedAccountId
      );
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error("Error redirecting to Stripe Checkout:", error);
        alert(
          "There was an error redirecting to the payment page. Please try again."
        );
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
      alert(
        "There was an error creating the checkout session. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSkip = () => {
    handleClose(null);
    navigate("/home");
  };

  return (
    <HsModal
      open={open}
      handleClose={handleSkip}
      title="Select Subscription Plan"
    >
      <Box display="flex" justifyContent="space-between" mb={1}>
        {plans.map((plan) => (
          <PlanCard
            key={plan.type}
            plan={plan}
            isSelected={selectedPlanType === plan.type}
            onSelect={setSelectedPlanType}
          />
        ))}
      </Box>
      <Typography variant="caption" color="text.secondary" textAlign="center">
        * All subscriptions are billed annually
      </Typography>
      <Box mt={2}></Box>
      <Button
        variant="darkRounded"
        onClick={handleSubmit}
        disabled={isLoading}
        startIcon={isLoading ? <CircularProgress size={20} /> : null}
        fullWidth
        sx={{
          color: "white",
          py: 1.5,
          fontSize: "1rem",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.primary.dark,
          },
        }}
      >
        {isLoading ? "Processing..." : "Start 30-Day Free Trial"}
      </Button>
    </HsModal>
  );
};

export default SubscriptionModal;
