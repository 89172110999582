import React from "react";
import { Typography, Box, Grid, TextField, Button } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

const ContactForm = () => (
  <Box sx={{ my: 4 }}>
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Typography variant="h2" gutterBottom>
          Contact Now
        </Typography>
        {/* <Typography variant="body1" paragraph>
          Feel free to contact us at any time.
        </Typography> */}
        {/* <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <PhoneIcon sx={{ mr: 1 }} />
          <Typography>000-111-555</Typography>
        </Box> */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <EmailIcon sx={{ mr: 1 }} />
          <Typography>support@homeschoolplanner.com</Typography>
        </Box>
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <Typography variant="h3" gutterBottom>
          Send Us A Message
        </Typography>
        <form>
          <TextField fullWidth label="Name" margin="normal" />
          <TextField fullWidth label="Email" margin="normal" />
          <TextField
            fullWidth
            label="Message"
            multiline
            rows={4}
            margin="normal"
          />
          <Button variant="contained" color="primary" sx={{ mt: 2 }}>
            Submit
          </Button>
        </form>
      </Grid> */}
    </Grid>
  </Box>
);

export default ContactForm;
