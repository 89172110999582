import { api } from "./api.js";
import apiUrl from "../config/config.js";

export const ROLE_TEACHER = "ROLE_TEACHER";
export const ROLE_STUDENT = "ROLE_STUDENT";
export const ROLE_PARENT = "ROLE_PARENT";

export const createNewUser = async (user, accountId, roleName, signal) => {
  const params = new URLSearchParams();
  params.append("account_id", accountId);
  params.append("role_name", roleName);

  const response = await api.post(
    `${apiUrl}/api/v1/user?${params.toString()}`,
    user,
    { signal }
  );

  return response.data;
};

export const getUsers = async (accountId, role, signal) => {
  const params = {
    account_id: accountId,
  };
  if (role) {
    params.role = role;
  }
  const response = await api.get(`${apiUrl}/api/v1/user`, {
    params,
    signal,
  });

  return response.data;
};

export const getUserById = async (id, accountId, signal) => {
  const params = { account_id: accountId };
  const response = await api.get(`${apiUrl}/api/v1/user/${id}`, {
    params,
    signal,
  });

  return response.data;
};

export const getStudentEnrollment = async (id, signal) => {
  const response = await api.get(`${apiUrl}/api/v1/student/${id}`, { signal });
  if (response.status === 204) {
    return []; // Keep special case for no content
  }
  return response.data;
};

export const getInviteUsers = async (
  accountId,
  role,
  page = 0,
  size = 10,
  sort = "ASC",
  signal
) => {
  const params = {
    accountId,
    page,
    size,
    sort,
    role_name: role,
  };
  const response = await api.get(`${apiUrl}/api/v1/user-invite`, {
    params,
    signal,
  });

  return response.data;
};

export const deleteInviteUser = async (id, signal) => {
  const response = await api.delete(`${apiUrl}/api/v1/user-invite/${id}`, {
    signal,
  });

  return response.data;
};

export const deleteUser = async (accountId, userId, signal) => {
  const params = { account_id: accountId };
  const response = await api.delete(`${apiUrl}/api/v1/user/${userId}`, {
    params,
    signal,
  });

  return response.data;
};

export const updateUserTimezone = async (userId, timezone, signal) => {
  const encodedTimezone = encodeURIComponent(timezone);

  const response = await api.put(
    `${apiUrl}/api/v1/user/timezone/${userId}?timezone=${encodedTimezone}`,
    null,
    { signal }
  );

  return response.data;
};

export const updateUserProfile = async (
  userId,
  userData,
  accountId,
  signal
) => {
  const response = await api.put(
    `${apiUrl}/api/v1/user/${userId}?account_id=${accountId}`,
    userData,
    { signal }
  );

  return response.data;
};

export const uploadProfileImage = async (
  userId,
  formData,
  accountId,
  signal
) => {
  const response = await api.post(
    `${apiUrl}/api/v1/user/${userId}/profile-image?account_id=${accountId}`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
      signal,
    }
  );

  return response.data;
};

export const updateProfileImage = async (
  userId,
  formData,
  accountId,
  signal
) => {
  const response = await api.put(
    `${apiUrl}/api/v1/user/${userId}/profile-image?account_id=${accountId}`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
      signal,
    }
  );

  return response.data;
};

export const deleteProfileImage = async (userId, accountId, signal) => {
  const response = await api.delete(
    `${apiUrl}/api/v1/user/${userId}/profile-image?account_id=${accountId}`,
    { signal }
  );

  return response.data;
};

const userApi = {
  createNewUser,
  getUsers,
  getUserById,
  getStudentEnrollment,
  getInviteUsers,
  deleteInviteUser,
  deleteUser,
  updateUserTimezone,
  updateUserProfile,
  uploadProfileImage,
  updateProfileImage,
  deleteProfileImage,
};

export default userApi;
