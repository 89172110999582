import api from "./api";

export const initiateCheckout = async (request) => {
  const response = await api.post("/api/v1/checkout/initiate", request);
  return response.data;
};

export const completeCheckout = async (sessionId) => {
  const response = await api.post(
    `/api/v1/checkout/complete?sessionId=${sessionId}`
  );
  return response.data;
};
