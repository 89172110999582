import { createSlice } from "@reduxjs/toolkit";

const networkSlice = createSlice({
  name: "network",
  initialState: {
    isError: false,
    message: null,
  },
  reducers: {
    setNetworkError: (state, action) => {
      state.isError = action.payload.isError;
      state.message = action.payload.message;
    },
  },
});

export const { setNetworkError } = networkSlice.actions;
export default networkSlice.reducer;
