import React from "react";
import {
  Grid,
  Button,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik, Form, Field } from "formik";
import { createClassValidationSchema } from "../../utils/validation";
import {
  SUBJECTS,
  LESSON_FREQUENCIES,
  NUMBER_OF_LESSONS_TYPES,
} from "./classConstants";
import {
  CustomTextField,
  CustomAutocomplete,
  CustomNumericFormat,
} from "./CustomFormFields";
import HsModal from "../../components/common/HsModal";
import { styled } from "@mui/material/styles";

const getInitialValues = (data) => ({
  name: data?.name || "",
  subject: data?.subject || "",
  description: data?.description || "",
  durationPerLesson: data?.durationPerLesson || 60,
  numberOfLessons: data?.numberOfLessons || 0,
  lessonFrequency: data?.lessonFrequency || "DAILY",
  numberOfLessonsType: data?.isOngoing ? "Ongoing" : "Fixed Number",
  ...(data ? {} : { numberOfLessonsType: "Ongoing" }),
});

const transformFormValues = (values) => ({
  name: values.name,
  subject: values.subject || null,
  description: values.description || null,
  durationPerLesson: values.durationPerLesson,
  numberOfLessons:
    values.numberOfLessonsType === "Fixed Number"
      ? values.numberOfLessons
      : null,
  lessonFrequency: values.lessonFrequency,
  isOngoing: values.numberOfLessonsType === "Ongoing",
});

// Styled components for consistent form styling
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(2),
  "& .MuiFormLabel-root": {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  "& .MuiFormControlLabel-root": {
    marginBottom: theme.spacing(1),
  },
}));

const CreateLessonPlanModal = ({
  open,
  handleCreate,
  handleUpdate,
  handleClose,
  data,
}) => {
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const classData = transformFormValues(values);
      console.log("Submitting data:", classData);
      if (data && data.id) {
        console.log("Updating class with ID:", data.id);
        await handleUpdate(data.id, classData);
      } else {
        console.log("Creating new class");
        await handleCreate(classData);
      }
    } catch (err) {
      console.error("Failed to submit lesson plan", err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <HsModal
      open={open}
      handleClose={handleClose}
      title={`${data ? "Edit" : "Create"} Lesson Plan`}
      maxWidth="md"
    >
      <Formik
        initialValues={getInitialValues(data)}
        validationSchema={createClassValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  name="name"
                  component={CustomTextField}
                  label="Title"
                  size="small"
                  fullWidth
                  placeholder="Enter lesson plan title"
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="description"
                  component={CustomTextField}
                  label="Description"
                  size="small"
                  fullWidth
                  multiline
                  rows={4}
                  placeholder="Enter lesson plan description"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Field
                  name="subject"
                  component={CustomAutocomplete}
                  options={SUBJECTS}
                  label="Subject"
                  freeSolo
                  size="small"
                  placeholder="Select or enter a subject"
                  helperText="Choose from list or enter your own"
                  required={false}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Field
                  name="durationPerLesson"
                  component={CustomNumericFormat}
                  label="Duration (minutes)"
                  size="small"
                  fullWidth
                  placeholder="60"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <StyledFormControl>
                  <FormLabel component="legend">Frequency</FormLabel>
                  <StyledRadioGroup
                    name="lessonFrequency"
                    value={values.lessonFrequency}
                    onChange={(e) =>
                      setFieldValue("lessonFrequency", e.target.value)
                    }
                  >
                    {LESSON_FREQUENCIES.map((frequency) => (
                      <FormControlLabel
                        key={frequency.value}
                        value={frequency.value}
                        control={<Radio color="primary" />}
                        label={frequency.label}
                      />
                    ))}
                  </StyledRadioGroup>
                </StyledFormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <StyledFormControl>
                  <FormLabel component="legend">Number of Lessons</FormLabel>
                  <StyledRadioGroup
                    name="numberOfLessonsType"
                    value={values.numberOfLessonsType}
                    onChange={(e) =>
                      setFieldValue("numberOfLessonsType", e.target.value)
                    }
                  >
                    {NUMBER_OF_LESSONS_TYPES.map((type) => (
                      <FormControlLabel
                        key={type.value}
                        value={type.value}
                        control={<Radio color="primary" />}
                        label={type.label}
                      />
                    ))}
                  </StyledRadioGroup>
                  {values.numberOfLessonsType === "Fixed Number" && (
                    <Field
                      name="numberOfLessons"
                      component={CustomNumericFormat}
                      label="Number of Lessons"
                      size="small"
                      fullWidth
                      placeholder="Enter number of lessons"
                    />
                  )}
                </StyledFormControl>
              </Grid>
            </Grid>

            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 4 }}
            >
              <Button
                variant="lightRoundedOutline"
                onClick={handleClose}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="darkRounded"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {data ? "Save Changes" : "Create Lesson Plan"}
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </HsModal>
  );
};

export default CreateLessonPlanModal;
