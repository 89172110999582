import { useMediaQuery, useTheme } from "@mui/material";
import { useDrawerContext } from "../contexts/drawer-context";

export const useDrawer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const drawerWidth = theme.components.MuiDrawer.styleOverrides.paper.width;

  const context = useDrawerContext();

  // If the context is not available, provide default values
  const drawerContext = context || {
    isOpen: false,
    toggleDrawer: () => {},
    setIsOpen: () => {},
  };

  return {
    ...drawerContext,
    isMobile,
    drawerWidth,
  };
};
