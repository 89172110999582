import { createSlice } from "@reduxjs/toolkit";
import { LOCAL_STORAGE_KEY } from "../config/constants.js";

const initialState = {
  loggedIn: false,
  token: null,
  loggedInUser: null,
  loggedInUserRoles: null,
  loggedInUserAccounts: null,
  selectedAccountId: null,
  googleCalendarConnected: false,
  reconnectGoogleCalendar: false,
  defaultSyncCalendarId: null,
  showQuickStart: false,
  planType: null,
  hasTimeoutMessage: false,
  calendarConnections: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthInfo: (state, action) => {
      // Add logging to see what's coming into the reducer
      console.log("Auth Payload:", action.payload);

      // Ensure we're not storing any functions or non-serializable values
      const serializedPayload = JSON.parse(JSON.stringify(action.payload));
      const newState = {
        ...state,
        ...serializedPayload,
      };

      // Set loggedIn to true if token and loggedInUser are both truthy
      newState.loggedIn = !!newState.token && !!newState.loggedInUser;

      // Set selectedAccountId and planType if there's only one account
      if (
        newState.loggedInUserAccounts?.length === 1 &&
        !newState.selectedAccountId
      ) {
        const account = newState.loggedInUserAccounts[0];
        newState.selectedAccountId = account.id;
        newState.planType = account.planType || "NONE";
      }
      // If selectedAccountId exists, ensure planType matches the selected account
      else if (newState.selectedAccountId && newState.loggedInUserAccounts) {
        const selectedAccount = newState.loggedInUserAccounts.find(
          (acc) => acc.id === newState.selectedAccountId
        );
        if (selectedAccount) {
          newState.planType = selectedAccount.planType || "NONE";
        }
      }

      // Process calendar connections from auth response
      if (serializedPayload.calendarConnections) {
        console.log(
          "Calendar Connections in Payload:",
          serializedPayload.calendarConnections
        );
        const googleStatus = serializedPayload.calendarConnections.GOOGLE;
        if (googleStatus) {
          console.log("Google Status:", googleStatus);
          newState.googleCalendarConnected = googleStatus.connected || false;
          newState.reconnectGoogleCalendar =
            googleStatus.needsReconnect || false;
          newState.defaultSyncCalendarId =
            googleStatus.defaultSyncCalendarId || null;

          // Ensure calendarConnections is properly set in state
          newState.calendarConnections = {
            ...serializedPayload.calendarConnections,
          };
        }
      }

      console.log("New State:", newState);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newState));
      return newState;
    },
    clearAuthInfo: () => {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      return initialState;
    },
    setSelectedAccountId: (state, action) => {
      state.selectedAccountId = action.payload;

      // Find the account with the selected id and set planType
      if (state.loggedInUserAccounts) {
        const selectedAccount = state.loggedInUserAccounts.find(
          (acc) => acc.id === action.payload
        );
        if (selectedAccount) {
          state.planType = selectedAccount.planType || "NONE";
        }
      }

      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
    },
    initializeAuth: (state) => {
      const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (savedState) {
        const parsedState = JSON.parse(savedState);
        Object.assign(state, parsedState);
        state.loggedIn = !!state.token && !!state.loggedInUser;

        // Ensure planType is set correctly based on selectedAccountId
        if (state.selectedAccountId && state.loggedInUserAccounts) {
          const selectedAccount = state.loggedInUserAccounts.find(
            (acc) => acc.id === state.selectedAccountId
          );
          if (selectedAccount) {
            state.planType = selectedAccount.planType || "NONE";
          }
        }
        // Set selectedAccountId and planType if there's only one account
        else if (state.loggedInUserAccounts?.length === 1) {
          const account = state.loggedInUserAccounts[0];
          state.selectedAccountId = account.id;
          state.planType = account.planType || "NONE";
        }
      }
    },
    setTimeoutMessage: (state) => {
      state.hasTimeoutMessage = true;
    },
    clearTimeoutMessage: (state) => {
      state.hasTimeoutMessage = false;
    },
    updateCalendarSyncSettings: (state, action) => {
      const { defaultSyncCalendarId } = action.payload;
      state.defaultSyncCalendarId = defaultSyncCalendarId;

      // If we have calendar connections, update there as well for consistency
      if (state.calendarConnections?.GOOGLE) {
        state.calendarConnections.GOOGLE.defaultSyncCalendarId =
          defaultSyncCalendarId;
      }

      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
    },
  },
});

export const {
  setAuthInfo,
  clearAuthInfo,
  setSelectedAccountId,
  initializeAuth,
  setTimeoutMessage,
  clearTimeoutMessage,
  updateCalendarSyncSettings,
} = authSlice.actions;

export default authSlice.reducer;
