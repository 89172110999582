import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { handleSubscriptionSuccess } from "../../api/paymentApi";
import { doPerformRefresh } from "../../api/api";
import { useDispatch } from "react-redux";
import { setAuthInfo, clearAuthInfo } from "../../redux/authSlice";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const SubscriptionSuccessHandler = () => {
  const [verificationStatus, setVerificationStatus] = useState("verifying");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const verifySubscription = async () => {
      const urlParams = new URLSearchParams(location.search);
      const sessionId = urlParams.get("session_id");

      console.log("Session ID: ", sessionId);

      if (!sessionId) {
        setVerificationStatus("error");
        return;
      }

      try {
        await handleSubscriptionSuccess(sessionId);
        const refreshedAuthInfo = await doPerformRefresh();

        console.log("Refreshed Auth Info: ", refreshedAuthInfo);

        if (refreshedAuthInfo && refreshedAuthInfo.token) {
          dispatch(setAuthInfo(refreshedAuthInfo));
          setVerificationStatus("success");
        } else {
          setVerificationStatus("error");
        }
      } catch (error) {
        console.error("Error verifying subscription:", error);
        await dispatch(clearAuthInfo());
        setVerificationStatus("error");
      }
    };

    verifySubscription();
  }, [location, dispatch]);

  const handleContinue = () => {
    navigate("/home");
  };

  const renderContent = () => {
    switch (verificationStatus) {
      case "verifying":
        return (
          <>
            <CircularProgress color="primary" size={60} />
            <Typography variant="h4" color="text.primary" gutterBottom mt={2}>
              Verifying Subscription
            </Typography>
            <Typography variant="bodyFV" color="text.secondary">
              Please wait while we confirm your subscription...
            </Typography>
          </>
        );
      case "error":
        return (
          <>
            <ErrorOutlineIcon sx={{ color: "error.main", fontSize: 80 }} />
            <Typography variant="h4" color="error.main" gutterBottom mt={2}>
              Subscription Verification Failed
            </Typography>
            <Typography variant="bodyFV" color="text.secondary" mb={3}>
              We're sorry, but there was an error verifying your subscription.
              Please contact our support team for assistance.
            </Typography>
            <Typography variant="bodyFV" color="text.secondary">
              Contact support at:{" "}
              <a
                href="mailto:support@homeschoolplanner.com"
                style={{ color: "inherit" }}
              >
                support@homeschoolplanner.com
              </a>
            </Typography>
          </>
        );
      case "success":
        return (
          <>
            <CheckCircleOutlineIcon color="success" style={{ fontSize: 80 }} />
            <Typography variant="h4" color="success.main" gutterBottom mt={2}>
              Subscription Successful!
            </Typography>
            <Typography variant="bodyFV" color="black" mb={3}>
              Thank you for subscribing. Your account has been successfully
              updated.
            </Typography>
            <Button variant="darkRounded" onClick={handleContinue}>
              Continue to Dashboard
            </Button>
          </>
        );
      default:
        return;
    }
  };

  return (
    <Box sx={{ p: 4, maxWidth: "800px" }}>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        {renderContent()}
      </Box>
    </Box>
  );
};

export default SubscriptionSuccessHandler;
