import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  IconButton,
  Checkbox,
  MenuItem,
  Select,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

// Keep only necessary styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.3)", // Updated shadow
  overflow: "hidden",
}));

const StyledTableContainer = styled(TableContainer)({
  borderRadius: 0,
});

const PageControlSelect = styled(Select)(({ theme }) => ({
  height: "32px",
  minWidth: "64px",
  backgroundColor: theme.palette.primary.light,
  borderRadius: "16px",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSelect-select": {
    paddingLeft: theme.spacing(4),
  },
}));

const StyledTableBody = styled(TableBody)(({ theme, haspagination }) => ({
  "& tr:last-child td": {
    borderBottom: haspagination === "true" ? undefined : "none",
  },
}));

const HsTable = ({
  columns,
  data,
  totalCount,
  page,
  rowsPerPage,
  loading,
  error,
  onPageChange,
  onRowsPerPageChange,
  onRowClick,
  renderDesktopRow,
  renderMobileRow,
  showPagination = true,
  rowsPerPageOptions = [10, 25, 50],
  selectable = false,
  selectedRows = [],
  onSelectRow,
  onSelectAllRows,
  tableId,
  emptyMessage,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChangePage = (newPage) => {
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  const handleSelectAllClick = (event) => {
    if (onSelectAllRows) {
      onSelectAllRows(event.target.checked, tableId);
    }
  };

  const handleRowClick = (row) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const handleSelectRow = (event, row) => {
    event.stopPropagation();
    if (onSelectRow) {
      onSelectRow(row, tableId);
    }
  };

  const isSelected = (id) =>
    Array.isArray(selectedRows) &&
    selectedRows.some((item) => item.id === id && item.tableId === tableId);

  const renderTableContent = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} align="center">
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }

    if (error) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} align="center">
            {error}
          </TableCell>
        </TableRow>
      );
    }

    if (data.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} align="center">
            {emptyMessage || "No Data Available"}
          </TableCell>
        </TableRow>
      );
    }

    return data.map((row, index) => {
      const isItemSelected = isSelected(row.id);

      if (isMobile && renderMobileRow) {
        return (
          <TableRow
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id || index}
            selected={isItemSelected}
            onClick={() => handleRowClick(row)}
          >
            {selectable && (
              <TableCell padding="checkbox" className="checkbox-cell">
                <Checkbox
                  checked={isItemSelected}
                  onChange={(event) => handleSelectRow(event, row)}
                  onClick={(event) => event.stopPropagation()}
                />
              </TableCell>
            )}
            <TableCell>{renderMobileRow(row)}</TableCell>
          </TableRow>
        );
      }

      return (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id || index}
          selected={isItemSelected}
          onClick={() => handleRowClick(row)}
        >
          {selectable && (
            <TableCell padding="checkbox" className="checkbox-cell">
              <Checkbox
                checked={isItemSelected}
                onChange={(event) => handleSelectRow(event, row)}
                onClick={(event) => event.stopPropagation()}
              />
            </TableCell>
          )}
          {renderDesktopRow
            ? renderDesktopRow(row, columns)
            : columns.map((column) => (
                <TableCell key={column.id}>{row[column.id]}</TableCell>
              ))}
        </TableRow>
      );
    });
  };

  const renderPagination = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        p: 2,
      }}
    >
      <Typography variant="body2" sx={{ mr: 2 }}>
        Rows per page:
      </Typography>
      <PageControlSelect value={rowsPerPage} onChange={handleChangeRowsPerPage}>
        {rowsPerPageOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </PageControlSelect>
      <Typography variant="body2" sx={{ mx: 4 }}>
        {page * rowsPerPage + 1}-
        {Math.min((page + 1) * rowsPerPage, totalCount)} of {totalCount}
      </Typography>
      <IconButton
        onClick={() => handleChangePage(page - 1)}
        disabled={page === 0}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <IconButton
        onClick={() => handleChangePage(page + 1)}
        disabled={page >= Math.ceil(totalCount / rowsPerPage) - 1}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </Box>
  );

  return (
    <StyledPaper elevation={1}>
      <StyledTableContainer>
        <Table>
          {!isMobile && (
            <TableHead>
              <TableRow>
                {selectable && (
                  <TableCell padding="checkbox" className="checkbox-cell">
                    <Checkbox
                      indeterminate={
                        selectedRows.filter((item) => item.tableId === tableId)
                          .length > 0 &&
                        selectedRows.filter((item) => item.tableId === tableId)
                          .length < data.length
                      }
                      checked={
                        data.length > 0 &&
                        selectedRows.filter((item) => item.tableId === tableId)
                          .length === data.length
                      }
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                )}
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ width: column.width }}
                    padding="normal"
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <StyledTableBody haspagination={showPagination.toString()}>
            {renderTableContent()}
          </StyledTableBody>
        </Table>
      </StyledTableContainer>
      {showPagination && renderPagination()}
    </StyledPaper>
  );
};

HsTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      width: PropTypes.string,
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  renderDesktopRow: PropTypes.func,
  renderMobileRow: PropTypes.func,
  showPagination: PropTypes.bool,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  selectable: PropTypes.bool,
  selectedRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      tableId: PropTypes.string.isRequired,
    })
  ),
  onSelectRow: PropTypes.func,
  onSelectAllRows: PropTypes.func,
  tableId: PropTypes.string,
  emptyMessage: PropTypes.string,
};

export default HsTable;
