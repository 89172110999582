export const SUBJECTS = [
  "English",
  "Math",
  "Science",
  "History",
  "Language Arts",
  "Reading",
  "Writing",
  "Spelling",
  "Art",
  "Music",
  "Physics",
  "Physical Education",
  "Health",
  "Foreign Language",
  "Computer Science",
  "Home Economics",
  "Shop",
  "Social Studies",
  "Agriculture",
  "Business",
  "Accounting",
  "Life Skills",
  "Philosophy",
  "Psychology",
  "Sociology",
  "Anthropology",
  "Geography",
  "Political Science",
  "Economics",
  "Civics",
  "Government",
  "Law",
  "Education",
];

export const LESSON_FREQUENCIES = [
  { value: "DAILY", label: "Each instructional day" },
  { value: "ALTERNATING", label: "Every other instructional day" },
  { value: "MON_WED_FRI", label: "Monday—Wednesday—Friday" },
  { value: "TUE_THU", label: "Tuesday—Thursday" },
  { value: "WEEKLY", label: "Weekly" },
  { value: "BIWEEKLY", label: "Every other week" },
  { value: "MONTHLY", label: "Monthly" },
];

export const NUMBER_OF_LESSONS_TYPES = [
  { value: "Ongoing", label: "Ongoing" },
  { value: "Fixed Number", label: "Fixed Number" },
];
