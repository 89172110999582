import { Typography, Grid, Card, CardContent, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const FeatureCard = ({
  title,
  description,
  image,
  secondaryDescription,
  imagePercentage,
}) => {
  const theme = useTheme();
  const descriptionPercentage = 100 - imagePercentage;

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        bgcolor: theme.palette.secondary.light,
      }}
    >
      <CardContent sx={{ flexGrow: 1, p: 3 }}>
        <Box
          sx={{
            backgroundImage: 'url("/assets/images/small_postit.png")',
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            p: 2,
            mb: 2,
            display: "inline-block",
          }}
        >
          <Typography variant="h4" component="div">
            {title}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Box sx={{ flexGrow: 1, width: `${descriptionPercentage}%` }}>
            <Typography variant="h4" component="div" gutterBottom>
              {description}
            </Typography>
          </Box>
          <Box
            component="img"
            src={image}
            alt={title}
            sx={{ width: `${imagePercentage}%`, height: "auto", ml: 2 }}
          />
        </Box>
        <Typography variant="body1" color="text.secondary">
          {secondaryDescription}
        </Typography>
      </CardContent>
    </Card>
  );
};

const HowWeCanHelp = () => (
  <Box sx={{ my: 12 }}>
    <Typography variant="h2" gutterBottom align="center">
      How We Can Help
    </Typography>
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <FeatureCard
          title="MANAGE"
          description="View And Manage Your Daily Checklist"
          image="/assets/images/manager.png"
          secondaryDescription="Stay organized and on top of your day with our intuitive daily checklist feature. Easily view, manage, and check off tasks and lessons as you complete them, ensuring nothing is missed in your homeschooling routine."
          imagePercentage={50}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FeatureCard
          title="SCHEDULE"
          description="Let Our Autoscheduler Do The Heavy Lifting"
          image="/assets/images/scheduler.png"
          secondaryDescription="Save time and reduce stress with our powerful autoscheduler. Simply input your classes and activities, and our smart system will automatically arrange them within your defined instructional hours, creating an optimized schedule for your family."
          imagePercentage={35}
        />
      </Grid>
    </Grid>
  </Box>
);

export default HowWeCanHelp;
