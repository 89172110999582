import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  TextField,
  useTheme,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { removeItem, updateItem, setCart } from "../redux/cartSlice";
import { getCart, updateCartItem, removeFromCart } from "../api/cartApi";
import { useAsyncOperation } from "../hooks/useAsyncOperation";

const ShoppingCart = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { items, total } = useSelector((state) => state.cart);

  const { execute: fetchCart, loading, error } = useAsyncOperation(getCart);

  useEffect(() => {
    const loadCart = async () => {
      try {
        const cartData = await fetchCart();
        dispatch(setCart(cartData));
      } catch (error) {
        console.error("Failed to fetch cart data:", error);
      }
    };

    loadCart();
  }, [fetchCart, dispatch]);

  const handleQuantityChange = async (item, newQuantity) => {
    if (newQuantity < 1) return;
    try {
      const updatedItem = { ...item, quantity: newQuantity };
      await updateCartItem(item.id, updatedItem);
      dispatch(updateItem(updatedItem));
    } catch (error) {
      console.error("Failed to update item quantity:", error);
    }
  };

  const handleRemoveItem = async (itemId) => {
    try {
      await removeFromCart(itemId);
      dispatch(removeItem(itemId));
    } catch (error) {
      console.error("Failed to remove item from cart:", error);
    }
  };

  const handleProceedToCheckout = () => {
    navigate("/checkout");
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          p: 4,
          maxWidth: "800px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 4, maxWidth: "800px" }}>
        <Typography color="error">
          Error loading cart: {error.message}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, maxWidth: "800px" }}>
      <Typography variant="h4" gutterBottom color="primary">
        Your Shopping Cart
      </Typography>
      {items.length === 0 ? (
        <Typography>Your cart is empty.</Typography>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ mt: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell align="right">Price</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Subtotal</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="right">
                      ${item.price.toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        type="number"
                        value={item.quantity}
                        onChange={(e) =>
                          handleQuantityChange(item, parseInt(e.target.value))
                        }
                        inputProps={{ min: 1 }}
                        sx={{ width: 60 }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      ${(item.price * item.quantity).toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleRemoveItem(item.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ marginTop: 3, textAlign: "right" }}>
            <Typography variant="h6" gutterBottom>
              Total: ${total.toFixed(2)}
            </Typography>
            <Button
              variant="darkRounded"
              onClick={handleProceedToCheckout}
              sx={{ mt: 2 }}
            >
              Proceed to Checkout
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ShoppingCart;
