// store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import drawerReducer from "./drawerSlice";
import lessonPlanReducer from "./lessonPlanSlice";
import cartReducer from "./cartSlice";
import checkoutReducer from "./checkoutSlice";
import networkReducer from "./networkSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    drawer: drawerReducer,
    lessonPlan: lessonPlanReducer,
    cart: cartReducer,
    checkout: checkoutReducer,
    network: networkReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["checkout/setStripe"],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["payload.stripe"],
        // Ignore these paths in the state
        ignoredPaths: ["checkout.stripe"],
      },
    }),
});

export default store;
