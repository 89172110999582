import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Divider,
  Alert,
  CircularProgress,
} from "@mui/material";
import LoginFlowModal from "./LoginFlowModal";
import { useLogin } from "../../hooks/useLogin";
import HsModal from "../common/HsModal";
import HsGoogleButton from "../HsGoogleButton";
import { useReportContext } from "../../contexts/report-context";
import { resendVerification } from "../../api/authApi";

const LoginModal = ({ open, handleClose, showRegisterAccount }) => {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const { setMessage } = useReportContext();
  const {
    loginWithCredentials,
    authResponse,
    setAuthResponse,
    isLoading,
    verificationRequired,
    userEmail,
  } = useLogin();
  const [resendingEmail, setResendingEmail] = useState(false);
  const [nextResendTime, setNextResendTime] = useState(null);

  console.log("LoginModal - Rendering:", {
    hasAuthResponse: !!authResponse,
    authResponseData: authResponse,
    isLoading,
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = async () => {
    try {
      await loginWithCredentials(formData);
    } catch (error) {
      // Only show error message for non-verification errors
      if (error.response?.data?.status !== "USER_EXISTS_UNVERIFIED") {
        setMessage({
          text: "Login failed. Please check your credentials and try again.",
          severity: "error",
          flag: true,
        });
      }
    }
  };

  const handlePasswordKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleShowRegisterAccount = () => {
    handleClose();
    showRegisterAccount();
  };

  const formatTimeRemaining = (nextAllowedTime) => {
    const now = Date.now();
    const diff = nextAllowedTime - now;
    const minutes = Math.ceil(diff / (1000 * 60));
    return `${minutes} minute${minutes !== 1 ? "s" : ""}`;
  };

  const handleResendVerification = async () => {
    setResendingEmail(true);
    try {
      const response = await resendVerification(userEmail);
      setMessage({
        text: response.data.message,
        severity: "success",
        flag: true,
      });
      setNextResendTime(null);
    } catch (error) {
      if (error.type === "RATE_LIMITED") {
        setNextResendTime(error.nextAllowedTime);
        setMessage({
          text: error.message,
          severity: "warning",
          flag: true,
        });
      } else if (error.response?.data?.status === "USER_EXISTS_VERIFIED") {
        setMessage({
          text: "This email address is already verified. Please try logging in.",
          severity: "info",
          flag: true,
        });
      } else if (error.response?.data?.status === "USER_NOT_FOUND") {
        setMessage({
          text: "No account found for this email address.",
          severity: "error",
          flag: true,
        });
      } else {
        setMessage({
          text: "Failed to resend verification email. Please try again later.",
          severity: "error",
          flag: true,
        });
      }
    } finally {
      setResendingEmail(false);
    }
  };

  return (
    <HsModal open={open} handleClose={handleClose} title="Login">
      <Box sx={{ width: "100%", maxWidth: 400, padding: 2 }}>
        <div style={{ textAlign: "center" }}>
          {verificationRequired && (
            <Alert
              severity="info"
              sx={{ mb: 2 }}
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={handleResendVerification}
                  disabled={resendingEmail || nextResendTime !== null}
                  endIcon={resendingEmail && <CircularProgress size={16} />}
                >
                  {nextResendTime
                    ? `Resend available in ${formatTimeRemaining(
                        nextResendTime
                      )}`
                    : "Resend Email"}
                </Button>
              }
            >
              Please verify your email address. A verification email has been
              sent to {userEmail}.
              {nextResendTime && (
                <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                  Too many attempts. Please wait before requesting another
                  email.
                </Typography>
              )}
            </Alert>
          )}

          <form>
            <TextField
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              margin="normal"
              required
              fullWidth
            />
            <TextField
              label="Password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              onKeyDown={handlePasswordKeyDown}
              margin="normal"
              required
              fullWidth
            />
            <Box sx={{ mt: 2, mb: 3 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleLogin}
                fullWidth
                disabled={isLoading}
                sx={{
                  borderRadius: "20px",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                {isLoading ? <CircularProgress size={24} /> : "Login"}
              </Button>
            </Box>
          </form>

          <Divider sx={{ margin: (theme) => theme.spacing(3, 0) }}>
            <Typography variant="body1" color="textSecondary">
              OR
            </Typography>
          </Divider>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <HsGoogleButton />
          </Box>

          <Typography variant="body2" sx={{ mt: 2 }}>
            Don't have an account?{" "}
            <Button
              color="primary"
              onClick={handleShowRegisterAccount}
              sx={{
                p: 0,
                minWidth: 0,
                verticalAlign: "baseline",
                textTransform: "none",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },
              }}
            >
              Sign up here
            </Button>
          </Typography>
        </div>
        <LoginFlowModal
          authResponse={authResponse}
          setAuthResponse={setAuthResponse}
        />
      </Box>
    </HsModal>
  );
};

export default LoginModal;
