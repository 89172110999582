import React, { useCallback } from "react";
import { Stack, Typography, IconButton } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import dayjs from "dayjs";
import { getFullDate } from "../utils";

const InstructionHour = React.memo(
  ({
    day,
    index,
    hour,
    handleChangeTime,
    handleRemoveDayInstructionHour,
    error,
  }) => {
    const handleTimeChange = useCallback(
      (field, value) => {
        // Only update if we have a valid date value
        if (value && value.isValid()) {
          handleChangeTime(day, index, field, value.format("HH:mm"));
        }
      },
      [day, index, handleChangeTime]
    );

    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        <TimePicker
          label="Start Time"
          value={hour.startTime ? dayjs(getFullDate(hour.startTime)) : null}
          onChange={(val) => handleTimeChange("startTime", val)}
          views={["hours", "minutes"]}
          ampm={true}
          slotProps={{
            textField: {
              size: "small",
              error: error?.startTime,
              helperText: error?.startTime,
              placeholder: "hh:mm AM/PM",
            },
          }}
        />
        <Typography>to</Typography>
        <TimePicker
          label="End Time"
          value={hour.endTime ? dayjs(getFullDate(hour.endTime)) : null}
          onChange={(val) => handleTimeChange("endTime", val)}
          views={["hours", "minutes"]}
          ampm={true}
          slotProps={{
            textField: {
              size: "small",
              error: error?.endTime,
              helperText: error?.endTime,
              placeholder: "hh:mm AM/PM",
            },
          }}
        />
        <IconButton
          aria-label="remove time range"
          size="small"
          color="error"
          onClick={() => handleRemoveDayInstructionHour(day, index)}
        >
          <RemoveCircleIcon />
        </IconButton>
      </Stack>
    );
  }
);

export default InstructionHour;
