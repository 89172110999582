import { api } from "./api.js";
import apiUrl from "../config/config.js";

export const getSubscriptionDetails = async (accountId, signal) => {
  const response = await api.get(`${apiUrl}/api/v1/subscription/${accountId}`, {
    signal,
  });
  return response.data;
};

export const createBillingPortalSession = async (accountId, signal) => {
  try {
    const response = await api.post(
      `${apiUrl}/api/v1/subscription/billing-portal-session`,
      null,
      {
        params: { account_id: accountId },
        signal,
      }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 400) {
      throw new Error("No subscription found for this account");
    }
    throw error;
  }
};

export const setupPricingTable = async (accountId, signal) => {
  const response = await api.post(
    `${apiUrl}/api/v1/subscription/pricing-setup`,
    null,
    {
      params: { account_id: accountId },
      signal,
    }
  );
  return response.data;
};
