// InviteUserModal.js
import React, { useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { validateEmail } from "../../utils/validation";
import { createNewUser } from "../../api/userApi";
import { useSelector } from "react-redux";
import { usePlanRestriction } from "../../hooks/usePlanRestriction";
import { debug } from "../../utils/debug";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
    marginTop: theme.spacing(2),
  },
  error: {
    color: "red",
    marginTop: theme.spacing(2),
  },
}));

const GRADE_LEVELS = [
  { value: 0, label: "Kindergarten" },
  { value: 1, label: "1st Grade" },
  { value: 2, label: "2nd Grade" },
  { value: 3, label: "3rd Grade" },
  { value: 4, label: "4th Grade" },
  { value: 5, label: "5th Grade" },
  { value: 6, label: "6th Grade" },
  { value: 7, label: "7th Grade" },
  { value: 8, label: "8th Grade" },
  { value: 9, label: "9th Grade" },
  { value: 10, label: "10th Grade" },
  { value: 11, label: "11th Grade" },
  { value: 12, label: "12th Grade" },
];

const InviteUserModal = ({
  title,
  open,
  onCancel,
  onSave,
  userRole,
  showGradeLevel = false,
}) => {
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const { handlePlanRestrictionError } = usePlanRestriction();

  const [newUser, setNewUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    gradeLevel: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const classes = useStyles();

  if (!open) {
    return null;
  }

  // Add validation check function
  const isFormValid = () => {
    const hasRequiredFields = !!(
      newUser.firstName &&
      newUser.lastName &&
      newUser.email &&
      validateEmail(newUser.email)
    );

    // Only check gradeLevel if it's required
    if (showGradeLevel) {
      return hasRequiredFields && newUser.gradeLevel !== "";
    }

    return hasRequiredFields;
  };

  const handleSaveUser = async () => {
    if (!isFormValid()) {
      setErrorMessage("Please fill in all required fields.");
      return;
    }

    try {
      await createNewUser(
        {
          ...newUser,
          ...(showGradeLevel && {
            gradeLevel: parseInt(newUser.gradeLevel, 10),
          }),
        },
        selectedAccountId,
        userRole
      );

      // Clear form and error state
      setErrorMessage("");
      setNewUser({ firstName: "", lastName: "", email: "", gradeLevel: "" });
      
      // Call onSave callback after successful API call
      onSave();
    } catch (error) {
      debug.wizard.state("Error creating user:", error);
      if (!handlePlanRestrictionError(error)) {
        setErrorMessage("Failed to create user. Please try again.");
      }
      // Don't call onSave if there's an error
      return;
    }
  };

  const handleCancel = () => {
    setNewUser({
      firstName: "",
      lastName: "",
      email: "",
      gradeLevel: "",
    });
    onCancel();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="invite-user-modal-title"
      aria-describedby="invite-user-modal-description"
      fullWidth
      maxWidth="sm"
    >
      {title && (
        <DialogTitle style={{ cursor: "move" }} id="dialog-title">
          {title}
        </DialogTitle>
      )}
      <DialogContent dividers sx={{ p: 2 }}>
        <form className={classes.form}>
          <TextField
            label="First Name"
            value={newUser.firstName}
            onChange={(e) =>
              setNewUser({ ...newUser, firstName: e.target.value })
            }
            required
          />
          <TextField
            label="Last Name"
            value={newUser.lastName}
            onChange={(e) =>
              setNewUser({ ...newUser, lastName: e.target.value })
            }
            required
          />
          <TextField
            label="Email"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            error={!!(newUser.email && !validateEmail(newUser.email))}
            helperText={
              newUser.email && !validateEmail(newUser.email) && "Invalid email"
            }
            required
          />
          {showGradeLevel && (
            <TextField
              select
              label="Grade Level"
              value={newUser.gradeLevel}
              onChange={(e) =>
                setNewUser({ ...newUser, gradeLevel: e.target.value })
              }
              required
            >
              {GRADE_LEVELS.map((grade) => (
                <MenuItem key={grade.value} value={grade.value}>
                  {grade.label}
                </MenuItem>
              ))}
            </TextField>
          )}
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveUser}
              disabled={!isFormValid()}
            >
              Save
            </Button>
          </div>
        </form>
        {errorMessage && <p className={classes.error}>{errorMessage}</p>}
      </DialogContent>
    </Dialog>
  );
};

export default InviteUserModal;
