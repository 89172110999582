import { api } from "./api.js";
import apiUrl from "../config/config.js";

const BASE_URL = `${apiUrl}/api/v1/lesson-plans`;

export const getLessonPlans = async (
  accountId,
  page = 0,
  size = 20,
  signal
) => {
  const response = await api.get(`${BASE_URL}/search`, {
    params: {
      account_id: accountId,
      page,
      size,
    },
    signal,
  });
  return response.data;
};

export const searchLessonPlans = async (
  accountId,
  criteria,
  page = 0,
  size = 20,
  signal
) => {
  const { name, subject, isOngoing } = criteria;
  const response = await api.get(`${BASE_URL}/search`, {
    params: {
      account_id: accountId,
      name,
      subject,
      isOngoing,
      page,
      size,
    },
    signal,
  });
  return response.data;
};

export const getLessonPlanById = async (id, signal) => {
  const response = await api.get(`${BASE_URL}/${id}`, { signal });
  return response.data;
};

export const createLessonPlan = async (data, signal) => {
  if (!data.accountId) {
    throw new Error("accountId is required for creating a lesson plan");
  }

  const response = await api.post(BASE_URL, data, { signal });
  return response.data;
};

export const updateLessonPlan = async (id, data, signal) => {
  if (!data.accountId) {
    throw new Error("accountId is required for updating a lesson plan");
  }
  const response = await api.put(`${BASE_URL}/${id}`, data, { signal });
  return response.data;
};

export const deleteLessonPlan = async (id, signal) => {
  await api.delete(`${BASE_URL}/${id}`, { signal });
};

export const applyLessonPlanToTerm = async (lessonPlanId, data, signal) => {
  // Validate data object
  if (!data || !data.termId || !data.studentId) {
    console.error("Invalid data object:", data);
    throw new Error("Missing required data fields");
  }

  const response = await api.post(`${BASE_URL}/${lessonPlanId}/apply`, data, {
    signal,
  });
  return response.data;
};

export const removeLessonPlanFromTerm = async (
  lessonPlanId,
  termId,
  studentId,
  signal
) => {
  if (!studentId) {
    throw new Error(
      "student_id is required for removing a lesson plan from term"
    );
  }

  await api.delete(`${BASE_URL}/${lessonPlanId}/terms/${termId}`, {
    params: {
      student_id: studentId,
    },
    signal,
  });
};

export const getClassInstances = async (
  lessonPlanId,
  page = 0,
  size = 20,
  signal
) => {
  const response = await api.get(`${BASE_URL}/${lessonPlanId}/instances`, {
    params: { page, size },
    signal,
  });
  return response.data;
};

export const modifyInstanceSchedule = async (
  lessonPlanId,
  instanceId,
  data,
  signal
) => {
  const response = await api.post(
    `${BASE_URL}/${lessonPlanId}/instances/${instanceId}/schedule`,
    data,
    { signal }
  );
  return response.data;
};
