// hooks/useTerms.js
import { useState, useCallback, useEffect } from "react";
import {
  fetchActiveTerms,
  fetchPastTerms,
  fetchFutureTerms,
  deleteTerm as apiDeleteTerm,
} from "../api/termApi";

export const useTerms = () => {
  const [activeTerms, setActiveTerms] = useState([]);
  const [futureTerms, setFutureTerms] = useState([]);
  const [pastTerms, setPastTerms] = useState([]);

  const fetchTerms = useCallback(async () => {
    const [active, future, past] = await Promise.all([
      fetchActiveTerms(),
      fetchFutureTerms(),
      fetchPastTerms(),
    ]);
    setActiveTerms(active.data || []);
    setFutureTerms(future.data || []);
    setPastTerms(past.data || []);
  }, []);

  useEffect(() => {
    fetchTerms();
  }, [fetchTerms]);

  const refreshTerms = useCallback(() => {
    fetchTerms();
  }, [fetchTerms]);

  const deleteTerm = useCallback(
    async (id) => {
      await apiDeleteTerm(id);
      refreshTerms();
    },
    [refreshTerms]
  );

  return {
    activeTerms,
    futureTerms,
    pastTerms,
    refreshTerms,
    deleteTerm,
  };
};
