import React from "react";
import { Grid, FormControl, FormLabel, FormGroup } from "@mui/material";
import InstructionDay from "./InstructionDay";
import { DAYS_OF_WEEK } from "./constants";

const Step2 = React.memo(
  ({
    instructionHours,
    handleSelectInstructionDay,
    handleAddDayInstructionHour,
    handleChangeTime,
    handleRemoveDayInstructionHour,
    validationErrors,
  }) => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl
          component="fieldset"
          sx={{
            width: "100%",
            padding: "16px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: "4px",
          }}
        >
          <FormLabel component="legend">Instruction Hours</FormLabel>
          <FormGroup>
            {DAYS_OF_WEEK.map((day) => (
              <InstructionDay
                key={day}
                day={day}
                isSelected={instructionHours[day]?.isSelected || false}
                value={instructionHours[day]?.value || []}
                handleSelectInstructionDay={handleSelectInstructionDay}
                handleAddDayInstructionHour={handleAddDayInstructionHour}
                handleChangeTime={handleChangeTime}
                handleRemoveDayInstructionHour={handleRemoveDayInstructionHour}
                errors={validationErrors[day] || []}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  )
);

export default Step2;
