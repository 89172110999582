import React, { useState, useCallback, useEffect } from "react";
import { Box, Typography, Chip, Button, TableCell, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { getUserOrders } from "../../api/ordersApi";
import HsTable from "../../components/common/HsTable";

const Orders = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const {
    data: ordersData,
    loading,
    error,
    execute: fetchOrders,
  } = useAsyncOperation(getUserOrders);

  useEffect(() => {
    fetchOrders(page, rowsPerPage);
  }, [fetchOrders, page, rowsPerPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page when changing page size
  };

  const handleRowClick = (order) => {
    navigate(`/orders/${order.id}`);
  };

  const getErrorMessage = (error) => {
    if (!error) return "";
    if (typeof error === "string") return error;
    if (error.response?.data?.message) return error.response.data.message;
    if (error.message) return error.message;
    return "An unexpected error occurred";
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "delivered":
        return "success";
      case "processing":
      case "pending":
        return "warning";
      case "cancelled":
        return "error";
      default:
        return "default";
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const columns = [
    { id: "id", label: "Order ID", width: "20%" },
    { id: "date", label: "Date", width: "25%" },
    { id: "total", label: "Total", width: "20%" },
    { id: "status", label: "Status", width: "20%" },
    { id: "tracking", label: "Tracking", width: "15%" },
  ];

  const renderDesktopRow = useCallback(
    (order) => (
      <>
        <TableCell>{order.id}</TableCell>
        <TableCell>{formatDate(order.createdAt)}</TableCell>
        <TableCell>{formatCurrency(order.total)}</TableCell>
        <TableCell>
          <Chip
            label={order.status}
            color={getStatusColor(order.status)}
            size="small"
          />
        </TableCell>
        <TableCell>
          {order.printOnDemandOrder?.trackingNumber ? (
            <Button
              variant="text"
              size="small"
              href={`https://track.printify.com/${order.printOnDemandOrder.trackingNumber}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              {order.printOnDemandOrder.trackingNumber}
            </Button>
          ) : (
            "Not available"
          )}
        </TableCell>
      </>
    ),
    []
  );

  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 3 }}>
        My Orders
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {getErrorMessage(error)}
        </Alert>
      )}

      <HsTable
        columns={columns}
        data={ordersData?.content || []}
        totalCount={ordersData?.totalElements || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        loading={loading}
        error={getErrorMessage(error)}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        renderDesktopRow={renderDesktopRow}
        onRowClick={handleRowClick}
        emptyMessage="No orders found"
      />
    </Box>
  );
};

export default Orders;
