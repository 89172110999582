import { CONFIG } from "../config/config";

const isDev = CONFIG.NODE_ENV === "development";

const enableAuthLogs = CONFIG.REACT_APP_ENABLE_AUTH_LOGS === "true";
const enableCalendarLogs = CONFIG.REACT_APP_ENABLE_CALENDAR_LOGS === "true";

export const debug = {
  log: (...args) => {
    if (isDev) {
      console.log(...args);
    }
  },
  warn: (...args) => {
    if (isDev) {
      console.warn(...args);
    }
  },
  error: (...args) => {
    // Always log errors, but could be configured to use error reporting service in prod
    console.error(...args);
  },
  group: (label, fn) => {
    if (isDev) {
      console.group(label);
      fn();
      console.groupEnd();
    }
  },
  // Add namespace support for feature-specific debugging
  auth: {
    log: (...args) => enableAuthLogs && console.log("Auth:", ...args),
    error: (...args) => console.error("Auth:", ...args),
  },
  calendar: {
    log: (...args) => enableCalendarLogs && console.log("Calendar:", ...args),
    error: (...args) => console.error("Calendar:", ...args),
  },
  api: {
    log: (...args) => isDev && console.log("API:", ...args),
    error: (...args) => console.error("API:", ...args),
  },
  network: {
    log: (...args) => isDev && console.log("Network:", ...args),
    error: (...args) => console.error("Network:", ...args),
  },
  profile: {
    log: (...args) => isDev && console.log("Profile:", ...args),
    error: (...args) => console.error("Profile:", ...args),
  },
  class: {
    log: (...args) => isDev && console.log("Class:", ...args),
    error: (...args) => console.error("Class:", ...args),
  },
  ui: {
    log: (...args) => isDev && console.log("UI:", ...args),
    error: (...args) => console.error("UI:", ...args),
  },
  checklist: {
    log: (...args) => isDev && console.log("Checklist:", ...args),
    error: (...args) => console.error("Checklist:", ...args),
  },
  cart: {
    log: (...args) => isDev && console.log("Cart:", ...args),
    error: (...args) => console.error("Cart:", ...args),
  },
  wizard: {
    log: (...args) => isDev && console.log("Wizard:", ...args),
    error: (...args) => console.error("Wizard:", ...args),
    state: (...args) => isDev && console.log("WizardState:", ...args),
  },
  navigation: {
    log: (...args) => console.log("[Navigation]", ...args),
    error: (...args) => console.error("[Navigation]", ...args),
  },
};
