import { useSelector } from "react-redux";

/**
 * Hook to access calendar connection state
 * Does not handle prompts/navigation to avoid context dependencies
 */
export const useCalendarConnection = () => {
  const {
    googleCalendarConnected,
    reconnectGoogleCalendar,
    defaultSyncCalendarId,
  } = useSelector((state) => state.auth);

  return {
    isConnected: googleCalendarConnected,
    needsReconnect: reconnectGoogleCalendar,
    defaultSyncCalendarId,
  };
};
