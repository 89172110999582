import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Stack,
  Button,
  useMediaQuery,
  IconButton,
  TableCell,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SchoolTermModal from "./modal/SchoolTermModal";
import { useTerms } from "../../hooks/useTerms";
import { useReportContext } from "../../contexts/report-context";
import HsTable from "../../components/common/HsTable";
import { format } from "date-fns";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material/styles";
import ConfirmationDialog from "../../components/common/ConfirmationDialog";

const MobileFilterButton = styled(Button)(({ theme, active }) => ({
  borderRadius: "50px",
  padding: theme.spacing(0.5, 2),
  textTransform: "none",
  backgroundColor: active
    ? theme.palette.primary.main
    : theme.palette.background.paper,
  color: active ? theme.palette.common.white : theme.palette.text.primary,
  "&:hover": {
    backgroundColor: active
      ? theme.palette.primary.dark
      : theme.palette.background.default,
  },
}));

const SchoolTermsPage = () => {
  const { setMessage } = useReportContext();
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [mobileFilter, setMobileFilter] = useState("active");
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    term: null,
  });
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const theme = useTheme();

  const { activeTerms, futureTerms, pastTerms, refreshTerms, deleteTerm } =
    useTerms();

  useEffect(() => {
    refreshTerms();
  }, [refreshTerms]);

  const handleCreateTerm = () => {
    setSelectedTerm(null);
    setIsOpenModal(true);
  };

  const handleSave = useCallback(() => {
    setIsOpenModal(false);
    setSelectedTerm(null);
    refreshTerms();
  }, [refreshTerms]);

  const handleCancel = useCallback(() => {
    setIsOpenModal(false);
    setSelectedTerm(null);
  }, []);

  const handleOpenTermEditModal = useCallback((rowData) => {
    setSelectedTerm(rowData);
    setIsOpenModal(true);
  }, []);

  const handleShowDeleteConfirmation = useCallback((term) => {
    setDeleteConfirmation({
      open: true,
      term,
    });
  }, []);

  const handleCancelDelete = useCallback(() => {
    setDeleteConfirmation({
      open: false,
      term: null,
    });
  }, []);

  const handleConfirmedDelete = useCallback(async () => {
    const term = deleteConfirmation.term;
    if (!term) return;

    try {
      await deleteTerm(term.id);
      setMessage({
        text: "Term deleted successfully",
        severity: "success",
        flag: true,
      });
      refreshTerms();
    } catch (error) {
      setMessage({
        text: "Failed to delete term",
        severity: "error",
        flag: true,
      });
    } finally {
      setDeleteConfirmation({
        open: false,
        term: null,
      });
    }
  }, [deleteConfirmation.term, deleteTerm, refreshTerms, setMessage]);

  const columns = useMemo(
    () => [
      { id: "name", label: "Name", width: "40%" },
      { id: "dateRange", label: "Date Range", width: "40%" },
      { id: "actions", label: "Actions", width: "20%" },
    ],
    []
  );

  const renderDesktopRow = useCallback(
    (row, columns) => (
      <>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            sx={{
              width: column.width,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              "&:last-child": {
                paddingRight: 2,
              },
            }}
          >
            {column.id === "dateRange" ? (
              `${format(new Date(row.startDate), "MMM d, yyyy")} - ${format(
                new Date(row.endDate),
                "MMM d, yyyy"
              )}`
            ) : column.id === "actions" ? (
              <Stack direction="row" spacing={1}>
                <IconButton
                  onClick={() => handleOpenTermEditModal(row)}
                  size="small"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleShowDeleteConfirmation(row)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            ) : (
              row[column.id]
            )}
          </TableCell>
        ))}
      </>
    ),
    [handleOpenTermEditModal, handleShowDeleteConfirmation]
  );

  const renderMobileRow = useCallback(
    (row) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          py: 2,
          px: 2,
          borderColor: "divider",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="subtitle1" fontWeight="bold">
            {row.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
            {format(new Date(row.startDate), "MMM d, yyyy")} -{" "}
            {format(new Date(row.endDate), "MMM d, yyyy")}
          </Typography>
        </Box>
        <IconButton
          onClick={() => handleOpenTermEditModal(row)}
          size="small"
          sx={{ ml: 1 }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    ),
    [handleOpenTermEditModal]
  );

  const TermTable = useCallback(
    ({ terms, tableId, emptyMessage }) => (
      <HsTable
        columns={columns}
        data={terms || []}
        totalCount={terms?.length || 0}
        page={0}
        rowsPerPage={terms?.length || 0}
        loading={false}
        error={null}
        onPageChange={() => {}}
        onRowsPerPageChange={() => {}}
        renderDesktopRow={renderDesktopRow}
        renderMobileRow={renderMobileRow}
        showPagination={false}
        tableId={tableId}
        emptyMessage={emptyMessage}
        sx={{
          "& .MuiTable-root": {
            tableLayout: "fixed",
          },
          "& .MuiTableCell-root": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
        }}
      />
    ),
    [columns, renderDesktopRow, renderMobileRow]
  );

  const renderMobileFilterButtons = () => (
    <Stack direction="row" spacing={1} mb={2}>
      <MobileFilterButton
        active={mobileFilter === "active"}
        onClick={() => setMobileFilter("active")}
      >
        Active
      </MobileFilterButton>
      <MobileFilterButton
        active={mobileFilter === "future"}
        onClick={() => setMobileFilter("future")}
      >
        Future
      </MobileFilterButton>
      <MobileFilterButton
        active={mobileFilter === "past"}
        onClick={() => setMobileFilter("past")}
      >
        Past
      </MobileFilterButton>
    </Stack>
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography variant="h2">School Terms</Typography>
        <IconButton
          onClick={handleCreateTerm}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>

      <Grid container spacing={3}>
        {isMobile && (
          <Grid item xs={12}>
            {renderMobileFilterButtons()}
          </Grid>
        )}
        {isMobile ? (
          <Grid item xs={12}>
            {mobileFilter === "active" && (
              <TermTable
                terms={activeTerms}
                tableId="activeTerms"
                emptyMessage="No School Terms Defined"
              />
            )}
            {mobileFilter === "future" && futureTerms.length > 0 && (
              <TermTable terms={futureTerms} tableId="futureTerms" />
            )}
            {mobileFilter === "past" && pastTerms.length > 0 && (
              <TermTable terms={pastTerms} tableId="pastTerms" />
            )}
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Active Terms
              </Typography>
              <TermTable
                terms={activeTerms}
                tableId="activeTerms"
                emptyMessage="No School Terms Defined"
              />
            </Grid>
            {futureTerms.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  Future Terms
                </Typography>
                <TermTable terms={futureTerms} tableId="futureTerms" />
              </Grid>
            )}
            {pastTerms.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  Past Terms
                </Typography>
                <TermTable terms={pastTerms} tableId="pastTerms" />
              </Grid>
            )}
          </>
        )}
      </Grid>
      <SchoolTermModal
        open={isOpenModal}
        onSave={handleSave}
        onCancel={handleCancel}
        term={selectedTerm}
      />
      <ConfirmationDialog
        open={deleteConfirmation.open}
        title="Delete School Term?"
        message={
          <Box>
            <Typography variant="body1" gutterBottom>
              Are you sure you want to delete "{deleteConfirmation.term?.name}"?
            </Typography>
            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
              Warning: This action cannot be undone. Any classes and enrollments
              associated with this term may no longer be editable or accessible.
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              Please type <strong>{deleteConfirmation.term?.name}</strong> to
              confirm deletion.
            </Typography>
          </Box>
        }
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={handleConfirmedDelete}
        onCancel={handleCancelDelete}
        requireConfirmationText={deleteConfirmation.term?.name}
        confirmButtonProps={{
          color: "error",
        }}
      />
    </Box>
  );
};

export default SchoolTermsPage;
