// src/components/CalendarView.js

import React, { useCallback, useState, useEffect } from "react";
import { Calendar, Views, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import { Box, CircularProgress, Typography } from "@mui/material";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = dayjsLocalizer(dayjs);

const CalendarView = ({
  events,
  handleOpenDetailsModal,
  loading,
  error,
  onRangeChange,
  onView,
  onNavigate,
  defaultView = Views.MONTH,
  currentDate,
}) => {
  const theme = useTheme();
  const [currentLabel, setCurrentLabel] = useState("");

  const handleClickEvent = (event) => {
    handleOpenDetailsModal(event);
  };

  // Custom toolbar to match theme
  const CustomToolbar = ({
    label,
    onNavigate: toolbarNavigate,
    onView,
    view,
  }) => {
    // Update the label whenever it changes
    useEffect(() => {
      setCurrentLabel(label);
    }, [label]);

    const handleNavigate = (action) => {
      toolbarNavigate(action);
      onNavigate(action);
    };

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <div>
          <button
            onClick={() => handleNavigate("PREV")}
            style={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              border: "none",
              padding: "8px 16px",
              borderRadius: "20px",
              marginRight: "8px",
              cursor: "pointer",
            }}
          >
            Back
          </button>
          <button
            onClick={() => handleNavigate("TODAY")}
            style={{
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.primary.main,
              border: `1px solid ${theme.palette.primary.main}`,
              padding: "8px 16px",
              borderRadius: "20px",
              marginRight: "8px",
              cursor: "pointer",
            }}
          >
            Today
          </button>
          <button
            onClick={() => handleNavigate("NEXT")}
            style={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              border: "none",
              padding: "8px 16px",
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            Next
          </button>
        </div>
        <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
          {currentLabel}
        </span>
        <div>
          {["month", "week", "day", "agenda"].map((viewName) => (
            <button
              key={viewName}
              onClick={() => onView(viewName)}
              style={{
                backgroundColor:
                  view === viewName
                    ? theme.palette.primary.main
                    : "transparent",
                color:
                  view === viewName
                    ? theme.palette.primary.contrastText
                    : theme.palette.text.primary,
                border: `1px solid ${theme.palette.primary.main}`,
                padding: "8px 16px",
                borderRadius: "20px",
                marginLeft: "8px",
                cursor: "pointer",
              }}
            >
              {viewName.charAt(0).toUpperCase() + viewName.slice(1)}
            </button>
          ))}
        </div>
      </div>
    );
  };

  // Custom event component
  const EventComponent = useCallback(
    ({ event }) => (
      <div
        style={{
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.contrastText,
          padding: "4px 8px",
          borderRadius: "4px",
          fontSize: "0.875rem",
        }}
      >
        {event.title}
      </div>
    ),
    [theme]
  );

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={500}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={500}
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      defaultView={defaultView}
      views={[Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA]}
      onSelectEvent={handleClickEvent}
      onRangeChange={onRangeChange}
      onView={onView}
      defaultDate={currentDate}
      style={{ height: 700 }}
      components={{
        toolbar: CustomToolbar,
        event: EventComponent,
      }}
      popup
      selectable
      min={new Date().setHours(6, 0, 0)}
      max={new Date().setHours(20, 0, 0)}
      eventPropGetter={() => ({
        style: {
          backgroundColor: theme.palette.primary.light,
          border: "none",
        },
      })}
    />
  );
};

export default CalendarView;
