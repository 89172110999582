import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
  Avatar,
  Paper,
  Slider,
  Alert,
} from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import {
  getUserById,
  uploadProfileImage,
  updateUserProfile,
  updateUser,
} from "../../api/userApi";
import { useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import HsModal from "../../components/common/HsModal";
import { usePlannerWizardContext } from "../../contexts/planner-wizard-context";
import isEqual from "lodash/isEqual";
import { debug } from "../../utils/debug";

const StudentInfoConfirmation = forwardRef((props, ref) => {
  const {
    wizardData,
    updateWizardData,
    updateStepValidation,
    validateStep,
    activeStep: stepIndex,
    stepValidation,
    resetPreviewGeneration,
  } = usePlannerWizardContext();

  debug.wizard.state("StudentInfoConfirmation init:", {
    wizardData,
    student: wizardData?.student,
  });

  // State declarations
  const initialStudentInfo = useMemo(
    () => ({
      firstName: wizardData?.student?.firstName || "",
      lastName: wizardData?.student?.lastName || "",
      gradeLevel: wizardData?.student?.gradeLevel || "",
      profileImageUrl: wizardData?.student?.profileImageUrl || "",
      username: wizardData?.student?.username || "",
      email: wizardData?.student?.email || "",
      phoneNumber: wizardData?.student?.phoneNumber || "",
      timezone: wizardData?.student?.timezone || "",
      status: wizardData?.student?.status || "",
    }),
    [wizardData?.student]
  );

  const [studentInfo, setStudentInfo] = useState(initialStudentInfo);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showUnsavedDialog, setShowUnsavedDialog] = useState(false);
  const [termName, setTermName] = useState(wizardData.term?.name || "");
  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarEditorOpen, setAvatarEditorOpen] = useState(false);
  const [avatarScale, setAvatarScale] = useState(1);

  // Refs
  const editorRef = useRef(null);
  const fileInputRef = useRef(null);
  const uploadHandledRef = useRef(false);
  const { selectedAccountId } = useSelector((state) => state.auth);
  const updateTimeoutRef = useRef(null);

  const {
    execute: executeUploadImage,
    loading: uploadingImage,
    error: uploadError,
    data: uploadedImageData,
  } = useAsyncOperation(uploadProfileImage);

  const {
    execute: executeUpdateProfile,
    loading: updatingProfile,
    error: updateProfileError,
  } = useAsyncOperation(updateUserProfile, {
    handleUnauthorized: true,
    errorHandler: (error) => console.error("Profile update error:", error),
  });

  // Define handleUpdateProfile before any effects that use it
  const handleUpdateProfile = useCallback(async () => {
    if (!wizardData.student?.id || !hasUnsavedChanges) return true;

    const updatePayload = {
      firstName: studentInfo.firstName,
      lastName: studentInfo.lastName,
      gradeLevel: studentInfo.gradeLevel,
      username: studentInfo.username,
      email: studentInfo.email,
      phoneNumber: studentInfo.phoneNumber,
      timezone: studentInfo.timezone,
      status: studentInfo.status,
    };

    const result = await executeUpdateProfile(
      wizardData.student.id,
      updatePayload,
      selectedAccountId
    );

    if (result) {
      updateWizardData({
        student: result,
        // Reset preview-related data
        reportId: null,
        previewUrls: null,
        generatedFor: null,
        status: "PENDING",
      });
      resetPreviewGeneration();
      setHasUnsavedChanges(false);
      return true;
    }

    console.error("Failed to update student profile:", updateProfileError);
    return false;
  }, [
    wizardData.student?.id,
    studentInfo,
    selectedAccountId,
    executeUpdateProfile,
    updateWizardData,
    resetPreviewGeneration,
    updateProfileError,
    hasUnsavedChanges,
  ]);

  // Fix the validation effect
  useEffect(() => {
    const isValid = Boolean(
      studentInfo.firstName && studentInfo.lastName && studentInfo.gradeLevel
    );
    // Update validation state directly without checking previous state
    updateStepValidation(isValid);
  }, [studentInfo, updateStepValidation]);

  // Remove the step change effect as it's not needed - we'll handle saves through handleNext
  useEffect(() => {
    setTermName(wizardData.term?.name || "");
  }, [wizardData.term]);

  // Optimize handleInputChange
  const handleInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setStudentInfo((prev) => {
        // Only update if the value actually changed
        if (prev[name] === value) return prev;

        const newInfo = {
          ...prev,
          [name]: value,
        };
        if (newInfo[name] !== initialStudentInfo[name]) {
          setHasUnsavedChanges(true);
        }
        return newInfo;
      });
    },
    [initialStudentInfo]
  );

  // Memoize the form content
  const renderForm = useMemo(
    () => (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="First Name"
            name="firstName"
            value={studentInfo.firstName}
            onChange={handleInputChange}
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            value={studentInfo.lastName}
            onChange={handleInputChange}
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Grade Level"
            name="gradeLevel"
            value={studentInfo.gradeLevel}
            onChange={handleInputChange}
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Term"
            value={termName}
            margin="normal"
            disabled
          />
        </Grid>
      </Grid>
    ),
    [studentInfo, termName, handleInputChange]
  );

  // Update useImperativeHandle to include validation
  useImperativeHandle(
    ref,
    () => ({
      handleNext: async () => {
        if (hasUnsavedChanges) {
          const success = await handleUpdateProfile();
          return success;
        }
        return true;
      },
      handleBack: async () => {
        if (hasUnsavedChanges) {
          return new Promise((resolve) => {
            setShowUnsavedDialog(true);
            window.handleUnsavedChanges = async (shouldSave) => {
              setShowUnsavedDialog(false);
              if (shouldSave) {
                const success = await handleUpdateProfile();
                if (success) {
                  setHasUnsavedChanges(false);
                }
                resolve(success);
              } else {
                setHasUnsavedChanges(false);
                resolve(true);
              }
            };
          });
        }
        return true;
      },
      handleUpdateProfile,
      isValid: () =>
        Boolean(
          studentInfo.firstName &&
            studentInfo.lastName &&
            studentInfo.gradeLevel
        ),
    }),
    [hasUnsavedChanges, handleUpdateProfile, studentInfo]
  );

  // Avatar handling
  const handleAvatarChange = useCallback((e) => {
    const file = e.target.files[0];
    setAvatarFile(file);
    setAvatarEditorOpen(true);
  }, []);

  const handleSaveAvatar = useCallback(() => {
    if (!editorRef.current || !wizardData.student?.id) return;

    const canvas = editorRef.current.getImageScaledToCanvas();
    canvas.toBlob((blob) => {
      const formData = new FormData();
      formData.append("file", blob, "profile-image.png");
      executeUploadImage(wizardData.student.id, formData, selectedAccountId);
    }, "image/png");
  }, [executeUploadImage, wizardData.student?.id, selectedAccountId]);

  useEffect(() => {
    if (uploadedImageData && !uploadHandledRef.current) {
      // Update both local state and wizard data
      const updatedStudent = {
        ...wizardData.student,
        profileImageUrl: uploadedImageData.profileImageUrl,
      };

      setStudentInfo((prev) => ({
        ...prev,
        profileImageUrl: uploadedImageData.profileImageUrl,
      }));

      // Update wizard data and reset preview
      updateWizardData({
        student: updatedStudent,
        // Reset preview-related data since avatar changed
        reportId: null,
        previewUrls: null,
        generatedFor: null,
        status: "PENDING",
      });
      resetPreviewGeneration();

      // Reset avatar state
      setAvatarEditorOpen(false);
      setAvatarFile(null);

      // Mark this upload as handled
      uploadHandledRef.current = true;
    } else if (!uploadedImageData) {
      // Reset the handled flag when there's no upload data
      uploadHandledRef.current = false;
    }
  }, [
    uploadedImageData,
    wizardData.student,
    updateWizardData,
    resetPreviewGeneration,
  ]);

  // Add error handling for avatar upload
  useEffect(() => {
    if (uploadError) {
      console.error("Error uploading avatar:", uploadError);
      setAvatarEditorOpen(false);
      setAvatarFile(null);
    }
  }, [uploadError]);

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (updateTimeoutRef.current) {
        clearTimeout(updateTimeoutRef.current);
      }
    };
  }, []);

  // Add cleanup for the window.handleUnsavedChanges
  useEffect(() => {
    return () => {
      delete window.handleUnsavedChanges;
    };
  }, []);

  if (updatingProfile) return <CircularProgress />;
  if (updateProfileError)
    return (
      <Alert severity="error">
        Error updating student information: {updateProfileError.message}
      </Alert>
    );

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Confirm Student Information
      </Typography>

      {hasUnsavedChanges && (
        <Alert severity="info" sx={{ mb: 2 }}>
          You have unsaved changes. They will be saved when you proceed to the
          next step.
        </Alert>
      )}

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
          <Avatar
            src={studentInfo.profileImageUrl}
            alt={`${studentInfo.firstName} ${studentInfo.lastName}`}
            sx={{ width: 150, height: 150, mb: 2 }}
          >
            {!studentInfo.profileImageUrl && (
              <PersonIcon style={{ fontSize: "5rem" }} />
            )}
          </Avatar>
          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Change Avatar
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleAvatarChange}
              ref={fileInputRef}
            />
          </Button>
        </Box>
        {renderForm}
      </Paper>
      {uploadError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          Failed to update profile image. Please try again.{" "}
          {uploadError.message}
        </Alert>
      )}
      <HsModal
        open={avatarEditorOpen}
        handleClose={() => {
          setAvatarEditorOpen(false);
          setAvatarFile(null);
        }}
        title="Edit Avatar"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {avatarFile && (
            <AvatarEditor
              ref={editorRef}
              image={avatarFile}
              width={250}
              height={250}
              border={50}
              borderRadius={125}
              color={[255, 255, 255, 0.6]}
              scale={avatarScale}
              rotate={0}
            />
          )}
          <Typography gutterBottom>Zoom</Typography>
          <Slider
            value={avatarScale}
            min={1}
            max={2}
            step={0.01}
            onChange={(e, newValue) => setAvatarScale(newValue)}
            sx={{ width: "80%", mt: 2, mb: 2 }}
          />
          <Button
            onClick={handleSaveAvatar}
            variant="contained"
            color="primary"
            disabled={uploadingImage}
            sx={{ mt: 2 }}
          >
            {uploadingImage ? <CircularProgress size={24} /> : "Save"}
          </Button>
          {uploadError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              Failed to upload avatar. Please try again.
            </Alert>
          )}
        </Box>
      </HsModal>
      <HsModal
        open={showUnsavedDialog}
        handleClose={() => setShowUnsavedDialog(false)}
        title="Unsaved Changes"
      >
        <Box sx={{ p: 3 }}>
          <Typography>
            You have unsaved changes. Would you like to save them before going
            back?
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", mt: 3, gap: 2 }}
          >
            <Button
              onClick={() => window.handleUnsavedChanges(false)}
              color="secondary"
            >
              Discard Changes
            </Button>
            <Button
              onClick={() => window.handleUnsavedChanges(true)}
              variant="contained"
              color="primary"
            >
              Save Changes
            </Button>
          </Box>
        </Box>
      </HsModal>
    </Box>
  );
});

export default StudentInfoConfirmation;
