export const formatInstructionHours = (hours) => {
  return Object.keys(hours).reduce((acc, day) => {
    acc[day] = {
      isSelected: true,
      value: Array.isArray(hours[day])
        ? hours[day].map((hour) => ({
            startTime: hour.startTime || "",
            endTime: hour.endTime || "",
          }))
        : [
            {
              startTime: hours[day].startTime || "",
              endTime: hours[day].endTime || "",
            },
          ],
    };
    return acc;
  }, {});
};

export const getCurrentTime = (value) => {
  if (!value) return "";
  const date = new Date(value);
  return `${String(date.getHours()).padStart(2, "0")}:${String(
    date.getMinutes()
  ).padStart(2, "0")}`;
};

export const getFullDate = (time) => {
  if (!time) return null;
  const [hours, minutes] = time.split(":");
  const date = new Date();
  date.setHours(parseInt(hours, 10));
  date.setMinutes(parseInt(minutes, 10));
  return date;
};
