import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Wizard, useWizard } from "react-use-wizard";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useSelector } from "react-redux";
import { createTerm, updateTerm } from "../../../api/termApi";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Footer from "./Footer";
import ConfirmationDialog from "../../../components/common/ConfirmationDialog";
import {
  INITIAL_INSTRUCTION_HOURS,
  INITIAL_VALUES,
  DAYS_OF_WEEK,
} from "./constants";
import { formatInstructionHours } from "../utils";
import { usePlanRestriction } from "../../../hooks/usePlanRestriction";
import { useReportContext } from "../../../contexts/report-context";
import { validateTimeRanges } from "../utils/validation";

dayjs.extend(utc);

const SchoolTermModal = ({ open, onSave, onCancel, term }) => {
  const { selectedAccountId } = useSelector((state) => state.auth);
  const { handlePlanRestrictionError } = usePlanRestriction();
  const { setMessage } = useReportContext();
  const [instructionHours, setInstructionHours] = useState(
    INITIAL_INSTRUCTION_HOURS
  );
  const [values, setValues] = useState(INITIAL_VALUES);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [initialInstructionHours, setInitialInstructionHours] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  // Track if form has unsaved changes
  const hasUnsavedChanges = useMemo(() => {
    if (!initialValues || !initialInstructionHours) return false;

    const valuesChanged =
      JSON.stringify(values) !== JSON.stringify(initialValues);
    const hoursChanged =
      JSON.stringify(instructionHours) !==
      JSON.stringify(initialInstructionHours);

    return valuesChanged || hoursChanged;
  }, [values, instructionHours, initialValues, initialInstructionHours]);

  // Validate current step
  const validateCurrentStep = useCallback(() => {
    if (activeStep === 0) {
      const errors = {};

      if (!values.name?.trim()) {
        errors.name = "Term name is required";
      }

      if (!values.startDate) {
        errors.startDate = "Start date is required";
      }

      if (!values.endDate) {
        errors.endDate = "End date is required";
      } else if (
        values.startDate &&
        dayjs(values.endDate).isBefore(dayjs(values.startDate))
      ) {
        errors.endDate = "End date must be after start date";
      }

      setValidationErrors(errors);
      return Object.keys(errors).length === 0;
    }

    // Step 2 validation (if needed)
    if (activeStep === 1) {
      const errors = validateTimeRanges(instructionHours);
      setValidationErrors(errors);
      return Object.keys(errors).length === 0;
    }

    return true;
  }, [activeStep, values, instructionHours]);

  // Check if current step is valid
  const isCurrentStepValid = useMemo(() => {
    if (activeStep === 0) {
      return !!(
        values.name?.trim() &&
        values.startDate &&
        values.endDate &&
        !dayjs(values.endDate).isBefore(dayjs(values.startDate))
      );
    }
    return true;
  }, [activeStep, values]);

  const handleClose = useCallback(() => {
    if (hasUnsavedChanges) {
      setShowConfirmation(true);
    } else {
      onCancel();
    }
  }, [hasUnsavedChanges, onCancel]);

  const handleConfirmClose = useCallback(() => {
    setShowConfirmation(false);
    onCancel();
  }, [onCancel]);

  const handleCancelClose = useCallback(() => {
    setShowConfirmation(false);
  }, []);

  const validateInstructionHours = useCallback(() => {
    const errors = {};
    Object.entries(instructionHours).forEach(([day, { isSelected, value }]) => {
      if (isSelected && (!value || value.length === 0)) {
        errors[day] = ["At least one time range is required for selected days"];
      }
    });
    return errors;
  }, [instructionHours]);

  const validateForm = useCallback(() => {
    const timeRangeErrors = validateTimeRanges(instructionHours);
    const instructionHoursErrors = validateInstructionHours();

    const errors = {
      ...timeRangeErrors,
      ...instructionHoursErrors,
    };

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  }, [instructionHours, validateInstructionHours]);

  const handleSubmit = async () => {
    if (!validateForm()) {
      setMessage({
        text: "Please correct the errors in the instruction hours",
        severity: "error",
        flag: true,
      });
      return;
    }

    const { name, startDate, endDate } = values;
    const selectedInstructionHours = Object.entries(instructionHours)
      .filter(([, { isSelected }]) => isSelected)
      .reduce((acc, [key, { value }]) => {
        acc[key] = value;
        return acc;
      }, {});

    const instructionHoursData = { hours: selectedInstructionHours };

    setIsSubmitting(true);
    try {
      // Format dates as YYYY-MM-DDT00:00:00
      const formattedStartDate = dayjs(startDate)
        .startOf("day")
        .format("YYYY-MM-DD[T]HH:mm:ss");
      const formattedEndDate = dayjs(endDate)
        .startOf("day")
        .format("YYYY-MM-DD[T]HH:mm:ss");

      const createdTerm = term?.id
        ? await updateTerm(
            term.id,
            formattedStartDate,
            formattedEndDate,
            instructionHoursData,
            name
          )
        : await createTerm(
            selectedAccountId,
            formattedStartDate,
            formattedEndDate,
            instructionHoursData,
            name
          );

      setIsSubmitting(false);
      onSave(createdTerm);
    } catch (error) {
      setIsSubmitting(false);
      if (!handlePlanRestrictionError(error)) {
        setMessage({
          text: "Failed to save term",
          severity: "error",
          flag: true,
        });
      }
    }
  };

  const handleSelectInstructionDay = useCallback((day, event) => {
    setInstructionHours((prev) => {
      const newState = { ...prev };
      const isSelected = event.target.checked;

      if (isSelected) {
        // When selecting a day, automatically add default time range
        newState[day] = {
          isSelected: true,
          value: [{ startTime: "09:00", endTime: "12:00" }],
        };
      } else {
        // When deselecting, clear the time ranges
        newState[day] = {
          isSelected: false,
          value: [],
        };
      }

      return newState;
    });
  }, []);

  const handleAddDayInstructionHour = useCallback((day) => {
    setInstructionHours((prev) => ({
      ...prev,
      [day]: {
        isSelected: true,
        value: [
          ...(prev[day]?.value || []),
          { startTime: "09:00", endTime: "12:00" },
        ],
      },
    }));
  }, []);

  const handleRemoveDayInstructionHour = useCallback((day, index) => {
    setInstructionHours((prev) => {
      const newValue = prev[day].value.filter((_, i) => i !== index);

      // If removing the last time range, deselect the day
      if (newValue.length === 0) {
        return {
          ...prev,
          [day]: {
            isSelected: false,
            value: [],
          },
        };
      }

      return {
        ...prev,
        [day]: {
          ...prev[day],
          value: newValue,
        },
      };
    });
  }, []);

  const handleChangeTime = useCallback((day, index, field, value) => {
    setInstructionHours((prev) => ({
      ...prev,
      [day]: {
        isSelected: true,
        value: (prev[day]?.value || []).map((hour, i) =>
          i === index ? { ...hour, [field]: value } : hour
        ),
      },
    }));
  }, []);

  const handleNext = useCallback(() => {
    const isValid = validateCurrentStep();
    if (isValid) {
      setActiveStep((prev) => prev + 1);
    }
  }, [validateCurrentStep]);

  const handleBack = useCallback(() => {
    setValidationErrors({});
    setActiveStep((prev) => prev - 1);
  }, []);

  // Reset form when modal opens/closes
  useEffect(() => {
    if (open) {
      let newValues = INITIAL_VALUES;
      let newHours = INITIAL_INSTRUCTION_HOURS;

      if (term) {
        newValues = {
          name: term.name || "",
          startDate: term.startDate || "",
          endDate: term.endDate || "",
        };

        if (term.instructionHours && term.instructionHours.hours) {
          const formattedHours = formatInstructionHours(
            term.instructionHours.hours
          );
          newHours = DAYS_OF_WEEK.reduce((acc, day) => {
            acc[day] = formattedHours[day] || { isSelected: false, value: [] };
            return acc;
          }, {});
        } else {
          newHours = DAYS_OF_WEEK.reduce((acc, day) => {
            acc[day] = { isSelected: false, value: [] };
            return acc;
          }, {});
        }
      }

      setValues(newValues);
      setInstructionHours(newHours);
      setInitialValues(newValues);
      setInitialInstructionHours(newHours);
      setActiveStep(0);
      setValidationErrors({});
    }
  }, [open, term]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="term-modal-title"
        aria-describedby="term-modal-description"
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "16px",
            maxHeight: "90vh",
          },
        }}
      >
        <DialogTitle
          id="dialog-title"
          sx={{
            bgcolor: "primary.main",
            color: "primary.contrastText",
            p: 2,
            fontSize: "1.25rem",
            fontWeight: 600,
          }}
        >
          {term?.id ? "Edit School Term" : "Create School Term"}
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <Wizard
            startIndex={activeStep}
            footer={
              <Footer
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
                handleCancel={onCancel}
                isNextDisabled={!isCurrentStepValid}
                activeStep={activeStep}
              />
            }
          >
            <Step1
              values={values}
              setValues={setValues}
              errors={validationErrors}
              setErrors={setValidationErrors}
            />
            <Step2
              instructionHours={instructionHours}
              handleSelectInstructionDay={handleSelectInstructionDay}
              handleAddDayInstructionHour={handleAddDayInstructionHour}
              handleChangeTime={handleChangeTime}
              handleRemoveDayInstructionHour={handleRemoveDayInstructionHour}
              validationErrors={validationErrors}
            />
          </Wizard>
        </DialogContent>
      </Dialog>

      <ConfirmationDialog
        open={showConfirmation}
        title="Discard Changes?"
        message="You have unsaved changes. Are you sure you want to close without saving?"
        confirmText="Discard"
        cancelText="Continue Editing"
        onConfirm={handleConfirmClose}
        onCancel={handleCancelClose}
      />
    </>
  );
};

export default SchoolTermModal;
