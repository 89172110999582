import api from "./api";

export const getCart = async () => {
  const response = await api.get("/api/v1/cart");
  return response.data;
};

export const addToCart = async (addToCartRequest) => {
  const response = await api.post("/api/v1/cart/add", addToCartRequest);
  return response.data;
};

export const removeFromCart = async (itemId) => {
  const response = await api.delete(`/api/v1/cart/remove/${itemId}`);
  return response.data;
};

export const updateCartItem = async (itemId, updateCartItemRequest) => {
  const response = await api.put(
    `/api/v1/cart/update/${itemId}`,
    updateCartItemRequest
  );
  return response.data;
};

export const clearCart = async () => {
  const response = await api.delete("/api/v1/cart");
  return response.data;
};
