import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearTimeoutMessage } from "../redux/authSlice";
import AppSnackbar from "../components/AppSnackBar";

export const ReportContext = React.createContext();

const defaultMessage = {
  text: "",
  severity: "info",
  flag: false,
};

export const ReportContextProvider = ({ children }) => {
  const [message, setMessage] = useState(defaultMessage);
  const [timeoutMessage, setTimeoutMessage] = useState(null);
  const dispatch = useDispatch();
  const hasTimeoutMessage = useSelector(
    (state) => state.auth.hasTimeoutMessage
  );

  const setMessageWithTimeout = useCallback(
    (newMessage) => {
      if (!hasTimeoutMessage) {
        setMessage({ ...defaultMessage, ...newMessage });
      }
    },
    [hasTimeoutMessage]
  );

  useEffect(() => {
    if (hasTimeoutMessage) {
      const newTimeoutMessage = {
        text: "You have been logged out due to inactivity.",
        severity: "warning",
        flag: true,
      };
      setTimeoutMessage(newTimeoutMessage);
      setMessage(defaultMessage); // Reset regular message
    }
  }, [hasTimeoutMessage]);

  useEffect(() => {
    let timeoutId;
    if (timeoutMessage) {
      timeoutId = setTimeout(() => {
        setTimeoutMessage(null);
        dispatch(clearTimeoutMessage());
      }, 5000); // 5-second display
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutMessage, dispatch]);

  const currentMessage = useMemo(() => {
    return timeoutMessage || message;
  }, [timeoutMessage, message]);

  const value = useMemo(
    () => ({
      message: currentMessage,
      setMessage: setMessageWithTimeout,
    }),
    [currentMessage, setMessageWithTimeout]
  );

  return (
    <ReportContext.Provider value={value}>
      {currentMessage.flag && <AppSnackbar message={currentMessage} />}
      {children}
    </ReportContext.Provider>
  );
};

export const useReportContext = () => {
  const context = useContext(ReportContext);
  if (!context) {
    throw new Error(
      "useReportContext must be used within a ReportContextProvider"
    );
  }
  return context;
};
