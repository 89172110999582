import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Typography,
  CircularProgress,
  List,
  ListItem,
  Alert,
  MenuItem,
  Container,
} from "@mui/material";
import {
  useGoogleMapsScript,
  getGoogleMapsPlaceDetails,
} from "../../hooks/useGoogleMaps";
import debounce from "lodash.debounce";
import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import { setShippingAddress } from "../../redux/checkoutSlice";

// Simple country code conversion function
export const getCountryCode = (countryName) => {
  const countryCodes = {
    "United States": "US",
    "United States of America": "US",
    Canada: "CA",
    Mexico: "MX",
    "United Kingdom": "GB",
    Australia: "AU",
    "New Zealand": "NZ",
    // Add more countries as needed
  };
  return countryCodes[countryName] || countryName;
};

const ShippingInformation = () => {
  const dispatch = useDispatch();
  const savedAddress = useSelector((state) => state.checkout.shippingAddress);
  const [address, setAddress] = useState({
    addressLine1: savedAddress?.street1 || "",
    addressLine2: savedAddress?.street2 || "",
    city: savedAddress?.city || "",
    stateProvince: savedAddress?.stateCode || "",
    postalCode: savedAddress?.postcode || "",
    country: savedAddress?.countryCode || "",
    phoneNumber: savedAddress?.phoneNumber || "",
    name: savedAddress?.name || "",
  });
  const [searchValue, setSearchValue] = useState(savedAddress?.street1 || "");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [userHasTyped, setUserHasTyped] = useState(false);

  const { isLoaded, loadError } = useGoogleMapsScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const isMounted = useRef(true);
  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      isMounted.current = false;
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const validateAddress = useCallback(() => {
    if (!address) return false;

    const requiredFields = [
      "addressLine1",
      "city",
      "stateProvince",
      "postalCode",
      "country",
      "phoneNumber",
    ];

    const isValid = requiredFields.every(
      (field) => address[field] && address[field].trim() !== ""
    );

    const isPhoneValid =
      address.phoneNumber && address.phoneNumber.trim() !== "" && !phoneError;

    return isValid && isPhoneValid;
  }, [address, phoneError]);

  useEffect(() => {
    if (validateAddress()) {
      const formattedAddress = {
        street1: address.addressLine1,
        street2: address.addressLine2 || "",
        city: address.city,
        stateCode: address.stateProvince,
        postcode: address.postalCode,
        countryCode: address.country || "US",
        phoneNumber: address.phoneNumber,
        name: address.name || "Customer",
        isBusiness: false,
      };

      console.log("Setting shipping address:", formattedAddress);
      dispatch(setShippingAddress(formattedAddress));
    } else {
      dispatch(setShippingAddress(null));
    }
  }, [address, validateAddress, dispatch]);

  const debouncedFetchSuggestions = useCallback(
    (input) => {
      const fetchSuggestions = debounce((input) => {
        if (
          isLoaded &&
          input &&
          !isAddressSelected &&
          isMounted.current &&
          userHasTyped
        ) {
          const autocompleteService =
            new window.google.maps.places.AutocompleteService();
          autocompleteService.getPlacePredictions(
            {
              input,
              types: ["address"],
              componentRestrictions: { country: "us" },
            },
            (predictions, status) => {
              if (!isMounted.current) return;
              if (
                status === window.google.maps.places.PlacesServiceStatus.OK &&
                predictions
              ) {
                setSuggestions(predictions);
                setShowSuggestions(true);
              } else {
                setSuggestions([]);
                setShowSuggestions(false);
              }
            }
          );
        } else {
          setSuggestions([]);
          setShowSuggestions(false);
        }
      }, 300);

      fetchSuggestions(input);
      return fetchSuggestions;
    },
    [isLoaded, isAddressSelected, userHasTyped]
  );

  useEffect(() => {
    let fetchSuggestionsFunc;
    if (
      isLoaded &&
      window.google &&
      window.google.maps &&
      window.google.maps.places
    ) {
      fetchSuggestionsFunc = debouncedFetchSuggestions(searchValue);
    }
    return () => {
      if (fetchSuggestionsFunc && fetchSuggestionsFunc.cancel) {
        fetchSuggestionsFunc.cancel();
      }
    };
  }, [searchValue, debouncedFetchSuggestions, isLoaded]);

  const handleSelect = async (placeId) => {
    if (
      !isLoaded ||
      !window.google ||
      !window.google.maps ||
      !window.google.maps.places
    )
      return;

    setIsAddressSelected(true);
    setUserHasTyped(false);

    try {
      const result = await getGoogleMapsPlaceDetails(placeId);
      const addressComponents = result.address_components;

      const streetNumber =
        addressComponents.find((c) => c.types.includes("street_number"))
          ?.long_name || "";
      const route =
        addressComponents.find((c) => c.types.includes("route"))?.long_name ||
        "";
      const fullStreetAddress = `${streetNumber} ${route}`.trim();

      const newAddress = {
        ...address,
        addressLine1: fullStreetAddress,
        addressLine2: "",
        city:
          addressComponents.find((c) => c.types.includes("locality"))
            ?.long_name || "",
        stateProvince:
          addressComponents.find((c) =>
            c.types.includes("administrative_area_level_1")
          )?.short_name || "",
        postalCode:
          addressComponents.find((c) => c.types.includes("postal_code"))
            ?.long_name || "",
        country: getCountryCode(
          addressComponents.find((c) => c.types.includes("country"))
            ?.long_name || ""
        ),
        name: address.name || "Customer",
        phoneNumber: address.phoneNumber,
      };

      setAddress(newAddress);
      setSearchValue(fullStreetAddress);
      setSuggestions([]);
      setShowSuggestions(false);

      const formattedAddress = {
        street1: newAddress.addressLine1,
        street2: newAddress.addressLine2 || "",
        city: newAddress.city,
        stateCode: newAddress.stateProvince,
        postcode: newAddress.postalCode,
        countryCode: newAddress.country,
        phoneNumber: newAddress.phoneNumber,
        name: newAddress.name || "Customer",
        isBusiness: false,
      };

      dispatch(setShippingAddress(formattedAddress));
    } catch (error) {
      console.error("Error fetching address details:", error);
    } finally {
      setIsAddressSelected(false);
    }
  };

  const handleChange = (field, value) => {
    if (field === "addressLine1") {
      setSearchValue(value);
      setUserHasTyped(true);
      if (value.trim() !== "") {
        setShowSuggestions(true);
      } else {
        setShowSuggestions(false);
      }
    }

    const newAddress = { ...address, [field]: value };
    setAddress(newAddress);

    if (field === "phoneNumber") {
      try {
        const phoneNumber = parsePhoneNumber(value, "US");
        if (phoneNumber && phoneNumber.isValid()) {
          newAddress.phoneNumber = phoneNumber.format("INTERNATIONAL");
          setPhoneError("");
        } else {
          setPhoneError("Invalid phone number");
        }
      } catch (error) {
        setPhoneError("Invalid phone number");
      }
    }

    // Format the address before dispatching to the store
    const formattedAddress = {
      street1: newAddress.addressLine1,
      street2: newAddress.addressLine2 || "",
      city: newAddress.city,
      stateCode: newAddress.stateProvince,
      postcode: newAddress.postalCode,
      countryCode: newAddress.country || "US",
      phoneNumber: newAddress.phoneNumber,
      name: newAddress.name || "Customer",
      isBusiness: false,
    };

    // Only dispatch if we have the minimum required fields
    if (
      formattedAddress.street1 &&
      formattedAddress.city &&
      formattedAddress.stateCode &&
      formattedAddress.postcode &&
      formattedAddress.phoneNumber
    ) {
      console.log("Dispatching formatted address:", formattedAddress);
      dispatch(setShippingAddress(formattedAddress));
    } else {
      dispatch(setShippingAddress(null));
    }
  };

  const handleAddressLine1Blur = () => {
    timeoutRef.current = setTimeout(() => {
      if (isMounted.current) {
        setShowSuggestions(false);
      }
    }, 200);
  };

  if (loadError) {
    console.error("Google Maps API load error:", loadError);
    return (
      <Alert severity="error">
        Error loading Google Maps API. Please try again later or contact
        support.
      </Alert>
    );
  }

  if (!isLoaded) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="md">
      <Box component="form" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Shipping Information
        </Typography>
        <TextField
          fullWidth
          label="Phone Number"
          value={address.phoneNumber}
          onChange={(e) => handleChange("phoneNumber", e.target.value)}
          margin="normal"
          required
          error={!!phoneError}
          helperText={phoneError}
        />
        <TextField
          fullWidth
          label="Address Line 1"
          value={searchValue}
          onChange={(e) => handleChange("addressLine1", e.target.value)}
          onFocus={() => {
            if (!isAddressSelected && userHasTyped) {
              setShowSuggestions(true);
            }
          }}
          onBlur={handleAddressLine1Blur}
          margin="normal"
          required
        />
        {showSuggestions &&
          suggestions.length > 0 &&
          !isAddressSelected &&
          userHasTyped && (
            <List>
              {suggestions.map((suggestion) => (
                <ListItem
                  button
                  key={suggestion.place_id}
                  onClick={() => handleSelect(suggestion.place_id)}
                  onMouseDown={(e) => e.preventDefault()} // Prevent onBlur from firing before onClick
                >
                  {suggestion.description}
                </ListItem>
              ))}
            </List>
          )}
        <TextField
          fullWidth
          label="Address Line 2 (Optional)"
          value={address.addressLine2}
          onChange={(e) => handleChange("addressLine2", e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="City"
          value={address.city}
          onChange={(e) => handleChange("city", e.target.value)}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="State/Province"
          value={address.stateProvince}
          onChange={(e) => handleChange("stateProvince", e.target.value)}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Postal Code"
          value={address.postalCode}
          onChange={(e) => handleChange("postalCode", e.target.value)}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          select
          label="Country"
          value={address.country}
          onChange={(e) => handleChange("country", e.target.value)}
          margin="normal"
          required
        >
          <MenuItem value="US">United States</MenuItem>
          <MenuItem value="CA">Canada</MenuItem>
          {/* Add more countries as needed */}
        </TextField>
      </Box>
    </Container>
  );
};

export default ShippingInformation;
