import axios from "axios";
import apiUrl from "../config/config.js";
import { parseJwt } from "../utils/utils";
import store from "../redux/store.js";
import AuthManager from "./AuthManager.js";
import { setNetworkError } from "../redux/networkSlice";
import axiosRetry from "axios-retry";
import { debug } from "../utils/debug";

export const doPerformRefresh = async (newToken) => {
  try {
    let token = newToken;
    if (!token) {
      // If no new token is provided, use the existing token from the store
      const state = store.getState();
      token = state.auth.token;
    }

    const response = await api.post(
      `${apiUrl}/api/v1/auth/refresh`,
      {},
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
      }
    );

    const responseData = response.data;
    const authData = responseData.data || responseData;

    const {
      token: refreshedToken,
      user,
      accounts,
      showQuickStart,
      calendarConnections,
    } = authData;

    const decodedToken = parseJwt(refreshedToken);
    const loggedInUserRoles = decodedToken.roles || [];

    // Process calendar connections properly
    const processedCalendarConnections = {};
    if (calendarConnections?.GOOGLE) {
      const googleStatus = calendarConnections.GOOGLE;
      processedCalendarConnections.GOOGLE = {
        connected: googleStatus.connected || false,
        needsReconnect: googleStatus.needsReconnect || false,
        defaultSyncCalendarId: googleStatus.defaultSyncCalendarId || null,
      };
    }

    debug.auth.log("Token refresh successful", {
      hasCalendarConnections: !!calendarConnections?.GOOGLE,
    });

    return {
      token: refreshedToken,
      loggedInUser: user || {},
      loggedInUserRoles,
      loggedInUserAccounts: accounts || [],
      showQuickStart,
      calendarConnections: processedCalendarConnections,
      googleCalendarConnected:
        processedCalendarConnections.GOOGLE?.connected || false,
      reconnectGoogleCalendar:
        processedCalendarConnections.GOOGLE?.needsReconnect || false,
      defaultSyncCalendarId:
        processedCalendarConnections.GOOGLE?.defaultSyncCalendarId || null,
    };
  } catch (error) {
    debug.auth.error("Refresh error:", error);
    AuthManager.handleAuthError(error);
    throw error;
  }
};

export const api = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  async (config) => {
    const state = store.getState();
    const { token } = state.auth;
    const isAuthRequest = config.url.includes("/api/v1/auth/");

    if (!token && !isAuthRequest) {
      await AuthManager.logout();
      return config;
    }

    if (!isAuthRequest) {
      try {
        const freshToken = await AuthManager.refreshTokenIfNeeded();
        if (freshToken) {
          config.headers.Authorization = `Bearer ${freshToken}`;
        }
      } catch (error) {
        console.error("Token refresh failed:", error);
        AuthManager.handleAuthError(error);
        throw error;
      }
    }

    store.dispatch(setNetworkError({ isError: false, message: null }));
    return config;
  },
  (error) => {
    debug.api.error("Request interceptor error:", error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const requestConfig = error.config;

    // Extract meaningful path from URL for logging
    const path = requestConfig.url.replace(apiUrl, "");
    const method = requestConfig.method.toUpperCase();

    // Log based on error type
    if (!error.response) {
      debug.network.error(`Network error on ${method} ${path}:`, error);
      // Maintain existing network error message format
      store.dispatch(
        setNetworkError({
          isError: true,
          message:
            "Unable to connect to the server. Please check your internet connection and try again.",
        })
      );
      // Mark as network error for the error boundary without removing existing functionality
      error.isNetworkError = true;
    } else {
      // Get feature namespace from URL path
      const feature = path.split("/")[2]; // e.g., 'auth', 'user', 'calendar'
      const namespace = debug[feature] || debug.api;

      namespace.error(
        `API Error (${error.response.status}) on ${method} ${path}:`,
        error.response.data
      );
    }

    // Handle authentication errors (maintaining existing behavior)
    if (
      error.response &&
      error.response.status === 401 &&
      !error.config.url.includes("/api/v1/auth/")
    ) {
      debug.api.error("Unauthorized request:", error);
      AuthManager.handleAuthError(error);
    }

    // Maintain existing plan restriction handling
    if (
      error.response?.status === 403 &&
      error.response?.data?.error === "Plan Restriction"
    ) {
      error.isPlanRestriction = true;
    }

    return Promise.reject(error);
  }
);

// Configure retry behavior
axiosRetry(api, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    // Retry on network errors and 5xx errors
    return (
      axiosRetry.isNetworkOrIdempotentRequestError(error) ||
      (error.response && error.response.status >= 500)
    );
  },
});

export default api;
