import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Paper,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import StarIcon from "@mui/icons-material/Star";
import { loadStripe } from "@stripe/stripe-js";
import { createCheckoutSession } from "../api/paymentApi";
import { CONFIG } from "../config/config";

const stripePromise = loadStripe(CONFIG.STRIPE_PUBLISHABLE_KEY);

const EmptySubscriptionView = () => {
  const { selectedAccountId } = useSelector((state) => state.auth);
  const [loadingPlan, setLoadingPlan] = useState(null);
  const theme = useTheme();

  const plans = [
    {
      type: "BASIC",
      title: "Basic Plan",
      price: "$45/year",
      features: [
        "1 student",
        "Unlimited school terms",
        "Unlimited lesson plans",
        "Full feature access",
        "Priority support",
      ],
    },
    {
      type: "FAMILY",
      title: "Family Plan",
      price: "$90/year",
      features: [
        "Up to 10 students",
        "Unlimited school terms",
        "Unlimited lesson plans",
        "Full feature access",
        "Priority support",
        "Family calendar view",
      ],
    },
  ];

  const handleStartTrial = async (planType) => {
    setLoadingPlan(planType);

    try {
      const { sessionId } = await createCheckoutSession(
        planType,
        selectedAccountId
      );
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error("Error creating checkout session:", error);
    } finally {
      setLoadingPlan(null);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "1200px",
        mx: "auto",
        p: { xs: 2, sm: 4 },
        mt: 4,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          p: 4,
          borderRadius: 2,
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Box textAlign="center" mb={6}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "bold",
              mb: 2,
            }}
          >
            Upgrade Your Homeschool Experience
          </Typography>
          <Typography variant="h6" color="text.secondary" sx={{ mb: 3 }}>
            Start your 30-day free trial today and unlock the full potential of
            Homeschool Planner
          </Typography>
          <Divider sx={{ my: 4 }} />
        </Box>

        <Grid container spacing={4} justifyContent="center">
          {plans.map((plan) => (
            <Grid item xs={12} md={6} key={plan.type}>
              <Paper
                elevation={2}
                sx={{
                  p: 4,
                  height: "100%",
                  borderRadius: 2,
                  display: "flex",
                  flexDirection: "column",
                  border:
                    plan.type === loadingPlan
                      ? `2px solid ${theme.palette.primary.main}`
                      : "none",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    transition: "transform 0.3s ease-in-out",
                  },
                }}
              >
                <Typography variant="h5" gutterBottom fontWeight="bold">
                  {plan.title}
                </Typography>
                <Typography
                  variant="h4"
                  color="primary"
                  gutterBottom
                  sx={{ mb: 3 }}
                >
                  {plan.price}
                </Typography>
                <List sx={{ mb: 4, flexGrow: 1 }}>
                  {plan.features.map((feature, index) => (
                    <ListItem key={index} sx={{ py: 1 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <StarIcon color="primary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={feature} />
                    </ListItem>
                  ))}
                </List>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={() => handleStartTrial(plan.type)}
                  disabled={loadingPlan !== null}
                  sx={{
                    py: 2,
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                  }}
                >
                  {loadingPlan === plan.type ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Start 30-Day Free Trial"
                  )}
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 6, textAlign: "center" }}>
          <Typography variant="body2" color="text.secondary">
            Cancel anytime during your trial period.
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default EmptySubscriptionView;
