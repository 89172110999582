import React from "react";
import { Typography, Box, Button } from "@mui/material";

const CallToAction = () => (
  <Box
    sx={{
      backgroundColor: "#FFF2CC",
      p: 4,
      borderRadius: 2,
      textAlign: "center",
      my: 4,
      position: "relative",
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: -10,
        width: 20,
        height: 20,
        backgroundColor: "#FFD966",
        borderRadius: "50%",
      },
      "&::before": { left: 20 },
      "&::after": { right: 20 },
    }}
  >
    <Typography variant="h2" gutterBottom>
      Simplify Your Homeschooling Journey Today
    </Typography>
    <Typography variant="body1" paragraph>
      Manage your daily schedule and track progress with our intuitive
      homeschooling platform.
    </Typography>
    <Button variant="darkRounded" color="primary" size="large">
      Get Started
    </Button>
  </Box>
);

export default CallToAction;
