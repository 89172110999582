import React from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  Container,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useTheme } from "@mui/material/styles";

const FeatureCard = ({ title, description, icon, color }) => {
  const theme = useTheme();
  const backgroundColor = theme.palette[color] || color;

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: backgroundColor,
        boxShadow: "none",
        borderRadius: 0,
      }}
    >
      <CardContent sx={{ flexGrow: 1, p: 3, textAlign: "center" }}>
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "50%",
            width: 60,
            height: 60,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto 16px",
          }}
        >
          {icon}
        </Box>
        <Typography variant="h5" component="div" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </CardContent>
    </Card>
  );
};

const GetStarted = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        my: 4,
        py: 4,
        backgroundColor: theme.palette.background.gray,
        borderRadius: 2,
        border: "2px solid #ccc",
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="h2" align="center" gutterBottom>
          Get Started With Our User-Friendly Platform
        </Typography>
        <Typography variant="body1" align="center" paragraph sx={{ mb: 4 }}>
          Our platform provides a step-by-step guide on how to manage your
          homeschooling schedule and track your child's progress through their
          lesson plans. With easy-to-use features and intuitive design, you can
          focus on what matters most - your child's education.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <FeatureCard
              title="Interactive Scheduling"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              icon={<CalendarTodayIcon />}
              color="yellow.light"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FeatureCard
              title="Progress Tracking"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              icon={<AccessTimeIcon />}
              color="primary.light"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FeatureCard
              title="Lesson Plan Management"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              icon={<AssignmentIcon />}
              color="yellow.light"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default GetStarted;
