import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { Box, Typography, Grid, Stack, Select, MenuItem } from "@mui/material";
import { useReportContext } from "../../contexts/report-context";
import { ROLE_STUDENT, getUsers } from "../../api/userApi";
import { getChecklist, updateCalendarEvent } from "../../api/calendarApi";
import LoginFlowModal from "../../components/modals/LoginFlowModal";
import EmptySubscriptionView from "../../components/EmptySubscriptionView";
import { useLogin } from "../../hooks/useLogin";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import WeekNavigation from "./WeekNavigation";
import FilterControls from "./FilterControls";
import ChecklistTable from "./ChecklistTable";
import { setAuthInfo } from "../../redux/authSlice";
import ChecklistView from "./ChecklistView";

const Home = () => {
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const planType = useSelector((state) => state.auth.planType);
  const loggedInUser = useSelector((state) => state.auth.loggedInUser);
  const loggedInUserAccounts = useSelector(
    (state) => state.auth.loggedInUserAccounts
  );
  const showQuickStart = useSelector((state) => state.auth.showQuickStart);
  const { setMessage } = useReportContext();
  const dispatch = useDispatch();

  const authResponse = useMemo(
    () => ({
      loggedInUser,
      loggedInUserAccounts,
      showQuickStart,
    }),
    [loggedInUser, loggedInUserAccounts, showQuickStart]
  );

  // Don't render anything until auth is ready
  if (!loggedInUser) {
    return null;
  }

  if (!planType || planType === "NONE") {
    return (
      <Box sx={{ width: "100%", height: "100%" }}>
        <EmptySubscriptionView />
        <LoginFlowModal
          authResponse={authResponse}
          setAuthResponse={(newAuthResponse) => {
            dispatch(setAuthInfo(newAuthResponse));
          }}
        />
      </Box>
    );
  }

  return (
    <Box>
      <ChecklistView
        accountId={selectedAccountId}
        onError={(error) => {
          if (error.code !== "ERR_CANCELED") {
            setMessage({
              text: error.message || "Failed to fetch checklist",
              severity: "error",
              flag: true,
            });
          }
        }}
      />
    </Box>
  );
};

export default Home;
