import React, {
  useState,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import { WizardContext } from "../../contexts/wizard-context";
import { PlannerWizardContext } from "../../contexts/planner-wizard-context";
import { QuickstartWizardContext } from "../../contexts/quickstart-wizard-context";
import { debug } from "../../utils/debug";

const HsWizard = ({
  open,
  handleClose,
  title,
  steps,
  onComplete,
  maxVisibleSteps = steps.length,
  finishButtonText = "Add to Cart",
  loading = false,
  error = null,
  setError,
  contextType,
}) => {
  // Log props immediately when component receives them
  debug.wizard.state("HsWizard props received:", {
    rawContextType: contextType,
    defaultValue: "default",
    isExactlyPlanner: contextType === "planner",
    typeofContextType: typeof contextType,
    propsObject: { open, steps, contextType },
    componentInstance: this?.constructor?.name,
    parentContext: useContext(PlannerWizardContext),
  });

  debug.wizard.log("Props:", {
    open,
    steps,
    contextType,
    receivedContextType: contextType,
    defaultContextType: "default",
    isContextTypePlanner: contextType === "planner",
  });

  const contextValue = useContext(
    contextType === "planner"
      ? PlannerWizardContext
      : contextType === "quickstart"
      ? QuickstartWizardContext
      : WizardContext
  );
  debug.wizard.state("Context selection:", {
    contextType,
    usingPlannerContext: contextType === "planner",
    hasContextValue: Boolean(contextValue),
    contextKeys: contextValue ? Object.keys(contextValue) : [],
  });

  const wizardState = contextValue;

  useEffect(() => {
    if (!wizardState) {
      debug.wizard.state("HsWizard: Waiting for wizardState");
      return;
    }
    debug.wizard.state("HsWizard - State received:", {
      activeStep: wizardState.activeStep,
      stepValidation: wizardState.stepValidation,
      hasWizardData: !!wizardState.wizardData,
      hasValidateStep: !!wizardState.validateStep,
    });
  }, [wizardState]);

  // Destructure wizardState early to access all needed properties
  const {
    activeStep = 0,
    handleStepChange,
    wizardData = {},
    updateWizardData = () => {},
    updateStepValidation = () => {},
    stepValidation = {},
    isSubmitting: stateIsSubmitting = false,
    validateStep,
  } = wizardState;

  // Initialize state if not provided
  const [internalWizardData, setInternalWizardData] = useState(wizardData);
  const [internalStepValidation, setInternalStepValidation] =
    useState(stepValidation);

  const handleUpdateWizardData = useCallback(
    (data) => {
      debug.wizard.state("Updating internal wizard data:", data);
      setInternalWizardData((prev) => ({ ...prev, ...data }));
      updateWizardData(data);
    },
    [updateWizardData]
  );

  const handleUpdateStepValidation = useCallback(
    (stepIndex, isValid) => {
      debug.wizard.state("Updating internal step validation:", {
        stepIndex,
        isValid,
      });
      setInternalStepValidation((prev) => ({ ...prev, [stepIndex]: isValid }));
      updateStepValidation(stepIndex, isValid);
    },
    [updateStepValidation]
  );

  // Use internal or external state
  const effectiveWizardData = wizardData || internalWizardData;
  const effectiveStepValidation = stepValidation || internalStepValidation;

  debug.wizard.state("Using wizard state:", {
    activeStep,
    wizardData: effectiveWizardData,
    stepValidation: effectiveStepValidation,
    isSubmitting: stateIsSubmitting,
  });

  // Wrap wizardSteps in useMemo with better validation
  const wizardSteps = useMemo(() => {
    debug.wizard.log("Computing wizardSteps:", {
      steps,
      contextSteps: wizardState.steps,
    });

    const stepsToUse = steps || wizardState.steps;
    if (!stepsToUse || !Array.isArray(stepsToUse)) {
      console.error("HsWizard: Invalid steps provided", { steps, wizardState });
      return [];
    }
    return stepsToUse;
  }, [steps, wizardState.steps]);

  debug.wizard.log("HsWizard - Computed wizardSteps:", wizardSteps);

  // Wrap validation in useMemo
  const isValidSteps = useMemo(() => {
    return wizardSteps.length > 0 && wizardSteps.every((step) => step != null);
  }, [wizardSteps]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [internalError, setInternalError] = useState(null);

  // Calculate safe active step early
  const safeActiveStep = useMemo(() => {
    if (!isValidSteps || !wizardSteps.length) return 0;
    return Math.min(
      Math.max(0, activeStep),
      Math.max(0, wizardSteps.length - 1)
    );
  }, [activeStep, isValidSteps, wizardSteps]);

  const handleNext = async () => {
    debug.wizard.state("Handling next button:", {
      currentStep: wizardSteps[activeStep],
      hasRef: Boolean(wizardSteps[activeStep]?.ref?.current),
      hasHandleNext: Boolean(wizardSteps[activeStep]?.ref?.current?.handleNext),
    });

    // If step has a handleNext function, call it
    if (wizardSteps[activeStep]?.ref?.current?.handleNext) {
      const canProceed = await wizardSteps[activeStep].ref.current.handleNext();

      debug.wizard.state("Step handleNext result:", { canProceed });

      if (!canProceed) {
        return;
      }
    }

    if (activeStep === wizardSteps.length - 1) {
      onComplete?.(wizardData);
    } else {
      handleStepChange("next");
    }
  };

  const handleBack = useCallback(() => {
    const currentStep = wizardSteps[activeStep];
    debug.wizard.state("Handling back button:", {
      currentStep,
      hasRef: Boolean(currentStep?.ref?.current),
      hasHandleBack: Boolean(currentStep?.ref?.current?.handleBack),
    });

    // Check if current step has a handleBack method
    if (currentStep?.ref?.current?.handleBack) {
      const canProceed = currentStep.ref.current.handleBack();
      debug.wizard.state("Back handler result:", { canProceed });
      if (!canProceed) {
        return;
      }
    }

    handleStepChange("back");
    if (setError) {
      setError(null);
    }
  }, [activeStep, wizardSteps, handleStepChange, setError]);

  // Move getStepContent into useCallback
  const getStepContent = useCallback(
    (step, index) => {
      if (!step) return null;
      if (step.component) {
        const Component = step.component;
        return (
          <Component
            ref={step.ref}
            {...step.props}
            wizardData={effectiveWizardData}
            updateWizardData={handleUpdateWizardData}
            updateStepValidation={handleUpdateStepValidation}
            stepIndex={index}
            stepValidation={effectiveStepValidation}
            validateStep={validateStep}
          />
        );
      }
      return step.content;
    },
    [
      effectiveWizardData,
      handleUpdateWizardData,
      handleUpdateStepValidation,
      effectiveStepValidation,
      validateStep,
    ]
  );

  // Update the currentStepContent useMemo to use memoized getStepContent
  const currentStepContent = useMemo(() => {
    const currentStep = wizardSteps[safeActiveStep];
    return getStepContent(currentStep, safeActiveStep);
  }, [wizardSteps, safeActiveStep, getStepContent]);

  // Update isStepValid to include validateStep in dependencies
  const isStepValid = useCallback(
    (step, stepIndex) => {
      debug.wizard.state("Step validation check:", {
        stepIndex,
        stepId: step?.id,
        hasRef: Boolean(step?.ref?.current),
        hasIsValid: Boolean(step?.ref?.current?.isValid),
        stepValidation: effectiveStepValidation[stepIndex],
      });

      if (!step) return false;

      let isValid = false;
      let validationSource = "";

      // Check ref.isValid first
      if (step.ref?.current?.isValid) {
        isValid = step.ref.current.isValid();
        validationSource = "ref.isValid";
      }
      // Then check step.isValid
      else if (typeof step.isValid === "function") {
        isValid = step.isValid(effectiveWizardData);
        validationSource = "step.isValid";
      }
      // Finally fall back to stepValidation
      else {
        isValid = effectiveStepValidation[stepIndex] ?? false;
        validationSource = "stepValidation";
      }

      debug.wizard.state("Step validation result:", {
        stepIndex,
        stepId: step?.id,
        isValid,
        validationSource,
      });

      return isValid;
    },
    [effectiveStepValidation, effectiveWizardData]
  );

  // Add debug logging for next button state
  const isNextButtonDisabled = useMemo(() => {
    const currentStep = wizardSteps[safeActiveStep];
    const hasCurrentStep = Boolean(currentStep);
    const isLoading = loading || stateIsSubmitting;
    const isValid = isStepValid(currentStep, safeActiveStep);

    debug.wizard.log("Next button state:", {
      isNextButtonDisabled: !hasCurrentStep || isLoading || !isValid,
      hasCurrentStep,
      isLoading,
      isValid,
      currentStep,
      safeActiveStep,
    });

    return !hasCurrentStep || isLoading || !isValid;
  }, [wizardSteps, safeActiveStep, loading, stateIsSubmitting, isStepValid]);

  // Early returns with better error messages
  if (!open) return null;

  if (!isValidSteps) {
    console.error("HsWizard: No valid steps available", {
      steps,
      wizardState,
      wizardSteps,
    });
    return null;
  }

  const currentStep = wizardSteps[safeActiveStep];

  if (!currentStep) {
    console.error("HsWizard: invalid step at index", {
      safeActiveStep,
      wizardSteps,
    });
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: "90vh",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            width: "100%",
            mt: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Stepper activeStep={safeActiveStep} alternativeLabel sx={{ mb: 4 }}>
            {wizardSteps.map((step, index) => (
              <Step key={step.id || index}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {(error || internalError) && (
            <Typography color="error" sx={{ mb: 2 }}>
              {error || internalError}
            </Typography>
          )}

          <Box sx={{ flex: 1 }}>{currentStepContent}</Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "auto",
              pt: 2,
            }}
          >
            <Button
              onClick={handleBack}
              disabled={safeActiveStep === 0 || loading || isSubmitting}
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={isNextButtonDisabled}
            >
              {loading || isSubmitting ? (
                <CircularProgress size={24} />
              ) : safeActiveStep === wizardSteps.length - 1 ? (
                finishButtonText
              ) : (
                "Next"
              )}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default HsWizard;
