import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Container,
} from "@mui/material";
import {
  setShippingOption,
  setShippingOptions,
} from "../../redux/checkoutSlice";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { printedPlannerApi } from "../../api/printedPlannerApi";

const ShippingOptions = () => {
  const dispatch = useDispatch();
  const { shippingAddress, shippingOption, shippingOptions } = useSelector(
    (state) => state.checkout
  );
  const cartItems = useSelector((state) => state.cart.items);

  const {
    execute: fetchShippingOptions,
    loading,
    error,
  } = useAsyncOperation(printedPlannerApi.getShippingOptions);

  useEffect(() => {
    const getShippingOptions = async () => {
      console.log("Shipping Address:", shippingAddress);
      console.log("Cart Items:", cartItems);

      if (shippingAddress && cartItems && cartItems.length > 0) {
        try {
          const formattedAddress = {
            city: shippingAddress.city,
            countryCode: shippingAddress.countryCode,
            isBusiness: shippingAddress.isBusiness || false,
            name: shippingAddress.name || "Customer",
            phoneNumber: shippingAddress.phoneNumber,
            postcode: shippingAddress.postcode,
            stateCode: shippingAddress.stateCode,
            street1: shippingAddress.street1,
            street2: shippingAddress.street2 || "",
          };

          const formattedItems = cartItems.map((item) => ({
            productId: item.productId,
            quantity: item.quantity,
            plannerOptions: item.plannerOptions,
            reportId: item.reportId || null,
            numberOfPages: item.numberOfPages || null,
          }));

          console.log("Formatted Request:", {
            shippingAddress: formattedAddress,
            items: formattedItems,
          });

          const options = await fetchShippingOptions({
            shippingAddress: formattedAddress,
            items: formattedItems,
          });

          console.log("Received Shipping Options:", options);

          if (options && Array.isArray(options)) {
            const sortedOptions = options.sort((a, b) => {
              if (a.cost !== b.cost) {
                return a.cost - b.cost;
              }
              return a.daysMin - b.daysMin;
            });

            dispatch(setShippingOptions(sortedOptions));
          } else {
            console.error("Invalid shipping options response:", options);
          }
        } catch (error) {
          console.error("Error fetching shipping options:", error);
        }
      } else {
        console.log("Missing required data for shipping options fetch");
        dispatch(setShippingOptions([]));
      }
    };

    getShippingOptions();
  }, [shippingAddress, cartItems, fetchShippingOptions, dispatch]);

  const handleOptionSelect = (event) => {
    dispatch(setShippingOption(event.target.value));
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" my={4}>
        Error loading shipping options: {error.message}
      </Typography>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Select Shipping Option
        </Typography>
        {!shippingOptions || shippingOptions.length === 0 ? (
          <Typography>No shipping options available</Typography>
        ) : (
          <RadioGroup value={shippingOption} onChange={handleOptionSelect}>
            {shippingOptions.map((option) => (
              <FormControlLabel
                key={option.name}
                value={option.name}
                control={<Radio />}
                label={`${formatOptionName(
                  option.name
                )} - $${option.cost.toFixed(2)} (${option.daysMin}-${
                  option.daysMax
                } days)`}
              />
            ))}
          </RadioGroup>
        )}
      </Box>
    </Container>
  );
};

const formatOptionName = (name) => name.replace(/_/g, " ");

export default ShippingOptions;
