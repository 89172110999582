import * as Yup from "yup";

// Phone regex that accepts:
// - US formats: (123) 456-7890, 123-456-7890, 123.456.7890, 1234567890
// - International formats: +1 123-456-7890, +44 20 7123 4567, etc.
const phoneRegExp =
  /^(\+?\d{1,4}[-.\s]?)?(\(?\d{1,}\)?[-.\s]?)?(\d{1,}[-.\s]?)*\d{1,}$/;

export function validateEmail(email) {
  if (!email) {
    return false;
  }
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
}

export const profileValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, {
      message: "Please enter a valid phone number",
      excludeEmptyString: true, // Allow empty string (optional phone number)
    })
    .min(10, "Phone number is too short")
    .max(20, "Phone number is too long"),
  timezone: Yup.string().required("Timezone is required"),
});

export const createCalendarEventValidationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string(),
  startTime: Yup.date().required("Start time is required"),
  endTime: Yup.date()
    .required("End time is required")
    .min(Yup.ref("startTime"), "End time must be after start time"),
  type: Yup.string().oneOf(["USER", "USER_BLOCK"]).default("USER"),
  termId: Yup.string(),
  rebalance: Yup.boolean().when("termId", {
    is: (val) => val && val.length > 0,
    then: () => Yup.boolean(),
    otherwise: () => Yup.boolean().oneOf([false]),
  }),
});

export const createClassValidationSchema = Yup.object({
  name: Yup.string().required("Title is required"),
  subject: Yup.string().nullable(),
  description: Yup.string().nullable(),
  durationPerLesson: Yup.number()
    .required("Duration is required")
    .min(1, "Duration must be at least 1 minute"),
  numberOfLessonsType: Yup.string()
    .required()
    .oneOf(["Ongoing", "Fixed Number"]),
  lessonFrequency: Yup.string().required("Frequency is required"),
  numberOfLessons: Yup.number().when("numberOfLessonsType", {
    is: "Fixed Number",
    then: (schema) =>
      schema
        .required("Number of lessons is required")
        .min(1, "Must have at least 1 lesson"),
    otherwise: (schema) => schema.nullable(),
  }),
});

export const createLessonValidationSchema = Yup.object({
  title: Yup.string().required(),
  description: Yup.string(),
  workItems: Yup.array().of(
    Yup.object({
      description: Yup.string().required(),
    })
  ),
});

export const termValidationSchema = Yup.object({
  name: Yup.string().required(),
  startDate: Yup.string().required(),
  endDate: Yup.string().required(),
});

export const enrollUserValidationSchema = Yup.object({
  termId: Yup.string().required(),
  studentId: Yup.string().required(),
});

export const enrollStudentValidationSchema = Yup.object({
  termId: Yup.string().required(),
  lessonPlanId: Yup.string().required(),
});

export const registrationValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First name must be at least 2 characters")
    .max(50, "First name must be less than 50 characters")
    .required("First name is required"),
  lastName: Yup.string()
    .min(2, "Last name must be at least 2 characters")
    .max(50, "Last name must be less than 50 characters")
    .required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});
