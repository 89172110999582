import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TimezoneSelectorModal from "./TimezoneSelectorModal";
import AccountSelectorModal from "./AccountSelectorModal";
import SubscriptionModal from "./SubscriptionModal";
import { updateUserTimezone } from "../../api/userApi";
import { useReportContext } from "../../contexts/report-context";
import { createCheckoutSession } from "../../api/paymentApi";
import { loadStripe } from "@stripe/stripe-js";
import QuickstartWizardModal from "../quickstart";
import { CONFIG } from "../../config/config";

const stripePromise = loadStripe(CONFIG.STRIPE_PUBLISHABLE_KEY);

const STEPS = {
  TIMEZONE_SELECTOR: "timezone",
  ACCOUNT_SELECTOR: "account",
  PLAN_TYPE: "planType",
  QUICKSTART: "quickstart",
  HOME: "home",
};

const LoginFlowModal = ({ authResponse, setAuthResponse, onError }) => {
  const [preselectedPlanType, setPreselectedPlanType] = useState(null);

  useEffect(() => {
    // Read the preselected plan type from localStorage
    const storedPlanType = localStorage.getItem("preselectedPlanType");
    if (storedPlanType) {
      setPreselectedPlanType(storedPlanType);
    }
  }, []);

  const [currentStep, setCurrentStep] = useState(null);
  const dispatch = useDispatch();
  const { setMessage } = useReportContext();
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const [openQuickstartWizardModal, setOpenQuickstartWizardModal] =
    useState(false);
  let [searchParams] = useSearchParams();

  const determineNextStep = useCallback(() => {
    // Add debug logging
    console.log("LoginFlowModal - determineNextStep:", {
      hasAuthResponse: !!authResponse,
      loggedInUser: authResponse?.loggedInUser,
      timezone: authResponse?.loggedInUser?.timezone,
      accounts: authResponse?.loggedInUserAccounts,
      currentStep,
    });

    if (!authResponse?.loggedInUser) return null;

    // Check if timezone needs to be set
    if (!authResponse.loggedInUser.timezone) {
      console.log("LoginFlowModal - No timezone set, should show selector");
      return STEPS.TIMEZONE_SELECTOR;
    }

    // Check if account selection is needed
    if (authResponse.loggedInUserAccounts?.length > 1)
      return STEPS.ACCOUNT_SELECTOR;

    // Check if plan type selection is needed - only if no accounts have a valid plan
    // AND there's no preselected plan type
    if (
      !preselectedPlanType &&
      authResponse.loggedInUserAccounts?.length > 0 &&
      !authResponse.loggedInUserAccounts.some((account) =>
        ["BASIC", "FAMILY", "FREE", "NONE"].includes(account.planType)
      )
    )
      return STEPS.PLAN_TYPE;

    // Check if quickstart wizard should be shown
    if (authResponse.showQuickStart) return STEPS.QUICKSTART;

    return STEPS.HOME;
  }, [authResponse, currentStep, preselectedPlanType]);

  useEffect(() => {
    const nextStep = determineNextStep();
    console.log("LoginFlowModal - Setting current step:", {
      nextStep,
      currentStep,
      isOpen: currentStep === STEPS.TIMEZONE_SELECTOR,
    });
    setCurrentStep(nextStep);
  }, [authResponse, determineNextStep]);

  const handlePlanTypeSelected = useCallback(
    async (planType) => {
      if (planType) {
        try {
          const { sessionId } = await createCheckoutSession(
            planType,
            selectedAccountId
          );
          const stripe = await stripePromise;
          const { error } = await stripe.redirectToCheckout({ sessionId });

          if (error) {
            setMessage({
              text: "There was an error redirecting to the payment page. Please try again.",
              severity: "error",
              flag: true,
            });
          }
        } catch (error) {
          setMessage({
            text: "There was an error creating the checkout session. Please try again.",
            severity: "error",
            flag: true,
          });
        }
      }
      setCurrentStep(STEPS.HOME);
    },
    [selectedAccountId, setMessage, setCurrentStep]
  );

  useEffect(() => {
    if (preselectedPlanType && currentStep === STEPS.PLAN_TYPE) {
      handlePlanTypeSelected(preselectedPlanType);
    }
  }, [preselectedPlanType, currentStep, handlePlanTypeSelected]);

  useEffect(() => {
    if (searchParams.get("showQuickStart") === "true") {
      setOpenQuickstartWizardModal(true);
    }
  }, [searchParams]);

  const handleTimezoneSelected = async (timezone) => {
    try {
      // Get the user ID from the auth response
      const userId = authResponse.loggedInUser.id;

      // Call updateUserTimezone and get the updated user data
      const updatedUser = await updateUserTimezone(userId, timezone);

      // Update the auth response with the new user data
      setAuthResponse({
        ...authResponse,
        loggedInUser: {
          ...authResponse.loggedInUser,
          timezone: updatedUser.timezone,
        },
      });

      // Move to next step after successful timezone update
      setCurrentStep(determineNextStep());
    } catch (error) {
      console.error("Failed to update timezone:", error);
      setMessage({
        text: "Failed to set timezone. Please try again.",
        severity: "error",
        flag: true,
      });
      // Stay on timezone selector if update fails
    }
  };

  const handleAccountSelected = (accountId) => {
    dispatch({ selectedAccountId: accountId });
  };

  const handleCloseModal = () => {
    // Update authResponse to mark quickstart as completed
    setAuthResponse({
      ...authResponse,
      showQuickStart: false,
    });
    setOpenQuickstartWizardModal(false);
    setCurrentStep(STEPS.HOME);
  };

  return (
    <>
      {console.log("LoginFlowModal - Rendering:", {
        currentStep,
        isTimezoneSelectorOpen: currentStep === STEPS.TIMEZONE_SELECTOR,
      })}
      <TimezoneSelectorModal
        isOpen={currentStep === STEPS.TIMEZONE_SELECTOR}
        handleClose={() => null}
        handleConfirmTimezone={handleTimezoneSelected}
        dismissable={false}
      />
      <AccountSelectorModal
        authResponse={authResponse}
        isOpen={currentStep === STEPS.ACCOUNT_SELECTOR}
        handleConfirmAccount={handleAccountSelected}
        dismissable={false}
      />
      {!preselectedPlanType && (
        <SubscriptionModal
          open={currentStep === STEPS.PLAN_TYPE}
          handleClose={handlePlanTypeSelected}
          dismissable={false}
        />
      )}
      <QuickstartWizardModal
        open={currentStep === STEPS.QUICKSTART}
        handleClose={handleCloseModal}
      />
    </>
  );
};

export default LoginFlowModal;
