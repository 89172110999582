import React from "react";
import { Typography, Button, Box } from "@mui/material";
import { debug } from "../utils/debug";

class NetworkErrorBoundary extends React.Component {
  state = { hasError: false, error: null };

  componentDidMount() {
    // Handle unhandled promise rejections
    window.addEventListener(
      "unhandledrejection",
      this.handleUnhandledRejection
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "unhandledrejection",
      this.handleUnhandledRejection
    );
  }

  handleUnhandledRejection = (event) => {
    const error = event.reason;
    if (error?.code === "ERR_NETWORK" || error?.message === "Network Error") {
      event.preventDefault(); // Prevent browser from showing error
      this.setState({ hasError: true, error });
      debug.error("Network Error Boundary caught unhandled rejection:", error);
    }
  };

  static getDerivedStateFromError(error) {
    // Only catch network errors, let others propagate
    if (error?.code === "ERR_NETWORK" || error?.message === "Network Error") {
      return { hasError: true, error };
    }
    // Let other errors propagate to parent error boundaries
    throw error;
  }

  componentDidCatch(error, errorInfo) {
    if (error?.code === "ERR_NETWORK" || error?.message === "Network Error") {
      debug.error("Network Error Boundary caught error:", error, errorInfo);
    }
  }

  handleRetry = () => {
    this.setState({ hasError: false, error: null });
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={3}
        >
          <Typography variant="h6" gutterBottom>
            Connection Error
          </Typography>
          <Typography color="textSecondary" paragraph>
            We're having trouble connecting to our servers. Please check your
            internet connection and try again.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleRetry}
          >
            Retry Connection
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default NetworkErrorBoundary;
