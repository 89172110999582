import React from "react";
import {
  Grid,
  Stack,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import capitalize from "lodash/capitalize";
import InstructionHour from "./InstructionHour";

const InstructionDay = React.memo(
  ({
    day,
    isSelected,
    value,
    handleSelectInstructionDay,
    handleAddDayInstructionHour,
    handleChangeTime,
    handleRemoveDayInstructionHour,
    errors,
  }) => {
    const handleDaySelection = (event) => {
      handleSelectInstructionDay(day, event);
    };

    return (
      <Grid container sx={{ marginBottom: "16px" }}>
        <Grid item xs={4}>
          <Stack direction="row">
            <FormControlLabel
              label={capitalize(day)}
              control={
                <Checkbox checked={isSelected} onChange={handleDaySelection} />
              }
            />
            {isSelected && (
              <IconButton
                aria-label="add"
                size="small"
                color="primary"
                onClick={() => handleAddDayInstructionHour(day)}
              >
                <AddCircleIcon />
              </IconButton>
            )}
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack direction="column" spacing={2}>
            {value.map((hour, index) => (
              <InstructionHour
                key={`${day}-${index}`}
                day={day}
                index={index}
                hour={hour}
                handleChangeTime={handleChangeTime}
                handleRemoveDayInstructionHour={handleRemoveDayInstructionHour}
                error={errors?.[index]}
                showRemove={value.length > 1}
              />
            ))}
          </Stack>
        </Grid>
      </Grid>
    );
  }
);

export default InstructionDay;
