import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  Container,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { verifyEmail } from "../../api/authApi";
import { useReportContext } from "../../contexts/report-context";
import LoginFlowModal from "../../components/modals/LoginFlowModal";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  backgroundColor: theme.palette.background.paper,
  maxWidth: 600,
  width: "100%",
}));

const EmailVerification = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { setMessage } = useReportContext();
  const [verificationStatus, setVerificationStatus] = useState("verifying");
  const [authResponse, setAuthResponse] = useState(null);
  const [hasVerified, setHasVerified] = useState(false);

  const handleVerification = useCallback(async () => {
    if (!token || hasVerified) {
      return;
    }

    setHasVerified(true);
    try {
      const result = await verifyEmail(token);
      console.log("Verification result:", result);

      switch (result.status) {
        case "USER_EXISTS_VERIFIED":
          setVerificationStatus("already_verified");
          setMessage({
            text: "Your email is already verified. Please log in.",
            severity: "info",
            flag: true,
          });
          setTimeout(() => navigate("/login"), 3000);
          break;

        case "TOKEN_EXPIRED_RESENT":
          setVerificationStatus("token_expired");
          setMessage({
            text: "Verification link expired. A new one has been sent to your email.",
            severity: "warning",
            flag: true,
          });
          break;

        case "REGISTRATION_COMPLETE":
        case "EMAIL_VERIFIED":
          setVerificationStatus("success");
          if (result.authInfo) {
            setAuthResponse(result.authInfo);
          } else {
            setMessage({
              text: "Email verified successfully. Please log in.",
              severity: "success",
              flag: true,
            });
            setTimeout(() => navigate("/login"), 3000);
          }
          break;

        default:
          setVerificationStatus("error");
          setMessage({
            text: "Verification failed. Please try again or request a new verification email.",
            severity: "error",
            flag: true,
          });
      }
    } catch (error) {
      console.error("Verification failed:", error);
      setVerificationStatus("error");
      setMessage({
        text: error.message || "Verification failed. Please try again.",
        severity: "error",
        flag: true,
      });
    }
  }, [token, setMessage, navigate, hasVerified]);

  useEffect(() => {
    handleVerification();
  }, [handleVerification]);

  const renderContent = () => {
    const content = {
      verifying: {
        icon: <CircularProgress size={48} />,
        title: "Verifying Your Email",
        message: "Please wait while we verify your email address...",
        color: "primary",
      },
      success: {
        icon: "✓",
        title: "Email Verified Successfully",
        message: "Your email has been verified. Setting up your account...",
        color: "success.main",
      },
      already_verified: {
        icon: "✓",
        title: "Email Already Verified",
        message: "Your email was already verified. Redirecting to login...",
        color: "info.main",
      },
      token_expired: {
        icon: "⚠️",
        title: "Verification Link Expired",
        message:
          "A new verification link has been sent to your email. Please check your inbox.",
        color: "warning.main",
      },
      error: {
        icon: "✕",
        title: "Verification Failed",
        message:
          "Unable to verify your email. Please try again or request a new verification link.",
        color: "error.main",
      },
    }[verificationStatus];

    return (
      <>
        {typeof content.icon === "string" ? (
          <Box sx={{ fontSize: 48, color: content.color, mb: 2 }}>
            {content.icon}
          </Box>
        ) : (
          content.icon
        )}
        <Typography
          variant="h4"
          color={content.color}
          gutterBottom
          align="center"
        >
          {content.title}
        </Typography>
        <Typography variant="body1" color="text.secondary" align="center">
          {content.message}
        </Typography>
      </>
    );
  };

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          py: 4,
        }}
      >
        <StyledPaper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 3,
            }}
          >
            {renderContent()}
          </Box>
        </StyledPaper>
      </Box>
      <LoginFlowModal
        authResponse={authResponse}
        setAuthResponse={setAuthResponse}
      />
    </Container>
  );
};

export default EmailVerification;
