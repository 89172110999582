import {
  parsePhoneNumber,
  formatIncompletePhoneNumber,
} from "libphonenumber-js";

export function parseJwt(token) {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    throw new Error(`Invalid JWT token: ${error.message}`);
  }
}

export function isTokenExpired(token) {
  const decodedToken = parseJwt(token);
  const currentTimestamp = Math.floor(Date.now() / 1000);

  return decodedToken.exp < currentTimestamp;
}

export function isTokenExpiringWithinTenMinutes(token) {
  const decodedToken = parseJwt(token);
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const tenMinutesFromNow = currentTimestamp + 60; // 1 minute from now
  // const tenMinutesFromNow = currentTimestamp + 600; // 10 minutes from now

  return decodedToken.exp < tenMinutesFromNow;
}

export function getCurrentTime(value) {
  const date = new Date(value);
  let hour = date.getHours();
  if (hour < 10) {
    hour = "0" + hour;
  }
  let minute = date.getMinutes();
  if (minute < 10) {
    minute = "0" + minute;
  }
  return hour + ":" + minute;
}

export function getFullDate(timeValue) {
  if (timeValue === "") return null;
  const date = new Date();
  const hour = parseInt(timeValue.split(":")[0]);
  const minute = parseInt(timeValue.split(":")[1]);
  date.setHours(hour, minute, 0);
  return date;
}

export function getOnlyDateValue(dateTime) {
  const date = new Date(dateTime);
  date.setHours(0);
  return date;
}

export const formatPhoneNumber = (phoneNumber, format = "display") => {
  if (!phoneNumber) return null;

  try {
    // If number doesn't start with +, assume it's a US number
    const numberToParse = phoneNumber.startsWith("+")
      ? phoneNumber
      : `+1${phoneNumber.replace(/\D/g, "")}`;

    const parsedNumber = parsePhoneNumber(numberToParse);

    if (format === "E.164") {
      return parsedNumber.format("E.164");
    }

    // For display, use national format for US/Canada numbers, international for others
    return parsedNumber.country === "US" || parsedNumber.country === "CA"
      ? parsedNumber.format("NATIONAL")
      : parsedNumber.format("INTERNATIONAL");
  } catch (error) {
    // Fallback for incomplete or invalid numbers
    return formatIncompletePhoneNumber(phoneNumber);
  }
};

export const formatLimit = (limit) => {
  // Check if the limit is Integer.MAX_VALUE or similar very large number
  if (limit > 1000000) {
    return "∞"; // Unicode infinity symbol
  }
  return limit;
};

export const formatCurrency = (amount) => {
  if (amount == null || isNaN(amount)) return "$0.00";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};
