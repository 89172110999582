import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Container,
  Paper,
  Grid,
  Divider,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { printedPlannerApi } from "../../api/printedPlannerApi";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";

const SummaryStep = () => {
  const { shippingAddress, shippingOption } = useSelector(
    (state) => state.checkout
  );
  const cartItems = useSelector((state) => state.cart.items);
  const [orderSummary, setOrderSummary] = useState(null);

  const {
    execute: calculateCost,
    loading,
    error,
  } = useAsyncOperation(printedPlannerApi.calculatePrintJobCost);

  useEffect(() => {
    const fetchOrderSummary = async () => {
      if (shippingAddress && shippingOption) {
        try {
          const request = {
            shippingAddress: {
              city: shippingAddress.city,
              countryCode: shippingAddress.countryCode,
              isBusiness: shippingAddress.isBusiness || false,
              name: shippingAddress.name || "Customer",
              phoneNumber: shippingAddress.phoneNumber,
              postcode: shippingAddress.postcode,
              stateCode: shippingAddress.stateCode,
              street1: shippingAddress.street1,
              street2: shippingAddress.street2 || "",
            },
            shippingMethod: shippingOption,
          };

          console.log("Calculating cost with request:", request);
          const summary = await calculateCost(request);
          setOrderSummary(summary);
        } catch (error) {
          console.error("Error calculating order cost:", error);
        }
      }
    };

    fetchOrderSummary();
  }, [shippingAddress, shippingOption, calculateCost]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error">
        Error calculating order cost. Please try again later.
      </Typography>
    );
  }

  if (!orderSummary) {
    return null;
  }

  const formatCurrency = (value) => {
    return typeof value === "number" ? `$${value.toFixed(2)}` : "$0.00";
  };

  const subtotal =
    orderSummary.totalCostExclTax - orderSummary.shippingCost.totalCostExclTax;

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Order Summary
        </Typography>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Items
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderSummary.reportItems.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>
                          {item.type} - {item.pageCount} pages
                        </TableCell>
                        <TableCell align="right">1</TableCell>
                        <TableCell align="right">
                          {formatCurrency(item.itemPrice)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Shipping
              </Typography>
              <Typography variant="body1">
                {`${shippingAddress.street1}${
                  shippingAddress.street2 ? `, ${shippingAddress.street2}` : ""
                }`}
                <br />
                {`${shippingAddress.city}, ${shippingAddress.stateCode} ${shippingAddress.postcode}`}
                <br />
                {shippingAddress.countryCode === "US"
                  ? "United States"
                  : shippingAddress.countryCode}
              </Typography>
              <Typography variant="body1">{shippingOption}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Typography variant="body1">Subtotal:</Typography>
                <Typography variant="body1">
                  {formatCurrency(subtotal)}
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography variant="body1">Shipping:</Typography>
                <Typography variant="body1">
                  {formatCurrency(orderSummary.shippingCost.totalCostExclTax)}
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography variant="body1">Tax:</Typography>
                <Typography variant="body1">
                  {formatCurrency(orderSummary.totalTax)}
                </Typography>
              </Grid>
              {orderSummary.discountAmount > 0 && (
                <Grid container justifyContent="space-between">
                  <Typography variant="body1">Discount:</Typography>
                  <Typography variant="body1">
                    -{formatCurrency(orderSummary.discountAmount)}
                  </Typography>
                </Grid>
              )}
              <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                <Typography variant="h6">Total:</Typography>
                <Typography variant="h6">
                  {formatCurrency(orderSummary.totalCostInclTax)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
};

export default SummaryStep;
