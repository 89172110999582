import React, { useState, useEffect } from "react";
import { Typography, Button, Grid, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import LoginModal from "../../components/modals/LoginModal";

const PostNoteCard = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFF9E5",
  borderRadius: "4px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  maxWidth: "500px",
  position: "relative",
  overflow: "hidden",
  zIndex: 2,
  marginTop: "50px",
}));

const PostNoteHeader = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(255, 236, 179, 0.5)",
  height: "46px",
  width: "100%",
}));

const PostNoteContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8),
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  marginTop: "50px", // Add margin-top to shift down
  marginLeft: -300, // Adjust this value to overlap the image with the post-it note
  width: "calc(100% + 300px)", // Compensate for the negative margin
  [theme.breakpoints.down("md")]: {
    marginTop: 0,
    marginLeft: 0,
    width: "100%",
  },
  marginBottom: 0,
}));

const BackgroundBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  position: "absolute",
  top: 0,
  bottom: 0,
  left: "50%",
  transform: "translateX(-50%)", // Center the box horizontally
  width: "100%", // Set the width to 100% to fill the parent container
  zIndex: -1,
}));

const Hero = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();
  const [greenBackgroundWidth, setGreenBackgroundWidth] = useState(0);

  useEffect(() => {
    const updateGreenBackground = () => {
      const navItemsContainer = document.getElementById("nav-items-container");
      if (navItemsContainer) {
        setGreenBackgroundWidth(navItemsContainer.offsetWidth);
      }
    };

    updateGreenBackground();
    window.addEventListener("resize", updateGreenBackground);

    return () => window.removeEventListener("resize", updateGreenBackground);
  }, []);

  const handleNavigateToRegister = () => {
    navigate("/register");
  };

  const handleLogin = () => {
    setShowLoginModal(true);
  };

  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
  };

  return (
    <Box sx={{ overflow: "hidden", position: "relative" }}>
      <BackgroundBox style={{ width: greenBackgroundWidth }} />
      <Grid container spacing={0} alignItems="flex-start">
        <Grid item xs={12} md={7}>
          <PostNoteCard>
            <PostNoteHeader />
            <PostNoteContent>
              <Typography
                variant="h4"
                component="h1"
                gutterBottom
                sx={{ fontWeight: 700 }}
              >
                Empower Your Homeschooling Journey With Us
              </Typography>
              <Typography variant="body1" paragraph sx={{ mb: 3 }}>
                Streamline your homeschooling experience with our intuitive
                platform. Stay organized, track progress, and create engaging
                lesson plans.
              </Typography>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Button
                  variant="lightRoundedOutline"
                  sx={{ mr: 2 }}
                  onClick={handleNavigateToRegister}
                >
                  Try for Free
                </Button>
                <Button variant="darkRounded" onClick={handleLogin}>
                  Log In
                </Button>
              </Box>
            </PostNoteContent>
          </PostNoteCard>
        </Grid>
        <Grid item xs={12} md={5}>
          <ImageWrapper>
            <Box
              component="img"
              src="/assets/images/studying1.png"
              alt="Mother and son studying"
              sx={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                objectPosition: "left center",
              }}
            />
          </ImageWrapper>
        </Grid>
      </Grid>
      {showLoginModal && (
        <LoginModal
          open={showLoginModal}
          handleClose={handleCloseLoginModal}
          showRegisterAccount={handleNavigateToRegister}
        />
      )}
    </Box>
  );
};

export default Hero;
