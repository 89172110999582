import React, {
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Typography,
  Grid,
  Divider,
  Paper,
  Alert,
  Button,
  Stack,
} from "@mui/material";
import { usePlannerWizardContext } from "../../contexts/planner-wizard-context";
import { printedPlannerApi } from "../../api/printedPlannerApi";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { formatCurrency } from "../../utils/utils";
import { debug } from "../../utils/debug";
import { setCart } from "../../redux/cartSlice";

const FinalReview = forwardRef((props, ref) => {
  const { wizardData, updateStepValidation } = usePlannerWizardContext();
  const dispatch = useDispatch();

  const { execute: executeAddToCart, loading } = useAsyncOperation(
    printedPlannerApi.addToCart
  );

  const handleAddToCart = useCallback(async () => {
    debug.wizard.state("Adding planner to cart:", {
      reportId: wizardData.reportId,
      pageCount: wizardData.pageCount,
      itemPrice: wizardData.itemPrice,
    });

    try {
      const orderDetails = {
        reportId: wizardData.reportId,
        numberOfPages: wizardData.pageCount,
        plannerOptions: {
          coverFinish: "MATTE",
          paperType: "WHITE",
          colorType: "FULL_COLOR",
          paperQuality: "STANDARD",
          size: "LETTER",
          bindingType: "COIL",
        },
      };

      const response = await executeAddToCart(orderDetails);
      debug.wizard.state("Planner added to cart:", response);
      dispatch(setCart(response));

      return true;
    } catch (error) {
      debug.wizard.state("Error adding planner to cart:", error);
      return false;
    }
  }, [
    wizardData.reportId,
    wizardData.pageCount,
    wizardData.itemPrice,
    executeAddToCart,
    dispatch,
  ]);

  // Wrap validatePlannerData in useCallback
  const validatePlannerData = useCallback(() => {
    const requiredFields = {
      student: Boolean(wizardData.student?.id),
      term: Boolean(wizardData.term?.id),
      theme: Boolean(wizardData.themeId),
      preview: Boolean(wizardData.previewUrls?.length > 0),
      itemPrice: Boolean(wizardData.itemPrice),
      reportId: Boolean(wizardData.reportId),
    };

    const isValid = Object.values(requiredFields).every(Boolean);
    const missingFields = Object.entries(requiredFields)
      .filter(([_, value]) => !value)
      .map(([key]) => key);

    return { isValid, missingFields };
  }, [wizardData]);

  // Update step validation
  useEffect(() => {
    const { isValid } = validatePlannerData();
    if (isValid) {
      updateStepValidation(true, handleAddToCart);
    } else {
      updateStepValidation(false);
    }
  }, [wizardData, updateStepValidation, handleAddToCart, validatePlannerData]);

  // Expose validation through ref
  useImperativeHandle(
    ref,
    () => ({
      isValid: () => validatePlannerData().isValid,
      handleNext: handleAddToCart,
    }),
    [validatePlannerData, handleAddToCart]
  );

  const { isValid, missingFields } = validatePlannerData();

  if (!isValid) {
    return (
      <Box>
        <Alert severity="error" sx={{ mb: 2 }}>
          Missing required planner information: {missingFields.join(", ")}
        </Alert>
        <Typography>
          Please complete all previous steps before reviewing your planner.
        </Typography>
      </Box>
    );
  }

  const subtotal = wizardData.itemPrice || 0;

  return (
    <Box>
      <Typography variant="h4" gutterBottom color="primary">
        Order Summary
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, height: "100%" }}>
            <Typography variant="h6" gutterBottom color="primary">
              Student Information
            </Typography>
            <Typography>{`${wizardData.student.firstName} ${wizardData.student.lastName}`}</Typography>
            <Typography>{`Grade: ${wizardData.student.gradeLevel}`}</Typography>
            <Typography>{`Term: ${wizardData.term.name}`}</Typography>
            <Typography
              sx={{ mt: 2 }}
              variant="h6"
              gutterBottom
              color="primary"
            >
              Theme
            </Typography>
            <Typography>{wizardData.themeName}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, height: "100%" }}>
            <Typography variant="h6" gutterBottom color="primary">
              Planner Details
            </Typography>
            <Typography>{`Number of Pages: ${
              wizardData.pageCount || 0
            }`}</Typography>
            <Typography
              sx={{ mt: 2 }}
              variant="h6"
              gutterBottom
              color="primary"
            >
              Cost Breakdown
            </Typography>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography>Subtotal:</Typography>
              </Grid>
              <Grid item>
                <Typography>{formatCurrency(subtotal)}</Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography>Shipping:</Typography>
              </Grid>
              <Grid item>
                <Typography>To Be Calculated</Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography>Tax:</Typography>
              </Grid>
              <Grid item>
                <Typography>To Be Calculated</Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">Total:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" color="primary">
                  To Be Calculated
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
});

export default FinalReview;
