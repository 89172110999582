// OrderPrintedPlanner.jsx

import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import { Typography, Box, Button, Stack } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import HsWizard from "../common/HsWizard";
import HsModal from "../../components/common/HsModal";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { printedPlannerApi } from "../../api/printedPlannerApi";
import TermStudentSelection from "./TermStudentSelection";
import StudentInfoConfirmation from "./StudentInfoConfirmation";
import ThemeSelection from "./ThemeSelection";
import FinalReview from "./FinalReview";
import PlannerPreview from "./PlannerPreview";
import { PlannerWizardProvider } from "../../contexts/planner-wizard-context";
import { setCart } from "../../redux/cartSlice";
import { debug } from "../../utils/debug";
import PrintedPlannerWizard from "./PrintedPlannerWizard";

const SKIP_PLANNER_PREVIEW =
  process.env.REACT_APP_SKIP_PLANNER_PREVIEW === "true" || false;

const MemoizedTermStudentSelection = React.memo(TermStudentSelection);
const MemoizedStudentInfoConfirmation = React.memo(StudentInfoConfirmation);
const MemoizedThemeSelection = React.memo(ThemeSelection);
const MemoizedPlannerPreview = React.memo(PlannerPreview);
const MemoizedFinalReview = React.memo(FinalReview);

const WIZARD_STEPS = [
  {
    id: "term-student",
    label: "Term & Student",
    component: TermStudentSelection,
    isValid: false,
  },
  {
    id: "theme",
    label: "Theme",
    component: ThemeSelection,
    isValid: false,
  },
  {
    id: "preview",
    label: "Preview",
    component: PlannerPreview,
    isValid: false,
  },
];

const OrderPrintedPlanner = ({ open, onClose }) => {
  debug.wizard.state("OrderPrintedPlanner mounting");

  const handleClose = () => {
    debug.wizard.state("OrderPrintedPlanner handling close");
    onClose?.();
  };

  return (
    debug.wizard.state("OrderPrintedPlanner render", {
      isOpen: open,
      hasOnClose: Boolean(onClose),
    }),
    (<PrintedPlannerWizard open={open} onClose={handleClose} />)
  );
};

export default OrderPrintedPlanner;
