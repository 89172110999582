import { createTheme, responsiveFontSizes } from "@mui/material/styles";

// Define color palette
const palette = {
  primary: {
    main: "#728D77",
    light: "#BCCAAB",
    lighter: "#D9E3DA",
    dark: "#618F74",
  },
  secondary: {
    main: "#ECC7F7",
    light: "#EDE9F1",
    dark: "#D1A7DC",
  },
  yellow: {
    main: "#FD9031",
    light: "#FFECBB",
  },
  red: {
    main: "#EA6161",
    light: "#FFD6D6",
  },
  error: {
    main: "#EA6161",
  },
  warning: {
    main: "#FD9031",
    light: "#FFECBB",
  },
  success: {
    main: "#4CAF50",
  },
  info: {
    main: "#2196F3",
  },
  text: {
    primary: "#333333",
    secondary: "#666666",
  },
  background: {
    default: "#fff",
    paper: "#fff",
    gray: "#f0f0f0",
  },
};

// Define fonts
const HEADER_FONT = '"Nanum Myeongjo", "Helvetica", "Arial", sans-serif';
const BODY_FONT = '"Inter", "Helvetica", "Arial", sans-serif';

// Define typography
const typography = {
  fontFamily: BODY_FONT,
  fontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontFamily: HEADER_FONT,
    fontSize: "2.5rem",
    fontWeight: 700,
  },
  h2: {
    fontFamily: HEADER_FONT,
    fontSize: "2rem",
    fontWeight: 600,
  },
  h3: {
    fontFamily: HEADER_FONT,
    fontSize: "1.75rem",
    fontWeight: 600,
  },
  h4: {
    fontFamily: HEADER_FONT,
    fontSize: "1.5rem",
    fontWeight: 600,
  },
  h5: {
    fontFamily: HEADER_FONT,
    fontSize: "1.25rem",
    fontWeight: 600,
  },
  h6: {
    fontFamily: HEADER_FONT,
    fontSize: "1rem",
    fontWeight: 600,
  },
  subtitle1: {
    fontSize: "1rem",
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: "0.875rem",
    fontWeight: 500,
  },
  body1: {
    fontSize: "1rem",
    fontWeight: 400,
  },
  body2: {
    fontSize: "0.875rem",
    fontWeight: 400,
  },
  button: {
    fontSize: "0.875rem",
    fontWeight: 500,
    textTransform: "none",
  },
  caption: {
    fontSize: "0.75rem",
    fontWeight: 400,
  },
  overline: {
    fontSize: "0.75rem",
    fontWeight: 400,
    textTransform: "uppercase",
  },
  bodyFL: {
    fontSize: 18,
    fontWeight: 700,
  },
  bodyFV: {
    fontSize: 16,
    fontWeight: 400,
  },
};

// Define breakpoints
const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};

// Define component overrides
const components = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true, // This will disable the ripple effect, which is often the cause of aria-hidden issues
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true, // This can help with some aria-hidden issues as well
    },
    styleOverrides: {
      root: {
        borderRadius: 50,
        textTransform: "none",
        padding: "8px 16px",
      },
    },
    variants: [
      {
        props: { variant: "navItem" },
        style: {
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textTransform: "none",
          padding: "16px 32px",
        },
      },
      {
        props: { variant: "lightRounded" },
        style: {
          color: "black",
          backgroundColor: "white",
          "&:hover": {
            backgroundColor: "#f0f0f0",
          },
        },
      },
      {
        props: { variant: "darkRounded" },
        style: {
          color: "white",
          backgroundColor: "black",
          "&:hover": {
            backgroundColor: "#333",
          },
          "&:disabled": {
            color: "rgba(255, 255, 255, 0.5)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
      {
        props: { variant: "lightRoundedShadow" },
        style: {
          color: "black",
          backgroundColor: "white",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
          "&:hover": {
            backgroundColor: "#f0f0f0",
          },
        },
      },
      {
        props: { variant: "lightRoundedOutline" },
        style: {
          color: "black",
          backgroundColor: "transparent",
          border: "1px solid black",
          "&:hover": {
            backgroundColor: "#f0f0f0",
          },
        },
      },
    ],
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        width: 220,
        transition: "width 0.3s",
        backgroundColor: palette.secondary.light,
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        boxShadow: "none",
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        minHeight: 64,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: "12px 16px",
        height: "56px",
        minHeight: "56px",
        borderBottom: "1px dashed rgba(224, 224, 224, 1)",
        fontFamily: "inherit",
      },
      head: {
        backgroundColor: palette.background.default,
        color: palette.text.secondary,
        fontWeight: "normal", // Change this to normal
        borderBottom: "2px solid rgba(224, 224, 224, 1) !important",
        height: "56px",
        minHeight: "56px",
        textTransform: "uppercase", // Add this line
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        "&:last-child td, &:last-child th": {
          borderBottom: "1px dashed rgba(224, 224, 224, 1)",
        },
      },
      head: {
        height: "56px",
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        "& .MuiTableCell-head": {
          borderBottom: "1px solid rgba(224, 224, 224, 1) !important",
          height: "56px",
          minHeight: "56px",
        },
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        borderCollapse: "separate",
        borderSpacing: 0,
      },
    },
  },
  MuiCalendar: {
    styleOverrides: {
      root: {
        "& .rbc-calendar": {
          backgroundColor: "#ffffff",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          overflow: "hidden",
        },
        "& .rbc-header": {
          backgroundColor: palette.primary.lighter,
          padding: "8px",
          fontWeight: 600,
        },
        "& .rbc-today": {
          backgroundColor: palette.primary.lighter,
        },
        "& .rbc-event": {
          backgroundColor: palette.primary.main,
          borderRadius: "4px",
          "&:hover": {
            backgroundColor: palette.primary.dark,
          },
        },
        "& .rbc-off-range-bg": {
          backgroundColor: palette.background.gray,
        },
        "& .rbc-toolbar button": {
          color: palette.primary.main,
          "&:hover": {
            backgroundColor: palette.primary.lighter,
          },
          "&:active, &.rbc-active": {
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,
          },
        },
      },
    },
  },
};

// Create the base theme
let theme = createTheme({
  palette,
  typography,
  breakpoints,
  components,
  spacing: 8,
  shape: {
    borderRadius: 16,
  },
});

// Apply responsive font sizes
theme = responsiveFontSizes(theme);

// Add responsive typography for mobile
theme.typography = {
  ...theme.typography,
  [theme.breakpoints.down("sm")]: {
    h6: {
      fontSize: "1.1rem",
    },
    subtitle1: {
      fontSize: "0.9rem",
    },
    body1: {
      fontSize: "0.9rem",
    },
    body2: {
      fontSize: "0.8rem",
    },
  },
};

export default theme;
